//
// Panel CrossSell
//--------------------------------------------------
// Crosssell
// Crosssell : HOME

//TODO move yellow-light to core-less-imports
@yellow-light: hsla(48, 100%, 70%, 1.0);

// TODO delete from here. In SASOI-2179 these variables are set in core-less-imports
@duration-in: 0.3s;
@duration-out: 0.2s;

.panel-crossSell {
	&:extend(._panel all);
	flex: 1; // hack for older safari browsers
	min-height: @sticker-m + @panel-grid-s;

	.panel-body {
		&:extend(._panel-border all);
		flex: 1 1 100%;
		display: block;
		background-color: @yellow;
		border-color: @yellow;
		text-decoration: none;
		color: @blue-dark;
		transition: background-color @duration-in ease-out;

		> header {
			.header-img();
			float: left;
			// On Mobile, the header image and body text are not shown
			img {
				display: none; // TODO: Refactor, never hide using display none!
				@media (@breakpoint-m) {
					display: inline;
				}
			}
		}

		&:hover {
			background-color: @yellow-light;
			transition: background-color @duration-out ease-out;

			span:after {
				left: @line;
				transition: left @duration-out;
			}
		}
	}
	.panel-main {

		> h3 {
			margin: 0;
			vertical-align: middle;
			height: 40px;
			display: table-cell;

			@media (@breakpoint-m) {
				display: block;
				height: auto;
			}
			> * {
				text-align: left;
				padding: 0;
				font-weight: normal;
				font-size: inherit;
			}
		}

		p {
			display: none;
			@media (@breakpoint-m) {
				display: inline;
			}
		}
	}
}

// Crosssell : HOME
//----------------------
.shopping .panel-crossSell {
	.panel-body {
		background-color: transparent;
		border-color: @blue-grey-light;
		transition: border-color @duration-out ease-out;
		&:hover {
			border-color: @blue-dark;
			background-color: transparent;
			transition: border-color @duration-out ease-out;
		}
		
		span:after { color: @blue; }
	}
}

/*  Sticker positioning
	different from other stickers because with this panel we HIDE the HEADER image on mobile
*/
.panel-crossSell {

	// position sticker in a different location when there's a header
	.panel-body > header + [class^=sticker-] {
		margin-top: -@grid-unit-m;

		@media (@breakpoint-m) {
			margin-top: ~'calc(-@{sticker-m} )';
		}

		@media (@breakpoint-l) {
			margin-top: ~'calc(-@{sticker-l} )';
		}
	}
}
