// Colours
@yellow: hsla(48, 100%, 50%, 1);
@blue: hsla(199, 100%, 43%, 1);
@blue-dark: hsla(213, 100%, 26%, 1);
@orange: hsla(32, 99%, 48%, 1);
@green: hsla(102, 48%, 47%, 1);
@red: hsla(16, 96%, 48%, 1);
@white: hsla(60, 100%, 100%, 1);

@blue-grey-lighter: hsla(201, 52%, 94%, 1);
@blue-grey-light: hsla(202, 53%, 90%, 1);
@blue-grey: hsla(203, 54%, 84%, 1);
@blue-grey-dark: hsla(204, 44%, 55%, 1);
@blue-grey-darker: #003D86;

@black: hsla(209, 100%, 6%, 1);

@shadow-color-light: hsla(211, 100%, 25%, 0.3);
@shadow-color-dark: hsla(211, 100%, 25%, 0.7);

// Gradients
@gradient-blue: linear-gradient(
  to right,
  @blue 0%,
  hsla(197, 70%, 50%, 1) 100%
);
@gradient-blue-dark: linear-gradient(
  to right,
  @blue-dark 0%,
  hsla(204, 74%, 46%, 1) 100%
);
@gradient-orange: linear-gradient(
  to right,
  @orange 0%,
  hsla(41, 87%, 51%, 1) 100%
);
@gradient-blue-transparent: linear-gradient(
  hsla(0, 0%, 100%, 0),
  hsla(212, 96%, 11%, 0.2) 100%
);

// Lightened gradients (use only as hover effect)
@gradient-lighter-blue: linear-gradient(
  to right,
  hsla(204, 85%, 61%, 1) 0%,
  hsla(196, 99%, 65%, 1) 100%
);
@gradient-lighter-blue-dark: linear-gradient(
  to right,
  hsla(211, 63%, 40%, 1) 0%,
  hsla(201, 70%, 50%, 1) 100%
);
@gradient-lighter-orange: linear-gradient(
  to right,
  hsla(36, 100%, 57%, 1) 0%,
  hsla(46, 100%, 62%, 1) 100%
);

// Button ACTIVE gradients
@gradient-blue-btn-active: linear-gradient(
  to right,
  hsla(213, 100%, 26%, 1) 0%,
  hsla(211, 63%, 40%, 1) 100%
);
@gradient-orange-btn-active: linear-gradient(
  to right,
  darken(@orange, 10%) 0%,
  @orange 100%
);

// Colours used for specific purposes
@input-color: @blue-grey-darker;
@input-bg: @white;
@input-bg-disabled: @blue-grey;
@input-border: @blue-grey-light;

// Colours used by Social media vendors
@twitter-color: #55acee;
@facebook-color: #3b5998;
@youtube-color: #ff0000;
@google-color: #dd4b39;
@whatsapp-color: #20cb3d;
@linkedin-color: #0077b5;
@instagram-color: #f10176;
