// FOOTER
//-------------------------
// Footer

// Footer images
// Disclaimer

// Social icons
//-------------------------

.footer {
	> .footer-block {
		.grid-balanced;

		._flexed-outer-grid-padding();
		padding-bottom: 0.4em;
		color: @white;

		@media (@breakpoint-m) {
			padding-top: @rem-component-l;
			padding-bottom: @rem-component-m;
		}

		.panel-responsive {
			padding-bottom: 0;

			&:after {
				margin-right: -(@_grid-base + @panel-grid-s);
				margin-left: -(@_grid-base + @panel-grid-s);
			}
		}

		ul {
			&:extend(._list-unstyled all);
			padding: 0;
			margin: 0;
		}

		ul > li {
			//padding-top: @offset-xs;
			//padding-bottom: @offset-xs;

			padding: 0;
			margin: 0;

			@media (@breakpoint-l) {
				//  padding: 0;
			}
		}

		ul > li > a:not([role="button"]):not(.imgLink) {
			font-family: @default-fonts;
			position: relative;
			color: @white;
			font-size: @font-size-xs;
			display: inline-block;
			margin: 0;
			padding-top: @offset-s;
			padding-bottom: @offset-s;

			&:hover {
				color: @blue-grey;
			}
			&:before {
				font-size: @rem-base;
				text-align: center;
			}
			// IE/Edge compatible way not to show underline on :before pseudo-element on hover
			&:hover,
			&:before {
				text-decoration: underline;
			}
			&,
			&:before,
			&:hover:before {
				text-decoration: none;
			}

			&:active {
				color: @white;
			}
		}

		h3 {
			color: @white;
			padding-top: @rem-N;
			padding-bottom: 0;
			line-height: @line-height-s;
			font-size: @font-size-m;
			.anwb-thesans-bold();

			@media (@breakpoint-m) {
				label {
					padding-bottom: @offset-m;
				}
				padding-top: 0;
				padding-bottom: 0;
				line-height: @line-height-base;
				margin-bottom: @rem-s;
			}
		}

		input[type="checkbox"] + h3 {
			margin-bottom: 0;

			@media (@breakpoint-m) {
				margin-bottom: @rem-N;
			}
		}

		input[type="checkbox"]:checked + h3 {
			margin-bottom: @rem-N;
		}

		& .panel:last-of-type {
			h3 {
				margin-bottom: @margin-text-m;
				@media (@breakpoint-m) {
					margin-bottom: @rem-N;
				}
			}
			._panel-grid-offset();
		}

		.panel-main {
			a.imgLink {
				outline: none;
				display: inline-block;
				margin-bottom: @rem-s;
			}

			img {
				border-radius: 0;
			}
		}
	}
	// FIXME: FREEMARKER template should replace this rule with a button class in the markup.
	[role="button"] {
		&:extend(._btn all);
	}
}

.footer-disclaimer a.footerlogo {
	display: block;
	width: 75px;
	height: 40px;
	margin-top: @rem-s;
	position: relative;
	flex: 1 1 20%;

	&:after {
		width: 75px;
		height: 40px;
		top: 0px;
		right: 0;
		position: absolute;
		content: url(@anwb_logo);
	}
}

// Footer discaimer block
.footer-disclaimer {
	background-color: white;

	& > .footer-disclaimer-block {
		//.grid('balanced',2);

		._flexed-outer-grid-padding();

		border: solid @grid-unit-s transparent;
		border-top: 0;
		border-bottom: 0;
		padding-right: 0;
		padding-left: 0;
		padding-top: 0;
		display: flex;
		align-items: center;

		@media (@breakpoint-m) {
			border-width: @panel-grid-m;
		}

		@media (@breakpoint-l) {
			border-width: @panel-grid-l;
		}

		ul {
			align-items: flex-start;
			flex: 1 1 80%;
		}

		a {
			color: @blue-dark;
			text-decoration: none;
			font-size: @font-size-xs;
			display: inline-block;
			line-height: @line-height-s;
			vertical-align: top;

			@media (@breakpoint-m) {
				display: inline-block;
			}

			&:hover {
				color: @blue;
			}

			&:active {
				color: @orange;
			}
		}
	}
}

// Social icons
.footer-block > :nth-child(3) li > a {
	&:before {
		border-radius: 50%;
		width: 22px;
		display: inline-block !important;
		background-color: @yellow;
		margin-right: 6px;
		vertical-align: bottom;
		@media (@breakpoint-l) {
			top: @rem-xs;
		}
	}
}
.socialmedia-buttons a,
.footer-block > :nth-child(3) li > a {
	&:before {
		color: @white;
	}
	&:hover:before {
		background-color: lighten(@yellow, 20%);
	}
	&[href*="nieuwsbr"]:before {
		content: @var-icon-mail;
	}
	&[href$="/mobiel"]:before {
		content: @var-icon-social-apps;
	}
	&[href*="/wandelen/podcast"]:before {
		content: @var-icon-podcast;
	}
	&[href$="/community"]:before {
		content: @var-icon-social-community;
	}
	// twitter
	&[href*="twitter"] {
		&:before {
			content: @var-icon-social-twitter;
			background-color: @twitter-color;
		}
		&:hover:before {
			background-color: lighten(@twitter-color, 10%);
		}
	}
	// Facebook
	&[href*="facebook.com"] {
		&:before {
			content: @var-icon-social-facebook;
			background-color: @facebook-color;
		}
		&:hover:before {
			background-color: lighten(@facebook-color, 10%);
		}
	}
	// youtube
	&[href*="youtube.com"] {
		&:before {
			content: @var-icon-social-youtube;
			background-color: @youtube-color;
		}
		&:hover:before {
			background-color: lighten(@youtube-color, 10%);
		}
	}
	// Google+
	&[href*="google.com"] {
		&:before {
			content: @var-icon-social-google;
			background-color: @google-color;
		}
		&:hover:before {
			background-color: lighten(@google-color, 10%);
		}
	}
	// LinkedIn
	&[href*="linkedin.com"] {
		&:before {
			content: @var-icon-social-linkedin;
			background-color: @linkedin-color;
		}
		&:hover:before {
			background-color: lighten(@linkedin-color, 10%);
		}
	}
	// instagram
	&[href*="instagram.com"] {
		&:before {
			content: @var-icon-social-instagram;
			background-color: @instagram-color;
		}
		&:hover:before {
			background-color: lighten(@instagram-color, 10%);
		}
	}
}
