.paging {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media ( @breakpoint-m) {
    justify-content: flex-start;
  }

  li {
    border-bottom: 2px solid @blue-grey-light;
    display: block;
    height: 40px;
    min-width: 30px;
    padding: 8px 2px 0;
    font-feature-settings: "tnum" 1;
    font-variant-numeric: tabular-nums;
    white-space: nowrap;

    &:first-child,
    &:last-child {
      flex: 1 1 auto;
    }

    &:first-child {
      &:not([class*="previous"]) {
        text-align: right;
      }

      @media ( @breakpoint-m) {
        flex: 0 1 auto;
      }
    }

    &:last-child {
      &[class*="next"] {
        text-align: right;

        @media ( @breakpoint-m) {
          text-align: left;
        }
      }
    }

    > * {
      box-sizing: content-box;
      display: inline-block;
      font-weight: bold;
      font-family: @anwb-font-family;
      font-size: @font-size-m;
      border-bottom: 2px solid transparent;
      height: 100%;
      width: auto;
      min-width: 26px;
      text-align: center;
    }

    > a {
      color: @blue-dark;
      text-decoration: none;
    }

    &:not([class*="current"]) a:hover {
      color: @blue;
      border-color: @blue;
    }

    &[class*="current"] > * {
      color: @orange;
      cursor: default;
      pointer-events: none;
      border-color: @orange;
    }

    &[class*="previous"],
    &[class*="next"] {
      min-width: 26px;

      a {
        &:before,
        &:after {
          &:extend(.icon);
          vertical-align: baseline;
        }

        > span {
          @media (@breakpoint-s) {
            .hide();
          }
        }

        &:hover {
          border-color: transparent;
        }
      }
    }

    &[class*="previous"] {
      padding-left: 0;

      @media ( @breakpoint-m) {
        padding-right: 15px;
      }

      a {
        text-align: left;

        &:before {
          color: @blue;
          content: @var-icon-chevron-left;

          @media ( @breakpoint-m) {
            margin-right: 10px;
          }
        }
      }
    }

    &[class*="next"] {
      padding-right: 0;

      @media ( @breakpoint-m) {
        padding-left: 15px;
      }

      a {
        text-align: right;

        &:after {
          color: @blue;
          content: @var-icon-chevron-right;
          @media ( @breakpoint-m) {
            margin-left: 10px;
          }
        }
      }
    }

    // the following classes can be used to hide / show on breakpoints,
    // if used in your paging logic, make sure to test _all_ scenarios.
    &[class*="collapse-s"] {
      @media (@breakpoint-s) {
        .hide();
      }
    }

    &[class*="collapse-m"] {
      @media (@breakpoint-m) {
        .hide();
      }
    }
  }
}
