@media (@breakpoint-m--menu)  {
	.@{anwb-prefix}navigation__content {
		display: block;
	}
}

// Fantastically horrible Firefox hack!
// Looks like we're not done with buggy browsers yet!
@-moz-document url-prefix() {
	.@{anwb-prefix}navigation__content {
		@media (@breakpoint-m) {
			//height: 4.066rem;
		}
		@media (@breakpoint-l) {
			//height: auto;
		}
	}
}

