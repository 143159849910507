.panel-warning {
	&:extend(._panel all);
	> .panel-body {
		&:extend(._panel-border all);
		border-color: @orange;
		background: lighten(@orange, 50%);
		h3 {
			color: @orange;
		}
	}

	.exclamation-sign {
		@sign-size: @rem-component-m * 1.5;

			&:after {
			content: '';
			background: transparent url(@exclamation-sign);
			background-size: @sign-size @sign-size;
			width: @sign-size;
			height: @sign-size;
			display: block;
		}
	}
}