.@{anwb-prefix}lightbox {
	@block: ~"@{anwb-prefix}lightbox";

	z-index: @z-index-popup + 2;

	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 120%;
		background-color: @overlay-color-dark;
		z-index: @z-index-popup + 1;

		&--clickable {
			cursor: pointer;
		}
	}

	&__dialog {
		position: absolute;
		right: 0;
		left: 0;
		display: block;
		box-sizing: border-box;
		padding: 2rem @grid-unit-s @grid-unit-s;
		margin: @grid-offset-s;
		border-radius: @border-radius;
		background-color: @white;
		z-index: @z-index-popup + 3;

		// actually a firefox fix...
		&:after {
			position: absolute;
			content: "";
			bottom: -24px;
			height: 24px;
			width: 1px;
		}

		@media (@breakpoint-m) {
			padding: 2rem @grid-unit-m @grid-unit-m;
			margin: 0;
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		}

		@media (@breakpoint-l) {
			padding: 2rem @grid-unit-l @grid-unit-l;
		}

		// horizontal sizes, vertical sizes are defined by the content
		&--wide,
		&[data-size=l] {
			@media (@breakpoint-m) {
				width: 462px;
			}
			@media (@breakpoint-l) {
				width: 752px;
			}
		}

		&--medium,
		&[data-size=m] {
			@media (@breakpoint-m) {
				width: 342px;
			}
			@media (@breakpoint-l) {
				width: 560px;
			}
		}

		&--narrow,
		&[data-size=s] {
			@media (@breakpoint-m) {
				width: 222px;
			}
			@media (@breakpoint-l) {
				width: 364px;
			}
		}
	}

	&__container {
		> iframe {
			width: 100%;
			height: 500px;
			border: 0;
		}

		h2 {
			.anwb-thesans-semi-bold-italic();
			font-size: @heading-l;
		}

		min-height: 100px;
		max-height: 100%;
		height: auto;
	}

	&__footer {
		display: flex;
		justify-content: space-between;
		margin-top: 2rem;

		> button + button { // fixme: should be in the (new BEM) button styles imho
			margin-left: 1rem;
		}
	}

	&__closebutton {
		position: absolute;
		top: 0;
		right: 0;
		padding: .5rem;
		text-decoration: none;

		&:after {
			.icon;
			content: @var-icon-cross;
			.icon-sm;
			display: block;
		}
	}

}

