/* Layout forms ------------------------------------------------ */
/* ------------------------------------------------------------- */

.grid-form {
	.form-layout();
	.panel-widget & {
		fieldset{
			max-width: none;
		}
	}
	.container &,
	.container_attention & {
		&:extend(._panel);
	}
	& label:first-of-type > a:not([data-ui]) {
		float: right;
	}
	// Other options setup
	& label > input[data-toggle]:not(:checked) ~ input[type=text]{
		display: none;
	}
	& label > input[data-toggle]:checked ~ input[type=text]:focus {
		display: inline-block;
		pointer-events: none;
	}
}



form.grid-form > header,
.grid-form form > header {
	position: sticky;
	top: 0;
	z-index: @z-index-sticky;
	background-color: @white;
	[class*="attention"] & {
		background-color: @blue-grey-lighter;
	}
}
.inputset {
	._inputset();
	@media (@breakpoint-m) {
		& > *:not(button){
			& > button,
			& > input[class*='btn'] {
				margin-top: @offset-m;
			}
		}

		.panel-widget & {
			& > div {
				display: flex;
				flex-direction: column;
				flex: 1;
				&:last-child {
					align-self: flex-end;
				}


				& > *:not([data-input-size]) {
					align-self: flex-start;
					&:not(button):not(select):not([class*='btn']) {
						flex: 1 1 auto;
					}
					& > span {
						display: flex;
						flex-direction: row;
					}
				}
			}
		}
		.grid-full .grid-form & {
			& > *:not([data-input-size]) {
				flex: 1 0 auto;
				width: auto;
				min-width: 0;
			}
		}

		.grid-overview .grid-form &,
		.grid-balanced .grid-form &,
		.grid-centered .grid-form &,
		.grid-stacked .grid-form &,
		.grid-asymmetric > *[data-sidebar='true'] .grid-form &,
		.grid-asymmetric > aside .grid-form &,
		.grid-content > aside .grid-form & {
			& > div:not([data-input-size]){
				flex: 1 1 100%;
				width: 100%;
				min-width: 100%;
			}
		}
		.grid-asymmetric > *:not(aside):not([data-sidebar]) .grid-form &,
		.grid-content > div .grid-form & {
			& > div:not([data-input-size]) {
				flex: 1 0 49%;
				min-width: 49%;
				max-width: 49%;
				width: 49%;			}
		}
	}
	@media (@breakpoint-l) {

		.panel-widget .grid-form & {
			& > div:not([data-input-size]) {
				flex: 1 0 auto;
				min-width: 0;
				max-width: none;
			}
		}
	}
}

