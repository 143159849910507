.panel-twitter {
  &:extend(._panel all);

  & > .panel-body {
    &:extend(._panel-border all);
    padding: 0;

    > h3 {
      &:extend(._panel-title-h3 all);

      ._panel-padding();
      padding-bottom: 0;

      &:before {
        .icon;
        content: @var-icon-social-twitter;
        margin-right: @rem-xs;
        color: @twitter-color;
        font-size: @heading-l;
      }

      @media (@breakpoint-m) {
        padding-bottom: 0;
      }
      @media (@breakpoint-l) {
        padding-bottom: 0;
      }
    }

    > a {
      ._panel-padding();
      @media (@breakpoint-m) {
        padding-top: 0;
      }
      @media (@breakpoint-l) {
        padding-top: 0;
      }
      display: block;
      margin-top: @margin-text-negative-s;
    }

  }
}

.twitter-list {
  &:extend(._list-unstyled all);

  small {
    display: inline-block;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: @offset-xs;
    width: auto;
    color: @blue-grey-dark;
    font-weight: normal;

  }

  > li {
    ._panel-padding();
    @media (@breakpoint-m) {
      padding-top: 0;
      padding-bottom: @rem;
    }
    @media (@breakpoint-l) {
      padding-top: 0;
      padding-bottom: @rem;
    }
    padding-top: 0;
    padding-bottom: @rem;
    max-width: 100%;
    width: 100%;
    border-bottom: solid @line @blue-grey-light;

    &:last-of-type {
      border: none;
    }

    > p {
      padding-top: @rem-s;
      display: block;
      margin-bottom: 0;
      padding-bottom: @rem-xs;
      max-width: 100%;
      width: 100%;
      font-weight: normal;
    }
  }
}

.twitter-list > li > div {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: baseline;
  flex-flow: wrap;

  > small {
    display: inline-block;
    padding-right: @rem-xs;
    padding-bottom: 0;

    > a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: none;
        color: inherit;

      }
    }

  }

  > span {
    display: flex;
    flex-direction: row;
    padding: 0;
    width: auto;

    > small {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;

      &:after {
        padding: 0 @rem-xs;
        content: "\00B7";
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }
}

.twitter-list:empty:after {
	._panel-padding();
	text-align: center;
	display: block;
	content: 'Tweets tijdelijk niet beschikbaar';
}

.twitter-list.cycle-children > li {
	border: 0;
	padding-left: 0;
	padding-right: 0;
}

.twitter-list a.username {
	color: @blue-dark; 
	text-decoration: none;
	font-weight: bold;

	&:hover {
		color: @blue;
	}
}
