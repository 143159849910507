
@arrow-width: 10px;

// Info icon placement
a[data-ui=tooltip] {
	cursor: pointer;
	position: relative;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
  	-webkit-tap-highlight-color: transparent;
	text-decoration: none;

	&:before {
		.icon;
		content: @var-icon-information;
		top: @margin-text-negative-s / 2;
		font-size:@rem-l;
		padding: @rem-xs;
		cursor: pointer;
		color:@blue;

		@media (@breakpoint-m) {
			padding: 0 @rem-xs 0 @rem-xs;
		}
	}

	&.active:before{
		color: @blue-dark;
	}

	&:hover:before{
		@media (@breakpoint-m) {
			color: @blue-dark;
		}
	}
}


// base tooltip
[class^="tooltip"] {
	width: ~'calc(100% - 1.6rem)';
	position: absolute;
	z-index: @z-index-popup;
	display: inline-flex;
	flex-direction: column;
	padding: @grid-offset-s @grid-offset-m @grid-offset-s @grid-offset-s;
	border-radius: @border-radius;
	background-color: @blue-dark;
	color: @white;
	margin-left: @grid-offset-s;

	@media (@breakpoint-m) {
		max-width: 422px;
		margin-left: 0;
	}	

	// arrow base
	&:after {
		position: absolute;
		content: '';
	}

	> h3,
	> p {
		flex: 0 1 auto;
		margin-right: @rem-xl;
	}
	
	> a {
		position: absolute;
		right: @grid-offset-s;
		margin-right: 0;
		width: 40px;
        height: 40px;
		display: block;
		text-decoration: none;
		
		&:after {
			.icon;
			content: @var-icon-cross;
			position: absolute;
			right: 0;
			margin-right: 0;
            color: @white;
            font-size: 14px;
		}
	}


	> div > h3 {
		margin-bottom: 0;
		color: @white;
		font-style: italic;
		font-size: @font-size-m;
		line-height: @line-height-s;
	}

	> div > p {
		margin-top: 0;
		margin-bottom: 0;
		font-size:@font-size-xs;

		> a {
			color: @white;

			&:focus,
			&:hover {
				color: @blue;
			}
			&:active {
				color: @orange;
			}
		}
	}

}

// Arrow direction

//left
.tooltip-top-left .arrow-holder {
	.arrow('top', @arrow-width, @blue-dark);
	top: -(@arrow-width - 1);
	left: (@arrow-width * 3)
}

// middle
.tooltip-top-middle .arrow-holder {
	.arrow('top', @arrow-width, @blue-dark);
	top: -(@arrow-width - 1);
	left: ~'calc(50% - 10px)'
}

// right
.tooltip-top-right .arrow-holder{
	.arrow('top', @arrow-width, @blue-dark);
	top: -(@arrow-width);
	right: (@arrow-width * 3)
}


// TODO: Move to Core less?
// Mixins

// Arrow
// usage: .arrow(direction:'top|right|bottom|left', @arrow-width:N, @color:COLOR)

.arrow(@direction:'top', @arrow-width:10px, @color:@blue) {
	position: absolute;
	width: 0;
	height: 0;

	& when ( @direction = 'top' ) {
		border-left: @arrow-width solid transparent;
		border-right: @arrow-width solid transparent;
		border-bottom: @arrow-width solid @color;
	}
	& when ( @direction = 'right' ) {
		border-top: @arrow-width solid transparent;
		border-bottom: @arrow-width solid transparent;
		border-left: @arrow-width solid @color;
	}
	& when ( @direction = 'bottom' ) {
		border-left: @arrow-width solid transparent;
		border-right: @arrow-width solid transparent;
		border-top: @arrow-width solid @color;
	}
	& when ( @direction = 'left' ) {
		border-top: @arrow-width solid transparent;
		border-bottom: @arrow-width solid transparent;
		border-right: @arrow-width solid @color;
	}
}
