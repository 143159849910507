//Base
.topic-grid {
	._list-unstyled;
	padding-top: @rem-xs;

	li {
		display: flex;
		padding: @grid-unit-s;
		width: 100%;
		border-bottom: solid @blue-grey-light @line;
		color: @blue-dark;
		text-decoration: none;
		font-weight: bold;
		line-height: @line-height-s;
		align-items: center;
		&:first-child {
			border-top: solid @blue-grey-light @line;
		}

		@media (@breakpoint-m) {
			padding: @margin-text-s;
			border: 0;
			&:first-child {
				border: 0;
			}
		}
	}

	a {
		display: flex;
		flex-direction: row;
		color: inherit;
		text-decoration: none;
		align-items: center;
		.hyphenate();
		&:hover { color: @blue; }
	}

	i {
		.icon-xxxl;
	}
	i + * {
		font-weight: bold;
		margin-left: @rem-s;
		font-family: @anwb-font-family;
	}
}

//
@media (min-width: @viewport-m) {
	.topic-grid {
		.grid('wrapped', 5);
		padding: 0 @grid-offset-m;

		& > li {
			display: flex;
			flex-direction: column;
			> * {
				display: block;
				max-width: 100%;
				flex-shrink: 1;
				align-self: center;
				text-align: center;
			}
		}

		& > li > a {
			display: block;
			> * {
				display: block;
				margin: 0 auto;
			}
		}

		i { .icon-jumbo-xxl; }

		i + * {
			margin-left: 0;
			text-align: center;
			padding-top: @rem-xs;
		}
	}

}

@media (min-width: @viewport-m-max) {
	.topic-grid {
		.grid('wrapped', 6);
	}
}
