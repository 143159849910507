.@{anwb-prefix}contact {
	@block: ~"@{anwb-prefix}contact";

	&__title {
		font-family: @anwb-font-family;
		font-style: italic;
		font-weight: 600;
		margin-top: 0;
		margin-bottom: .4rem;
	}

	&__prefix,
	&__postfix,
	&__social {
		display: inline-block;
		font-family: @anwb-font-family;
		font-size: @heading-s;
	}

	&__telephone {
		display: inline-block;
		font-family: @anwb-font-family;
		font-size: @heading-panel-m;
		line-height: 1.3;
		text-decoration: none;
		color: @blue-grey-darker;
	}

	&__whatsapp::before {
		display: inline-block;
		content: @var-icon-social-whatsapp;
		.size(@icon-size-xxl);
		.font-size(@icon-size-xxl);
		margin-right: @rem-xs;
	}

	&__facebook::before {
		display: inline-block;
		content: @var-icon-social-facebook;
		.size(@icon-size-xxl);
		.font-size(@icon-size-xxl);
		margin-right: @rem-xs;
	}

	&__table {
		width: 100%;

		thead {
			background-color: transparent;
		}

		//td {
		//	padding-right: @rem-xs;
		//}

		tr:first-of-type td,
		tr:last-of-type td,
		td:first-of-type,
		td:last-of-type,
		td {
			text-align: left;
			vertical-align: top;
			border: 0 none;
			padding: @rem-xs/2 @rem-xs @rem-xs/2 0;
		}
	}
}