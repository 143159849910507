/* === Lists === */

// - list-unstyled
// - list-grid
// - list-grid-gutter
// - list-stacked
// - list-checklist

// --------------------------

/* === default list === */
// --------------------------
ol {
	padding-left: @grid-offset-l;
}

ul {
	padding-left: @grid-unit-m;

}

ol, ul {
	ol, ul {
		margin-top: @offset-s;
		margin-bottom: @offset-s;
		> li {
			margin-top: auto;
			margin-bottom: auto;
		}
	}
	ol {
		padding-left: @grid-unit-l;
	}
}

// --------------------------

/* === list-unstyled === */
// --------------------------

._list-unstyled {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

/* === list-grid === */
// --------------------------

.list-grid {
	&:extend(._list-unstyled all);

	display: flex;
	flex-direction: column;
	align-items: flex-start;

	> li {
		flex: 0 1 auto;

		&.break-right {
			margin-left: 0;
		}
	}

	@media (@breakpoint-m) {
		align-items: center;
		flex-direction: row;

		> li {
			margin-right: 2rem;

			&:last-child {
				margin-right: 0;
			}

			&.break-right {
				margin-left: auto;
			}
		}
	}

}

/* === list-checklist === */
// --------------------------
.list-checklist {
	&:extend(._list-unstyled all);
	margin-top: @margin-text-m;
	margin-bottom: @margin-text-m;
	display: block;

	@media (@breakpoint-m) {
		//margin-bottom: @margin-text-xl;
	}

	> li {
		padding-left: @rem-xl;
		margin-top: @offset-s;
		margin-bottom: @offset-s;
	}

	> li:before {
		.icon;
		content: @var-icon-check-usp;
		color: @green;
		margin-left: -@rem-xl;
		padding-right: @rem-s;
	}
	
	> li.crossed-item:before {
		content: @var-icon-cross;
		color: @blue;
	}

	.list-checklist {
		padding-left: @grid-offset-m;
	}
}

/* === list-linklist-Base === */
// ----------------------------

.list-linklist {
	&:extend(._list-unstyled all);
	> li > * {
		display: flex;
		padding: @grid-unit-s;
		width: 100%;
		color: @blue-dark;
		text-decoration: none;
		font-weight: bold;
		line-height: @line-height-s;
		align-items: center;

		@media (@breakpoint-l) {
			padding-right: @rem-l;
			padding-left: @rem-l;
		}

	}
}
