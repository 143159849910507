//
// Panel List Theme
//--------------------------------------------------

.panel-list-theme {
	._panel-list;
	& > .panel-body {
		background: @blue-grey-lighter;
	}
	.panel-main {
		border-bottom-right-radius: @border-radius;
		border-bottom-left-radius: @border-radius;
		overflow: hidden;

		ul {
			> li:first-child { margin-top: @offset-s; }

			> li:nth-child(odd) {
				background: @blue-grey-light;
			}
		}
	}
}

// Generic Panels
// TODO: These styles are now used inside Panel-list-group & Theme
// and still need refactoring. But this lower part is generic
._panel-list {
	&:extend(._panel all);
	> .panel-body {
		padding: 0;
	}

	.panel-main > h3 {
		&:extend(._panel-title-h3 all);
		._panel-padding();
		padding-right: @grid-unit-s;
		padding-left: @grid-unit-s;

		@media (@breakpoint-m) {
			padding-right: @grid-unit-s;
			padding-left: @grid-unit-s;
		}
		@media (@breakpoint-l) {
			padding-right: @rem-l;
			padding-left: @rem-l;
		}

	}
	// Generic lists
	.panel-main > ul {
		&:extend(.list-linklist all);
		display: table;
		border-collapse: collapse;
		width: 100%;

		li {
			display: table-row;
			margin-top: 0;
			margin-bottom: 0;
		}

		li > * {
			display: table-cell;
			word-break: break-word;
			width: 100%;
			font-weight: normal;
			line-height: @line-height-base;
			font-size: @font-size-m;
			font-family: @anwb-font-family;
			padding-right: @grid-unit-s;
			padding-left: @grid-unit-s;
			@media (@breakpoint-m) {
				padding-right: @grid-unit-m;
				padding-left: @grid-unit-m;
			}
			@media (@breakpoint-l) {
				padding-right: @rem-l;
				padding-left: @rem-l;
			}

			&.active {
				color: @blue;
			}
		}
		a > span {
			line-height: @line-height-base;
			font-size: @font-size-m;

			&.active {
				color: @blue;
			}
		}
		a > small {
			display: block;
			float: left;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: wrap;
			font-weight: normal;
			padding-bottom: @line;
		}
	}
}
