.@{anwb-prefix}label {
	@block: ~"@{anwb-prefix}label";

	display: inline-block;
	border-radius: @border-radius;
	background-color: @blue-grey-lighter;
	color: @blue-dark;
	padding: @margin-text-s @margin-text-m;
	text-decoration: none;
	font-family: @anwb-font-family;
	line-height: 1.4;
	margin-bottom: @margin-text-s;
	margin-right: @margin-text-s;

	&:hover {
		color: @blue;
	}

	&--close {
		&::before {
			content: @var-icon-cross;
			display: inline-block;
			margin-right: 7px;
			font-weight: normal;
			transform: scale(1);
			transition: transform .2s;
		}

		&:hover::before {
			transform: scale(.8);
		}
	}

	&--badge {
		background-color: @blue-grey-light;
		color: inherit;
		.anwb-thesans-semi-bold-italic();
		font-size: @heading-s;
		line-height: @line-height-compressed;

		&:hover {
			color: inherit;
		}

		.@{block}__numbers {
			font-size: @heading-m;
			vertical-align: middle;

			a {
				text-decoration: none;
				color: inherit;
			}
		}
	}
}