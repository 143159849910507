.embed-container {
	position: relative;
	padding-bottom: 56.25%; // VooDoo code :)
	//overflow: hidden;
	max-width: 100%;
	height: auto;

	& > iframe,
	& > object,
	& > embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translate3d(0,0,0);
		border-top-left-radius: @border-radius - @line;
		border-top-right-radius: @border-radius - @line;
	}

	[class|=panel] header & {
		left: @line;
		top: @line;
		border-top-left-radius: @border-radius - @line;
		border-top-right-radius: @border-radius - @line;
		margin-bottom: @rem;
	}
}


