.@{anwb-prefix}results {
	@block: ~"@{anwb-prefix}results";

	ul&,
	ol&,
	> ul,
	> ol {
		.reset-list();
		max-width: 100%;
	}

	&__header,
	&__item {
		border-top: 2px solid @blue-grey-light;
		padding-top: 1rem;
		overflow: hidden;
	}

	&__title {
		font-weight: normal; // although the ANWB font does not support it...
		margin: 0;
		overflow: hidden;

		a&,
		a {
			text-decoration: none;
			color: @blue-dark;

			&:hover {
				color: @blue;
			}
		}
	}

	&__sub-title {
		font-size: @font-size-xs;
		color: @blue;

		a {
			text-decoration: none;
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__text {
		a& {
			text-decoration: none;
		}
		p,
		p& {
			margin-top: 0;
		}
		p:last-child {
			margin-bottom: 0;
		}
		display: block;
		margin-bottom: 1rem;
	}

	&__image {
		img,
		img& {
			margin-top: 0;
			width: 100%;

			@media (@breakpoint-m) {
				float: left;
				margin-right: 1rem;
				max-width: 200px;
				width: 100%;
			}

			@media (@breakpoint-g) {
				max-width: 280px;
			}
		}
	}

	&__price {
		color: @orange;
		.anwb-thesans-bold();

		strike {
			.anwb-thesans-semi-bold-italic();
		}
	}

	&--tiles {
		@media (@breakpoint-m) {
			display: flex;
			flex-wrap: wrap;
		}

		> * {
			min-width: 180px;
			padding-left: 1rem;
			padding-right: 1rem;

			@media (@breakpoint-m) {
				flex: 0 0 25%;
			}
		}

		.@{block}__item {
			text-align: center;
			border-top: none;

			.rating { // FIXME: when BEMmed, change to that class-name
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 1rem;
				margin-top: 0;
			}

			img {
				float: none;
				margin: 0;
				width: 120px;
				height: 160px;
			}
		}

		.@{block}__header {
			flex: 0 0 100%;
			padding-left: 0;
			padding-right: 0;
		}

		.@{block}__title {
			.hyphenate();
			hyphens: initial;
		}
	}
}

// FIXME: When panels a.o. are BEMified replace it with the correct block:
.panel-highlight {
	&:extend(._panel-border all);
	._panel-grid-offset();
	margin-bottom: 1rem;

	h3 {
		&:extend(._panel-title-h3 all);

		a[class|='link']::after {
			font-size: 0.8em;
		}
	}
}