.@{anwb-prefix}link {
	@block: ~"@{anwb-prefix}link";

	color: @blue-dark;
	text-decoration: none;
	font-family: @anwb-font-family;
	.link-icon();

	// all links that have a href attribute have an arrow icon by default (if made visible)
	&[href]::after {
		content: @var-icon-arrow-right;
	}

	// all absolute urls that do not contain .anwb.nl/ will be interpreted as external
	&[href^='http']:not([href*='.anwb.nl']):after {
		content: @var-icon-external-link;
	}

	// supported document links
	&[href$='.pdf']::after,
	&[href$='.doc']::after,
	&[href$='.docx']::after {
		content: @var-icon-document;
	}

	&__sub {
		display: block;
		float: left;
		font-family: @default-fonts;
		font-weight: normal;
		width: 100%;
	}
}