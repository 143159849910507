.panel-more-news {
  ._panel-list;

  & > .panel-body {
    &:extend(._panel-attention all);
    &:extend(._panel-border all);
    border-color: @blue-grey-lighter;

    .panel-main > :last-child {
      margin-bottom: 1.2rem;
    }

    .panel-main > ul:last-child {
      margin-bottom: 0;
    }
	h3 + ul {
		border-top: solid @line @blue-grey-light;
	}
    ul > li {
      border-bottom: solid @line @blue-grey-light;
      &:last-child { border: none; }

      > a {
        > span {
          font-size: @font-size-s;
          word-break: break-word;
        }
      }
    }

    header {
      img {
        margin-top: 0;
        margin-bottom: 0;
        border-radius: @border-radius @border-radius 0 0;
      }

      @media (@breakpoint-s) {
        display: none;
      }
    }

    .panel-main > h3 ~ * {
      @media (@breakpoint-s) {
        display: none;
      }
    }

    h3, h4, small, p {
      padding-right: 1.2rem;
      padding-left: 1.2rem;
    }

    p {
      font-size: @font-size-s;
    }

  }
}

.section-informational .panel-more-news {
  > .panel-body {
    background-color: @white;

    ul > li {
      &:after {
        border-color: @blue-grey-lighter;
      }
    }

  }
}