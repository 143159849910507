@breakpoint-l--menu: ~"min-width: 1220px";

@media (@breakpoint-m--menu) {

	.navigation-main {

		position: relative;
		//top: 3px;
		.flex();
		background: @yellow;
		border-radius: @border-radius;

		@media (@breakpoint-m) {
			margin: 0 @panel-grid-m;

			& > * {
				pointer-events: inherit;
			}
		}

		@media (@breakpoint-l) {
			margin: 0 @panel-grid-l;
		}

		@media ( @breakpoint-l--menu ) {
			margin: 0 ~'calc( ( 100vw - 1220px ) / 2 + @{panel-grid-l} )';
			max-width: 1220px;
		}
	}
	.navigation-main:last-child {
		margin-bottom: -2rem;
		z-index: @z-index-menu;
	}
	.navigation-content {
		margin: 0;
		padding: 0;
		display: block;
		list-style: none;
		//width: 100%;
		max-width: none;

		position: relative;
		pointer-events: none;
		max-width: none;
		//margin:0 0 0 -0.5rem;
		//

		& > li {
			margin: 0;
			padding: 0;
			display: block;
			list-style: none;
			width: 100%;
			max-width: none;
			font-size: 1rem;
			& > label{
				display: none;
			}
		}
		& > li > ul {
			display: flex;
			justify-content: flex-start;
			list-style: none;
			max-width: none;
			padding: ( @margin-text-m / 2 ) 0;
			margin: 0 0 0 0.7rem;

			@media (@breakpoint-l) {
				margin: 0 0 0 0.2rem;
				padding: 0.4em 0 0.4em 0.6em;
			}
			[type=checkbox],[type=radio] {
				display: none;
			}


			ul,
			li {
				list-style: none;
				margin: 0;
				padding: 0;
				display: inline-block;

				@media ( @breakpoint-l) {
					//float: none;
				}
			}

			& > li {

				margin: 0;
				display: inline-block;

				& > label a,
				& > a {
					display: inline-block;

					padding: @margin-text-m;


					margin: 0;
					color: @blue-dark;
					text-decoration: none;
					font-size: @font-size-m;
					@media ( @breakpoint-l) {
						font-size: @font-size-l;
					}
					font-family: @anwb-font-family;

				}
				pointer-events: initial;
			}


			.anwb_hover();


			& li.active label a{
				border-radius: @border-radius-s;
				background: hsla(0, 0%, 100%, 0.5);
			}
			& li:not([aria-haspopup]){
				border-radius: @border-radius-s;

				transition: background @duration-out ease-out;
			}
			& li:not([aria-haspopup]):not(.utility-links) {
				&:hover{
					background: @white;
					transition: background @duration-in ease-in;
				}
				& a:hover {
					color: @blue;
				}
				& a:active {
					color: @orange;
				}

			}

			& [aria-haspopup] {
				position: relative;
			}
			& [aria-haspopup] > label {
				display: inline-block;
				position: relative;
				&::before {
					box-shadow: none !important;
				}

			}
			& [aria-haspopup] > ul{
				border-top-right-radius: @border-radius-s;
				box-shadow: 0 2px 5px @shadow-color-light;
			}

			& [aria-haspopup]:hover > label,
			& [aria-haspopup]> :checked ~ label {
				& > a {
					color: @blue;
				}

				&::before {
					box-shadow: none;
				}
				&::after {
					display: none;
				}
			}
		}
	}


	.navigation-content .utility-links {
		position: absolute;
		top: -2.8em;
	 	left: 0;
		width: 100%;
		margin: 0;
		padding: 0;
		display: block;
		pointer-events: inherit;

		@media (@breakpoint-m--menu) {
			width: ~'calc(100% - 240px)';
			left: 160px;
		}

		:hover{
			background: transparent;
			a::before{
				opacity: 0;
			}
		}
		& > nav {
			position: static;
			max-width: none;
			text-align: right;
			display: block;
			padding-right: 6em;
			white-space: nowrap;
			pointer-events: initial;
			& > a {
				padding: calc(@margin-text-m - 0.2rem);
				font-size: @font-size-s;
				display: inline-block;
				color: @blue-dark;
				text-decoration: none;
			}
			& a:hover {
				color: @blue;
			}
			& a:active {
				color: @orange;
			}
		}

	}
}

@media (@breakpoint-m) {
	.navigation-main {
		display: block;
	}
}
