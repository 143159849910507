.@{anwb-prefix}xsell {
	text-decoration: none;

	&,
	&:active {
		color: @blue-dark;
	}

	.@{anwb-prefix}panel__main {
		@media (@breakpoint-s) {
			display: table;
			min-height: @sticker-m - @panel-grid-s;
			height: @sticker-m - @panel-grid-s; // fix for firefox
			width: 100%;
		}
	}

	.@{anwb-prefix}panel__header {
		@media (@breakpoint-s) {
			display: table-cell;
			vertical-align: middle;
		}
	}

	.@{anwb-prefix}sticker {
		@media (@breakpoint-s) {
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	&:hover {
		&.@{anwb-prefix}panel--standard .@{anwb-prefix}panel__main {
			border-color: @blue-dark;
		}

		&.@{anwb-prefix}panel--promotion .@{anwb-prefix}panel__main {
			background-color: @yellow-light;
		}

		.@{anwb-prefix}panel__title {
			&, &::after {
				color: @blue;
			}

			&::after {
				text-indent: 10px;
			}
		}
	}

	.@{anwb-prefix}panel__title {
		.link-icon();
		display: block; // reset to block

		&::after {
			content: @var-icon-arrow-right;
			color: @blue-dark;
		}

		&:hover::after {
			color: @blue;
		}
	}

	.@{anwb-prefix}panel__title:first-child,
	.@{anwb-prefix}sticker + .@{anwb-prefix}panel__title,
	.@{anwb-prefix}panel__poster + .@{anwb-prefix}sticker + .@{anwb-prefix}panel__title {
		@media (@breakpoint-s) {
			margin-bottom: 0;
			margin-top: 0;
			padding-top: 0;
		}
	}

	&__secondary-content {
		display: none;

		@media (@breakpoint-m) {
			display: block;
		}
	}

	///* in balanced grid */
	//.grid-balanced &, // legacy
	//.@{anwb-prefix}grid__balanced & {
	//	//flex-direction: column;
	//	display: flex;
	//	flex: 1 1 100%;
	//
	//	.@{anwb-prefix}panel__main {
	//		flex: 1 1 100%;
	//	}
	//}
}