/* === Sticker === */
/*
Variables

Mixins
- Base
- Body 
- Outline

// SIZES
- Responsive (85/70)
- Fixed (60)

Variants
- sticker-information
- sticker-rating
- sticker-price

*/

// Variables
// -------------------------------
@sticker-s: unit(( 58 / @target-px-size ), rem);
@sticker-m: unit(( 70 / @target-px-size ), rem);
@sticker-l: unit(( 85 / @target-px-size ), rem);

// Mixins
// -------------------------------

// Sticker Base
._sticker {
	display: flex;
	padding: 0 5px;
	border-radius: 50%;
	justify-content: center;
	flex-direction: column;

	> .sticker-body {
		._sticker-body();

		//IE10/11 fix to make sure content stays within the coloured circle thusly stays readable
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: block;
			margin: 0px auto;
			text-align: center;

			> span {
				display: block;
			}
		}
	}

	.sticker-line-1 {
		font-size: @heading-l;
		font-weight: bold;
		font-style: normal;
	}

	.sticker-line-2 {
		font-size: @font-size-l;
		font-weight: bold;

		@media (@breakpoint-l) {
			font-size: unit(( 20 / @target-px-size ), em);
		}
	}

	.sticker-line-3 {
		font-size: @font-size-s;
	}

	.sticker-line-4 {
		font-size: @font-size-xs;

		@media (@breakpoint-l) {
			font-size: unit(( 13 / @target-px-size ), em);
		}
	}
}

// Sticker Body
._sticker-body() {
	display: flex;
	flex-direction: column;
	justify-content: center;

	> * {
		align-self: center;
		font-weight: normal;
		font-family: @anwb-font-family;
		line-height: @line-height-s;
		margin: 0;
		padding: 0;
		text-align: center;
	}
}

// Outline
._sticker-outline {
	&:extend(._sticker all);
	border: solid @blue-dark @line;
}

// SIZES
// -------------------------------

// Responsive
._sticker-responsive {
	width: @sticker-m;
	height: @sticker-m;
	@media (@breakpoint-l) {
		width: @sticker-l;
		height: @sticker-l;
	}
}

// Responsive
._sticker-md {
	width: 58px;
	height: 56px;
}

// VARIANTS
// -------------------------------

// Rating
.sticker-rating {
	&:extend(._sticker-outline all);
	&:extend(._sticker-md);

	& > * {
		color: @blue-dark;
	}
}

// Information
.sticker-information {
	&:extend(._sticker all);
	&:extend(._sticker-responsive all);
	background: @yellow;

	& > * {
		color: @blue-dark;
	}
}

// Price
.sticker-price {
	&:extend(._sticker all);
	&:extend(._sticker-responsive all);
	background: @red;

	& > * {
		color: @white;
	}
}

// Split header
// make room for a sticker
[class$='sticker'] + header,
[class^='sticker'] + header {
	//margin-right: @sticker-m;
	margin-right: ~'calc(@{sticker-m} - @{grid-unit-s})';
	@media (@breakpoint-l) {
		//margin-right: @sticker-l;
		margin-right: ~'calc(@{sticker-l} - @{grid-unit-s})';
	}
}