.@{anwb-prefix}search {
	@block: ~"@{anwb-prefix}search";

	&__form {
		padding: @grid-unit-s;
		@media (@breakpoint-m--menu) {
			padding: 0;
			margin: 0;
			display: flex;
			width: 100%;
			align-items: flex-start;
		}

		@media (@breakpoint-m--menu) {
			margin: @line 0 @line;
		}

		&.active {
			display: block;
		}
	}
	&__form-container {
		@media (@breakpoint-m--menu) {
			width: 78%;
		}
	}
	&__results {
		display: block;
		background-color: @white;
		margin: 0;
		padding: 0.2rem 0;
		border: @blue solid @line;
		border-top: 0 none;
		border-radius: @border-radius;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		list-style: none;
	}
	&__suggestion {
		display: block;
		text-decoration: none;
		color: @blue-dark;
		border: 0;
		padding: 0.4rem 0.7rem;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1.4;
		cursor: pointer;
		outline: 0;
		@media not all and (@breakpoint-m--menu) {
			.ANWB-header-search & {
				margin: 0 @grid-offset-s;
				line-height: 2.25;
			}
		}
		&:hover {
			color: @blue;
		}
		&--selected {
			color: @orange;
		}
	}

	&__input {
		&:first-child {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
		&:only-child {
			border-radius: @border-radius;
		}
		&:focus + * {
			border-color: @blue;
		}
	}
	&__submit {
		display: none;
		flex: 1 0 auto;
		margin-left: @panel-grid-m;

		@media (@breakpoint-m--menu) {
			display: inline-block;
		}

		&::after {
			content: @var-icon-search;
		}
	}

	&--small {
		@duration-in: 0.2s;
		@duration-out: 0.1s;
		@delay-in: @duration-in;
		@delay-out: @duration-out;

		@media (@breakpoint-m--menu) {
			margin-top: ~"calc(@{line} + @{rem-xs})";
			background-color: @white;
			border-radius: @border-radius;
			padding: @rem-xs @rem-s @line;
		}

		@media (@breakpoint-l) {
			margin-top: ~"calc(@{line} + @{rem-l})";
		}

		.@{block}__form {
			margin-top: 0;
			margin-bottom: 2px;
			display: flex;
			width: 100%;
			align-items: flex-start;
		}

		.@{block}__form-container {
			width: 100%;
			@media (@breakpoint-m--menu) {
				width: 248px;
			}
			@media (@breakpoint-g) {
				width: 268px;
			}
			transition-property: width;
			transition-duration: @duration-out;
			transition-delay: @delay-out;
			transition-timing-function: ease-out;
		}

		.@{block}__input {
			font-size: 0.8em;
			height: 34px;
		}

		.@{block}__submit {
			display: inline-block;
			padding-left: 1rem;
			padding-right: 1rem;
			width: 46px;
			height: 34px;
			white-space: nowrap;
			transition-property: width;
			transition-duration: @duration-out;
			transition-delay: @delay-out;
			transition-timing-function: ease-out;
			&::after {
				padding-left: 0;
			}
		}

		.@{block}__label {
			display: inline-block;
			text-align: left;
			width: 0;
			opacity: 0;
			transition-property: width, opacity;
			transition-duration: @duration-out;
			transition-delay: @delay-out, 0s;
			transition-timing-function: ease-out, ease-out;
		}

		&[data-focus="true"] {
			.@{block}__form-container {
				@media (@breakpoint-m--menu) {
					width: 206px;
				}
				@media (@breakpoint-g) {
					width: 226px;
				}
				transition-property: width;
				transition-duration: @duration-in;
				transition-delay: 0s;
				transition-timing-function: ease-in;
			}

			.@{block}__submit {
				width: 88px;
				transition-property: width;
				transition-duration: @duration-in;
				transition-delay: 0s;
				transition-timing-function: ease-in;
			}

			.@{block}__label {
				width: 34px + 7px;
				opacity: 1;
				transition-property: width, opacity;
				transition-duration: @duration-in;
				transition-delay: 0s, @delay-in;
				transition-timing-function: ease-in, ease-in;
			}
		}

		&[data-overlay="true"] {
			@media (@breakpoint-m--menu) {
				box-shadow: 0 0 5px @shadow-color-light;
			}
		}
	}

}