/*
* Tabs
*   - tabbox-horizontal
*   - tab-nav
*       - States
*   - tabbox-tabs
*   - tab
*   - tab-container
*   ----------------------
*   - tabbox-vertical
*/

div[class^="tabbox-"] {
	&:extend(._panel all);

	// Medium viewport
	@media (@breakpoint-m) {

		// Hide panel-header
		.container .tab > h3 { display: none; }

		.tab:after { display: none; } // remove line
	}
	.container{


		@media (@breakpoint-s) {
			padding-top: 0;
			padding-bottom: 0;
			border-radius: 0;
		}
	}

	.container > .container-body > .tab {
		padding-left: 0.8rem;
		padding-right: 0.8rem;

		@media (@breakpoint-m) {
			padding: 0;
		}
	}
	// tab
	.tab {
		position: relative;

		&:after {
			position: absolute;
			right: -@grid-unit-m;
			bottom: 0;
			left: -@grid-unit-m;
			border-top: solid transparent 1px;
			border-bottom: solid @white @line;
			content: '';
		}

		& > h3 {
			._panel-title-h3;
			position: relative;
			display: flex;
			&:hover {
				color: @blue;
			}
			&:after {
				.icon;
				content: @var-icon-chevron-down;
				position: absolute;
				top: 1.5em;
				right: 0;
				width: 2em;
				text-align: right;
				font-size: @font-size-xxs;
				pointer-events: none;
			}

		}
		& > h3 > label {
			position: relative;
			display: flex; // enlarge click area
			padding-top: @rem;
			padding-right: @rem-l; // enlarge click area
			padding-bottom: @rem;
			width: 100%;
			font-size: inherit;
			cursor: pointer;
		}
	}

	.tab-container {
		display: none;
		> :not([class|="panel"]):not([class|="grid"]) {
			display: none; // do not allow anything that's not a panel, a grid or a footer
		}
		// override panel style inside tab
		> [class|="panel"] {
			@media (@breakpoint-s) {
				padding-left: 0;
				padding-right: 0;
			}
			> .panel-body {
				padding: 0;
				> .panel-main {
					border-radius: @border-radius;
					background: @white;
					> h2 {
						font-size: @heading-m;
						font-style: normal;
					}
					> h3 { font-style: normal; }
				}
				> footer {
					padding-top: @rem-l;
					> * {
						max-width: inherit;
					}

					> a {
						._btn;
						._btn_arrow-right;
					}

					> p {
						margin-top: @margin-text-negative-s;
						&:last-child {
							margin-bottom: @margin-text-negative-s;
						}

						+ a {
							margin-top: @margin-text-negative-l;
						}
					}
				}
			}
		}
	}

	.tabbox-nav {
		display: none;

		@media (@breakpoint-m) {

			._list-unstyled;
			display: flex;
			justify-content: center;
			margin: 0 auto;
			max-width: auto;

			> li {
				position: relative;
				display: flex;
				margin: @rem @rem-xs;
				padding: @rem-s;
				border-radius: @border-radius;
				background: @blue-grey-light;
				color: @blue-dark;
				text-align: center;
				align-items: center;
				.hyphenate();
				&:after {
					width: 0;
					height: 0;
					content: '';
					left: ~'calc(50% - 10px)';
					bottom: -10px;
					position: absolute;
					border-left: 10px solid transparent;
					border-right: 10px solid transparent;
					border-top: 10px solid transparent;
				}
			}

			> li > label {
				cursor: pointer;
				font-family: @anwb-font-family;
				font-size: @heading-panel-m;
				font-style: italic;
				line-height: @line-height-compressed;
			}

			//State
			//-------------------------
			//Hover
			> li:hover {
				color: @blue;

				&:after {
					border-top: 10px solid @blue-grey-light;
				}
			}
		}
	}

	// start logic for toggling the tab panels (max. 10)
	@media (@breakpoint-m) {
		:nth-child(1):checked ~ .container .tab:nth-child(1) > .tab-container {
			display: block;
		}
	}
	// can probably be done with a less function?
	:nth-child(2):checked ~ .container .tab:nth-child(1) > .tab-container,
	:nth-child(3):checked ~ .container .tab:nth-child(2) > .tab-container,
	:nth-child(4):checked ~ .container .tab:nth-child(3) > .tab-container,
	:nth-child(5):checked ~ .container .tab:nth-child(4) > .tab-container,
	:nth-child(6):checked ~ .container .tab:nth-child(5) > .tab-container,
	:nth-child(7):checked ~ .container .tab:nth-child(6) > .tab-container,
	:nth-child(8):checked ~ .container .tab:nth-child(7) > .tab-container,
	:nth-child(9):checked ~ .container .tab:nth-child(8) > .tab-container,
	:nth-child(10):checked ~ .container .tab:nth-child(9) > .tab-container,
	:nth-child(11):checked ~ .container .tab:nth-child(10) > .tab-container {
		display: block;
	}
	.tab > h3 > label + label {
		display: none;
		opacity:0;
		left:0;
		position:absolute;
	}
	:nth-child(2):checked ~ .container .tab:nth-child(1) > h3,
	:nth-child(3):checked ~ .container .tab:nth-child(2) > h3,
	:nth-child(4):checked ~ .container .tab:nth-child(3) > h3,
	:nth-child(5):checked ~ .container .tab:nth-child(4) > h3,
	:nth-child(6):checked ~ .container .tab:nth-child(5) > h3,
	:nth-child(7):checked ~ .container .tab:nth-child(6) > h3,
	:nth-child(8):checked ~ .container .tab:nth-child(7) > h3,
	:nth-child(9):checked ~ .container .tab:nth-child(8) > h3,
	:nth-child(10):checked ~ .container .tab:nth-child(9) > h3,
	:nth-child(11):checked ~ .container .tab:nth-child(10) > h3 {
		&  > label + label {
			display: inherit;
		}
		&:after { content: @var-icon-chevron-up; }
	}
}

div.tabbox-horizontal {
	overflow: hidden;
	padding: 0;

	@media (@breakpoint-m) {
		padding: @_grid-base;
		margin-left: 0;
		margin-right: 0;
        clear: both;
        
        input[type="radio"] {
            top: 44px;
        }
	}

	@media (@breakpoint-l) {
		padding: @grid-unit-s;
	}

	// override panel style inside tab
	.tab-container > [class|=panel] > .panel-body {
		& > .panel-main {
			padding: @rem;
		}
		& > footer {
			text-align: center;
		}
	}
	// start logic for toggling the tab panels (max. 10)
	:nth-child(1):checked ~ .tabbox-nav > :nth-child(1),
	:nth-child(2):checked ~ .tabbox-nav > :nth-child(1),
	:nth-child(3):checked ~ .tabbox-nav > :nth-child(2),
	:nth-child(4):checked ~ .tabbox-nav > :nth-child(3),
	:nth-child(5):checked ~ .tabbox-nav > :nth-child(4),
	:nth-child(6):checked ~ .tabbox-nav > :nth-child(5),
	:nth-child(7):checked ~ .tabbox-nav > :nth-child(6),
	:nth-child(8):checked ~ .tabbox-nav > :nth-child(7),
	:nth-child(9):checked ~ .tabbox-nav > :nth-child(8),
	:nth-child(10):checked ~ .tabbox-nav > :nth-child(9),
	:nth-child(11):checked ~ .tabbox-nav > :nth-child(10)  {
		background: @blue-dark;
		color: @white;
		&:after {
			border-top-color: @blue-dark;
		}
	}
}

div.tabbox-vertical {
	overflow: hidden;
	margin-right: @panel-grid-s;
	margin-left: @panel-grid-s;
	padding: 0;
	border: @line solid @blue-grey-light;
	border-radius: @border-radius;

	@media (@breakpoint-m){
		.grid('balanced',2);
		border:0;
		padding: @panel-grid-m;
	}

	@media (@breakpoint-l) {
		padding: @panel-grid-l;
	}

	@media (@breakpoint-m) {
		> * {
			flex: 1 1 70%;
		}
	}

	.container {
		background: #fff;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;

		@media (@breakpoint-m){
			border: @line solid @blue-grey-light;
		}
	}

	.tab {

		&:after {
			position: absolute;
			right: -@grid-unit-m;
			bottom: 0;
			left: -@grid-unit-m;
			border-top: solid transparent 1px;
			border-bottom: solid @blue-grey-light @line;
			content: '';
		}

		&:last-of-type {
			border-bottom: 0;
		}

		@media (@breakpoint-m) {
			border: 0;
		}
	}

	@media (@breakpoint-m) {
		.tabbox-nav {
			border: @line solid @blue-grey-light;
			border-right: 0;
			flex: 1 1 30%;
			align-self: flex-start;
			display: block;
			border-top-left-radius: @border-radius;
			border-bottom-left-radius: @border-radius;
			overflow: hidden;
			li {
				background: #fff;
				border-radius: 0;
				display: block;
				margin: 0;
				padding:0;
				border-bottom: @line solid @blue-grey-light;
				text-align: left;

				&:last-child{
					border-bottom: none;
				}
				label {
					color: @blue-dark;
					display: block;
					padding-right: @grid-unit-m;
					padding-left: @grid-unit-m;
					padding-top:~'calc(@{rem} - @{line})';
					padding-bottom:@rem;

					@media (@breakpoint-l) {
						padding-right: @rem-l;
						padding-left: @rem-l;
					}

				}

				&:after {
					display: none;
				}
			}

			> li:hover {
				label {
					color: @blue;
				}
			}
		}
	}

	// start logic for toggling the tab panels (max. 10)
	:nth-child(1):checked ~ * li:nth-child(1) label,
	:nth-child(2):checked ~ * li:nth-child(1) label,
	:nth-child(3):checked ~ * li:nth-child(2) label,
	:nth-child(4):checked ~ * li:nth-child(3) label,
	:nth-child(5):checked ~ * li:nth-child(4) label,
	:nth-child(6):checked ~ * li:nth-child(5) label,
	:nth-child(7):checked ~ * li:nth-child(6) label,
	:nth-child(8):checked ~ * li:nth-child(7) label,
	:nth-child(9):checked ~ * li:nth-child(8) label,
	:nth-child(10):checked ~ * li:nth-child(9) label,
	:nth-child(11):checked ~ * li:nth-child(10) label {
		color: @blue;
	}

	:nth-child(2):checked ~ * .tab:nth-child(1) > h3,
	:nth-child(3):checked ~ * .tab:nth-child(2) > h3,
	:nth-child(4):checked ~ * .tab:nth-child(3) > h3,
	:nth-child(5):checked ~ * .tab:nth-child(4) > h3,
	:nth-child(6):checked ~ * .tab:nth-child(5) > h3,
	:nth-child(7):checked ~ * .tab:nth-child(6) > h3,
	:nth-child(8):checked ~ * .tab:nth-child(7) > h3,
	:nth-child(9):checked ~ * .tab:nth-child(8) > h3,
	:nth-child(10):checked ~ * .tab:nth-child(9) > h3,
	:nth-child(11):checked ~ * .tab:nth-child(10) > h3 {
		color: @orange;
	}
}
