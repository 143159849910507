.@{anwb-prefix}section {
	@block: ~"@{anwb-prefix}section";

	margin-top: @rem-component-m;
	margin-bottom: @rem-component-m;
	padding-bottom: (@grid-offset-s - @panel-grid-s);

	@media (@breakpoint-m){
		padding-bottom: (@rem-component-m * 2 - @panel-grid-m);
	}

	@media (@breakpoint-l){
		padding-bottom: (@rem-component-m * 2 - @panel-grid-l);
	}

	&--informational {
		background-color: @blue-grey-lighter;
	}

	&--promotional {
		background-color: @yellow;
	}

	&__header {
		margin-top: 0 !important; // todo remove important, when old grid is gone
		margin-bottom: 0 !important; // todo remove important, when old grid is gone
		padding-bottom: @rem-xs;
		text-align: center;

		h1, h2, h3, h4, h5, h6, p {
			max-width: 100%;
		}

		&--padded {
			padding-bottom: @rem;
		}
	}

	&__title {
		font-family: @anwb-font-family;
		font-style: italic;
		font-weight: normal;
		font-size: @heading-l;
		margin-left: @rem-s;
		margin-bottom: @line;

		@media (@breakpoint-m) {
			margin-bottom: 0;
		}
	}

	&__anchor {
		margin: 0 !important; // todo remove important, when old grid is gone
		height: 54px;

		a {
			display: block;
			margin: 0 auto;
			padding: 0;
			width: @blockanchor-width;
			height: @blockanchor-height;
			border-radius: 0;
			background: transparent url('images/tab-lib.svg') no-repeat top center;
			background-size: @blockanchor-width auto;
			text-align: center;
			text-decoration: none;
			outline: none;

			&:before {
				.icon;
				content: @var-icon-chevron-down;
				position: relative;
				top: 0;
				transition: all 0.1s ease-in-out;
			}

			&:hover:before {
				color: @blue-dark;
				top: @line;
			}

			&:focus:before {
				color: @blue-dark;
			}

			&:active:before {
				color: @blue;
			}
		}
	}

	&__icon {
		display: inline-block;

		.icon-xxxl;

		@media (@breakpoint-m) {
			display: block;
			margin: 0 auto 1rem;

			.icon-jumbo-xxl;
		}
	}

	&--navigation {
		background-color: @blue-dark;
		color: @white;

		h2, h3, h4, h5, h6, a {
			color: @white;
		}
	}
}
