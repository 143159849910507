
div.notification {

	width: 100%;
	height: auto;
	min-height: 2.934rem;

	margin-top: 0;
	margin-bottom: @heading-l;
	padding: 0.5rem 1.0rem 0.7rem 2.65rem;

	color: @blue-grey-darker;

	border: @line solid @blue-grey;
	border-radius: @border-radius;

	position: relative;

	display: none;

	@media ( @breakpoint-m) {
		margin-top: 1.5rem;
	}

	& > :first-child {

		margin-top: .3rem;
		margin-bottom: 0;
		.anwb-thesans-semi-bold-italic();

		&::before {
			.icon;

			top: 0;
			left: 0;

			margin-top: 0.7rem;
			margin-left: .4rem;

			font-size: 1.5rem;

			position: absolute;
			display: inline-block;
		}
	}

	ol, ul, p {
		margin: @rem-xs 0;

	}
	&.hidden {
		.hide();
	}
	&[data-state='success'] {
		border-color: @green;
		background-color: hsla(105, 50%, 96.9%, 1.0);

		display: block;

		& > :first-child {

			color: @green;

			&::before {
				color: @green;
				/* ZzZ To Do: ADD Right icon */
				content: @var-icon-success;
			}
		}
	}

	&[data-state='info'] {
		border-color: @blue-grey-light;
		background-color: hsla(193, 52.9%, 96.7%, 1.0);

		display: block;

		& > :first-child {

			color: @blue;

			&::before {
				color: @blue;
				/* ZzZ To Do: ADD Right icon */
				content: @var-icon-information;
			}
		}
	}

	&[data-state='warning'] {
		border-color: @orange;
		background-color: hsla(34, 88.9%, 96.5%, 1.0);

		display: block;

		& > :first-child {

			color: @orange;

			&::before {
				color: @orange;
				/* ZzZ To Do: ADD Right icon */
				content: @var-icon-warning;
			}
		}
	}


	&[data-state='error'] {
		border-color: @red;
		background-color: hsla(15%, 85.7%, 97.3%, 1.0);

		display: block;

		& > :first-child {

			color: @red;

			&::before {
				color: @red;
				/* ZzZ To Do: ADD Right icon */
				content: @var-icon-error;
			}
		}
	}
}
