// TODO: refactor variables
/* === Gallery === */
/*
- Gallery
-- footer

- Slides
-- header
-- body
-- footer

- Toggle
-- Off
-- On

- Gallery nav

- State

- Gallery nav overlay
-- Next
-- Previous

- Mixins
- Animation


// --------------------------

/* === Gallery === */
// --------------------------
@tab-size: 130px;

.gallery {
	position: relative;
	overflow: hidden;
	margin-bottom: @rem-s;
}

// Footer (contains slide headers) 
// -------------------------
.gallery{
	h3 {
		display: none;
		margin-top: @rem-component-s;
		font-style: italic;
		margin-bottom: 0;
	}

	p {
		display: none;
		margin: 0;
		font-size: @font-size-xs;
		color: @blue;
	}

	.slide-btn:checked + .gallery-slide + h3 {
		display: block;
	}

	.slide-btn:checked + .gallery-slide + h3 + p {
		display: block;
	}

	figure {
		margin:0;
	}
}

/* === Gallery Slide === */
// -------------------------
.gallery-slide {
	animation: fader .2s 1 ease-in-out; /* IE 10+, Fx 29+ */
	position: relative;
}

// Header
// -------------------------
.slide-header {
}

// Body
// -------------------------
.slide-body {
	width: 100%;
	
	> a {
		color: inherit;
		text-decoration: none;

		& > * {
			color: inherit;
		}
	}

	img {
		margin: 0;
		
		&:not([srcset]):not(.half-inset) {
			width: 100%;
		}
	}
}

// Footer
// -------------------------
.slide-footer {
}


/* === Toggle === */
// -------------------------
// OFF
.slide-btn {
	display: none;

	+ section {
		display: none;
	}
}

// Toggle ON
.slide-btn:checked {
	+ section {
		display: flex;
	}
}

/* === Gallery Nav === */
// -------------------------
.gallery-nav-holder{
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	position: absolute;
	bottom: -1px; //TODO fix to bottom: 0;	
	overflow: hidden;
}

.gallery-nav {
	display: flex;
	margin-top: @rem-xs;
	padding: 10px;
	background: #fff; 
	position: relative;
	border-radius: 8px 8px 0 0;

	&:after, &:before {
  		position: absolute;
  		bottom: -1px;
  		width: 10px;
  		height: 10px;
  		content: " ";
	}

	&:before {
  		left: -9px;
  		border: 1px solid #fff;
  		border-bottom-right-radius: 12px;
  		border-width: 0 1px 1px 0;
  		box-shadow: 2px 2px 0 #fff;
	} 

	&:after {
  		right: -9px;
  		border: 1px solid #fff;
  		border-bottom-left-radius: 12px;
  		border-width: 0 0 1px 1px;
  		box-shadow: -2px 2px 0 #fff;
	}

	> label {
		margin: 0;
		margin-right: @rem-s;
		padding: 0;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: @blue-grey;
		content: ' ';
		cursor: pointer;

		&:last-child {
			margin-right: 0;
		}
		&:hover {
			background: @blue;
		}
	}
}

/* === Gallery Nav State === */
// -------------------------
// Generate Label colors for selected State
.gallery {
	.generate-input-checked-label(20);
}

/* === Nav list overlay === */
// --------------------------
.gallery-nav-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;

	ul {
		._list-unstyled;
		position: absolute;
		max-width: inherit;
		height: 40px;
		width: 100%;

		top:  ~'calc(50% - 60px)';

		display: flex;
		flex-direction: row;
		pointer-events: none;
		align-items: stretch;
		justify-content: flex-start;
		align-content: flex-start;

		@media (@breakpoint-m) {
			//height: calc(50% - 1px);
		}
	}

	ul > li {

		align-self: flex-end;
		position: relative;
		width: @tab-size;
		margin: 0;
		padding: 0;
		> label > i {
			&:extend(.icon);
		}
	}

	ul > li:first-child {
		label {
			right: auto;
			left: 15px;

			@media (@breakpoint-m) {
				left: 25px;

				> i {
					left: 0;
				}	
			}
		}
	}

	ul > li + li {
		margin-left: auto;
		margin-right: 0;
		
		label {
			right: 15px;

			@media (@breakpoint-m) {
				right: 25px;
			}	

			left: auto;

			i {
				right: 5px;
				left: auto;
				text-align: center;

				@media (@breakpoint-m) {
					right: 0;
				}	

				&:before{
					margin-left: 4px;
					content: @var-icon-chevron-right;
				}
			}
		}
	}
	
	// Nav list Next / Previous

	// Next
	// --------------------------
	ul > li > label {
		position: absolute;
		margin: 0;
		padding: 0;
		width: 44px;
		height: 44px;
		left: 15px;
		display: block;

		background: transparent none;
		pointer-events: auto;
		cursor: pointer;
		align-items: flex-start;

		> i {
			width: 30px;
			height: 30px;
			color: #0096DC;
			position: absolute;
			margin: 0;
			padding: 0;
			top: 5px;
			left: 5px;
			
			text-align: center;
			line-height: 26px;
			font-size: @font-size-xs;
			font-weight: bold;
			border-radius: 180%;
			background: #fff;
			border: 2px solid @blue-grey-lighter;

			&:before {
				content: @var-icon-chevron-left;
				margin-left: -4px;
			}

			@media (@breakpoint-m) {
				width: 44px;
				height: 44px;
				line-height: 40px;
				font-size: @font-size-xs;
				top: 0;
				right: 0;
			}	
		}
	}
}

// Hide the 'previous' arrow on the first slide
.gallery-slide:first-of-type > .gallery-nav-overlay ul li:first-child{
	display: none;
}

// Hide the 'next' arrow on the last slide
.gallery-slide:last-of-type > .gallery-nav-overlay ul li:last-child{
	display: none;
}

/* === Mixins === */
// -------------------------

.generate-input-checked-label(@n) when (@n > 0) {
	:nth-of-type(@{n}):checked + * nav :nth-child(@{n}){ background: @orange; }
.generate-input-checked-label((@n - 1));
}

/* === Animation === */
// -------------------------
@keyframes fader {
	0% {
		opacity: .7;
	}
	100% {
		opacity: 1;
	}
}