// supporting style for cycling children of an element in
// a visually appealing way. used for tweets.
.cycle-children {
	overflow: hidden;
	position: relative;
	> * {
		position: absolute;
		top: -50px;
		transition: top .6s linear, opacity .6s linear;
		opacity: 0;
		pointer-events: none;
	}
	> :last-child {
		top: 50px;
	}
	> .active {
		top: 0;
		opacity: 1;
		pointer-events: inherit;
		+ * {
			top: 50px;
		}
	}
}

// uses position: sticky where possible, emulates this behavior on browsers without native support
.sticky {
	position: sticky;
	top: 0;
	width: 100%; // make emulated position: sticky look like native position: sticky
}