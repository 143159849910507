// Temporary css goes here.
// It should be removed as soon as possible.
// Please always add a description to a hack.


// styling fixes for Hotjar polls, for: https://projprod.anwb.local/jira/browse/ONTLW-6682
// because site css overrules some hotjar css
._hj-widget-container [class*="openStateToggle"]::before,
._hj-widget-containerinput [type="radio"]+span::before,
._hj-widget-container [class*="closeEndedOptionIcon"][class*="radio"]::before {
    display: none;
}
._hj-widget-container [class*="closeEndedOptionIcon"] {
    padding: 0;
}