/**
 * The article selector is used within de CMS for the main body text.
 * These have specific alignments and to take into account.
 */

article {

	> * {
		max-width: @max-text-width;
	}
	> :last-child {
		margin-bottom: 0;
	}
	ul {
		> li {
			margin-top: @offset-s;
			margin-bottom: @offset-s;
		}
	}

	h1 + h4 {
		margin-top: @margin-text-l;
		margin-bottom: @margin-text-l;
		& > a:visited,
		& > a:link {
			color: @blue-dark;
			text-decoration: inherit;
		}
		& > a:hover {
			color: @blue;
		}
		& > a:active {
			color: @orange;
		}
		& + p {
			margin-top: 0;
		}
	}

	@media (@breakpoint-m) {

		// Very nasty hack to reduce the whitespace between the H1 and the panels
		// TODO: Remove after template fix in Hippo

		& > h1:last-child {
			margin-bottom: -@grid-offset-s;
			padding-bottom: 0;
		}
	}
}

