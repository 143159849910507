.panel-product {
	&:extend(._panel all);
	flex-direction: row;

	.panel-body {
		background-color: @white;
		flex:1;
		@media (@breakpoint-m) {
			display: flex;
			flex-direction: column;
			& > [class^=sticker-] {
				position: absolute;
				margin: 0;
				top: ( -@grid-unit-m + @grid-unit-m );
				right: ( -@grid-unit-m + @grid-unit-m );
			}
		}
	}

	footer {
		display: flex;
		flex-direction: column;

		> * {
			align-self: center;
			text-align: center;

			+ * {
				padding-top: @margin-text-l;
				display: block;
			}
		}

		.link-internal > span {
			padding-right: 3px;
			transition: padding .2s;

			&:hover {
				padding-right: 0;
				transition: padding .2s;
			}
		}
	}
}

