/* Opmerking mbt A/B Testing:
Voor elk actief Optimizely experiment op de pagina wordt een attribuut op het html element gezet.
De waarde is de actieve variatie. Variatie waarde "0" is normaal gesproken het origineel.

Het menu bevat styling voor de B-test (waarde "1") en default (soms met een :not() selector)
*/

// Dit is het unieke experiment ID:
@test-b: e('[experiment-8299577532="error"]');

html[optimizely-loading] {
	.navigation-search, .navigation-user {
		display: none;
	}
}


@media (@breakpoint-m--menu) {

	@breakpoint-m--menu--search: ~"min-width: 910px";

	.navigation-user {
		position: absolute;
		top: -3.55rem;
		@media ( @breakpoint-l ) {
			top: -3.5rem;
			right: -@line;
		}

		right: @line * 2;
		height:0;
		& ul {
			left: auto;
			right: ~'calc(@{grid-unit-m} - @{font-size-xs})';
			border-top-left-radius: @border-radius-s;
			& > li {
				&:hover a {
					color: @blue !important; // TODO: refactor. Don't use important!
				}
			}
		}
		& > li {

			position: relative;
			display: inline-block;
			// A-B testing main-menu; B test
			html@{test-b} .navigation-main & {
				padding: @margin-text-s;
				padding-right: @grid-unit-s;
				@media (@breakpoint-m) {
					background-color: @yellow;
					background: linear-gradient(to right, rgba(255,204,0,0) 0%,rgba(255,204,0,1) 15%,rgba(255,204,0,1) 100%);
					z-index: @z-index-logo;
				}
				@media (@breakpoint-l) {
					background: transparent none;
				}
			}
			padding-right: 0.4rem;
			pointer-events: initial;
			& > label{
				font-size: @font-size;
				font-family: @anwb-font-family;
				padding: @margin-text-s;
				// A-B testing main-menu; B test
				html@{test-b} .navigation-main & {
					padding-top: .8rem;
				}
				padding-right:0;
				// A-B testing main-menu; B test
				html@{test-b} .navigation-main &::before {
					box-shadow: none !important;
				}
				// A-B testing main-menu; B test
				html@{test-b} .navigation-main & {
					@media ( @breakpoint-l) {
						font-size: @font-size-l;
					}
				}
				& > a {
					padding-top: @grid-unit-m;
					padding-right: @margin-text-s;
					padding-bottom: 0;
					padding-left: @margin-text-s;
					//display: block;
					text-decoration: none;
					color: @blue-dark;

					// A-B testing main-menu; A test (default)
					html:not(@{test-b}) .navigation-main &:after {
						.icon;
						content: ' ';
						.icon-lg;
						margin-left: @margin-text-s;
						font-size: @font-size-s;
						color: @blue;
						line-height: 1.95;
					}
				}

			}
			&:hover > label{
				// A-B testing main-menu; A test (default)
				html:not(@{test-b}) .navigation-main & > a::after {
					content: ' ';
					color: @blue;
				}
			}

		}

		.anwb_hover();

		& [aria-haspopup]:not(input) > label {
			&::before, &::after {
				width: 100% !important;
			}
			// A-B testing main-menu; B test
			html@{test-b} .navigation-main &::before,
			html@{test-b} .navigation-main &::after {
				width: 6.7rem;
			}
			@media ( @breakpoint-l) {
				// A-B testing main-menu; B test
				html@{test-b} .navigation-main &::before,
				html@{test-b} .navigation-main &::after {
					width: 7.7rem;
				}
			}

		}

		// A-B testing main-menu; B test
		html@{test-b} .navigation-main & {
			top: -0.55rem;
			@media ( @breakpoint-l) {
				top: -0.25rem;
			}
		}

	}
}
@media (@breakpoint-l--menu) {
	.navigation-user {
		& > li {
			padding-right: @grid-unit-s;
		}
		& ul {
			right: ~'calc(@{grid-unit-m} - 6px)';
		}
		& > li > label {
			padding-right: @margin-text-s;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 141px;
			display: inline-block;
			padding-top: 0;
		}
	}
}
