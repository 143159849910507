.panel-contact {
	&:extend(._panel all);
	.panel-body {
		&:extend(._panel-attention all);

		color: @blue-dark;

		address {
			> span {
				display: block;
				font-style: normal;
				&[itemprop="addressLocality"], &[itemprop="postalCode"] {
					display: inline-block;
				}
			}
			& ~ .list-linklist {
				margin-top: @margin-text-l;
			}
		}

		// For split-lines between blocks
		> .panel-main > div {
			&:after {
				content: '';
				display: block;
				margin: @grid-unit-s -@grid-offset-s;
				height: @line;
				background: @blue-grey-light;

				@media (@breakpoint-m) {
					margin: @grid-unit-m -@grid-unit-m;
				}

				@media (@breakpoint-l) {
					margin: @grid-unit-m -@rem-l;
				}
			}
			@media (@breakpoint-m) {
				&:first-of-type {
					&:after {
						margin-top: @grid-unit-m;
					}
				}
			}

			&:last-of-type{
				margin:0;
				&:after {
					display: none;
				}

			}
		}

		.ANWB-panel__content--border { // for forwards compability...
			position: relative;

			&:not(:last-child)::after {
				position: absolute;
				bottom: -@line;
				left: -@grid-unit-s;
				right: -@grid-unit-s;
				content: '';
				display: block;
				height: @line;
				background: @blue-grey-light;

				@media (@breakpoint-m) {
					left: -@grid-unit-m;
					right: -@grid-unit-m;
				}

				@media (@breakpoint-l) {
					left: -@rem-l;
					right: -@rem-l;
				}
			}
		}

		.panel-contact-title {
			&:extend(._panel-title-h3 all);
		}
		// TODO: poor classname. Refactor!
		.list-linklist {
			& > li {
				&:last-child {
					margin-bottom: -@margin-text-s;
				}

				&:first-child {
					margin-top: -@margin-text-s;
				}

				& > * {
					display: inline-block;
					width: auto;
					padding: @margin-text-s 0;
				}
			}
		}
		.list-linklist:empty {
			display: none;
		}

		span {
			font-size: @font-size;
		}

		span.contactBlock_phonenumber,
		span.contactBlock_phonenumberPrefix,
		span.contactBlock_phonenumberSuffix {
			font-size: @font-size-l;
			font-family: @anwb-font-family;
			line-height: @line-height-s;
		}

		*:not('#etva') a {
			display: block;
			font-family: @anwb-font-family;
			&[href^="tel:"], &[href^="fax:"] {
				display: inline;
				color: @blue-dark;
				text-decoration: none;
			}
		}

		small {
			display: block;
		}

		*:not('#etva') strong {
			font-size: @font-size-m;
			font-family: @anwb-font-family;
		}

		table {
			width: 100%;
			td {
				padding: @rem-xs/2 0;
			}
		}
	}
}

.grid-content article .panel-contact,
.grid-stacked .panel-contact,
.grid-overview .panel-contact {
	> .panel-body {
		background-color: @white;
		&:extend(._panel-border all);
		td {
			border: 0;
			}
	}
}