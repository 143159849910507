._standard-table,
.basic-table,
article table {
	position: relative;
	margin: 0;
	border: 0;
	border-spacing: 0;
	border-collapse: separate;
	
	caption {
		.anwb-thesans-bold();
		margin-top: 0;
		margin-bottom: @margin-text-m;
		margin-left: @margin-text-m;
		font-size: @heading-m;
		text-align: left;
		font-weight: bold;
		max-width: @max-text-width;
		text-rendering: optimizeLegibility;
		color: @blue-dark;
		line-height: @line-height-s;

		@media (@breakpoint-m) {
			margin-left: 0;
		}

		+ tbody {
			tr:first-of-type {
				td {
					border-top: @line solid @blue-grey-light;

					&:first-of-type {
						@media (@breakpoint-m) {
							border-top-left-radius: @border-radius;
						}
					}

					&:last-of-type {
						@media (@breakpoint-m) {
							border-top-right-radius: @border-radius;
						}
					}
				}
			}
		}
	}

	thead {
		background-color: @blue-grey-light;
		border: 0;

		tr:first-of-type {
			th {
				border-top: @line solid @blue-grey-light;

				&:first-of-type {
					@media (@breakpoint-m) {
						border-top-left-radius: @border-radius;
					}
				}

				&:last-of-type {
					@media (@breakpoint-m) {
						border-top-right-radius: @border-radius;
					}
				}
			}
		}
	}

	> thead,
	> tbody {
		> tr {
			> th, td {
				padding: @rem;
				border: @line/2 solid;
				line-height: @line-height-s;
				text-align: center;

				> p {
					margin: 0;
				}

				&:first-of-type {
					text-align: left;
					border-left: 0;
					@media (@breakpoint-m) {
						border-left: @line solid @blue-grey-light;
					}
				}

				&:last-of-type {
					border-right: 0;
					@media (@breakpoint-m) {
						border-right: @line solid @blue-grey-light;
					}
				}
			}

			> th {
				border-right-color: @blue-grey;
				border-bottom-color: @blue-grey-light;
				border-left-color: @blue-grey;
				font-size: @font-size-m;
				font-family: @anwb-font-family;
			}

			> td {
				border-color: @blue-grey-light;
			}
		}
	}

	> tbody {
		tr:first-of-type {
			td {
				border-top: @line solid @blue-grey-light;

				&:first-of-type {
					@media (@breakpoint-m) {
						border-top-left-radius: @border-radius;
					}
				}

				&:last-of-type {
					@media (@breakpoint-m) {
						border-top-right-radius: @border-radius;
					}
				}
			}
		}

		tr th {
			border-color: @blue-grey-light;
		}

		tr:last-of-type {
			td, th {
				border-bottom: @line solid @blue-grey-light;
				@media (@breakpoint-m) {
					&:first-of-type {
						border-bottom-left-radius: @border-radius;
					}
					&:last-of-type {
						border-bottom-right-radius: @border-radius;
					}
				}
			}
		}
	}

	> thead + tbody {
		> tr:first-of-type {
			td {
				&:first-of-type {
					@media (@breakpoint-m) {
						border-top-left-radius: 0;
					}
				}

				&:last-of-type {
					@media (@breakpoint-m) {
						border-top-right-radius: 0;
					}
				}
			}	
		}
	}		 
}









.table-responsive {
	position: static;
	display: block;
	width: auto;
	max-width: 100vw;
	margin: 0 -@offset-xs * 2;
	overflow-x: auto;

	@media (@breakpoint-m) {
		margin: inherit;
		width: 100%;
	}
}

