//@utilities-offset: 49px;

@test-a: ~'html[experiment-1234567890="1"]';
@test-b: ~'html[experiment-1234567890="2"]';

@breakpoint-g--menu: ~"min-width: 30rem"; // todo: move to core-less-imports

.navigation-header-legacy() {
	display: block;
	flex-wrap: wrap;
	position: relative;
	text-align: right;
	padding-top: @rem-N;

	@media (@breakpoint-l) {
		padding-top: @rem * 0.55;
	}
	.@{anwb-prefix}navigation {
		&__search {
			position: absolute;
			z-index: @z-index-menu;
			right: 0;
			margin-top: -@rem-N;
		}
		&__logo {
			position: absolute;
			float: left;
			z-index: @z-index-logo;
			left: 0;
			a:last-child:before {
				top: @rem-N * 1.1;
				left: 0;
			}
		}
		&__search {
			z-index: 0;
		}
		&__user {
			display: inline-block;
			position: relative;
			bottom: -@offset-s;
			//& > ul {
			//	margin: 0;
			//}
		}
		&__content {
			text-align: left;
		}
		&__utilities {
			display: inline-block;
			position: relative;
			bottom: -4px;
		}
	}


}

.navigation-header-template() {
	display: grid;
	grid-template-columns: 120px 1fr auto;
	@media (@breakpoint-l) {
		grid-template-rows: 64px 64px;
	}
	grid-template-rows: 50px 56px;
}

.navigation-header-grid(@pos) {
	align-self: end;
	& when (@pos = 1) {
		grid-column: 1 / span 1;
		grid-row: 1;
	}
	& when (@pos = 2) {
		grid-column: 2 / span 1;
		grid-row: 1;
	}
	& when (@pos = 3) {
		grid-column: 3 / span 1;
		grid-row: 1;
	}
	& when (@pos = 4) {
		grid-column: 1 / span 999;
		grid-row: 2;
	}
	//pointer-events: none;
	a {
		pointer-events: auto;
	}
}

.grid-layout-logo() {
	.navigation-header-grid(1);
}

.grid-layout-util(@variant: 'legacy') {
	& when (@variant = 'legacy') {
		.navigation-header-grid(2);
	}
	& when (@variant = 'A') {
		.navigation-header-grid(2);
	}
	& when (@variant = 'B') {
		.navigation-header-grid(3);
		z-index: @z-index-menu;
		//align-self: center;
	}
}

.grid-layout-srch(@variant: 'legacy') {
	& when (@variant = 'legacy') {
		.navigation-header-grid(4);
		align-self: center;
		justify-self: end;
		-ms-grid-column-align: end;
	}
	& when (@variant = 'A') {
		.navigation-header-grid(3);
		z-index: @z-index-menu;
		align-self: start;
	}
	& when (@variant = 'B') {
		.navigation-header-grid(2);
		align-self: start;
		z-index: @z-index-menu;
	}
}

.grid-layout-user(@variant: 'legacy') {
	& when (@variant = 'legacy') {
		.navigation-header-grid(3);
		z-index: @z-index-menu;
		//align-self: center;
	}
	& when (@variant = 'A') {
		.navigation-header-grid(4);
		align-self: center;
		justify-self: end;
		-ms-grid-column-align: end;
	}
	& when (@variant = 'B') {
		.navigation-header-grid(4);
		align-self: center;
		justify-self: end;
		-ms-grid-column-align: end;
	}
}

.grid-layout-cont() {
	.navigation-header-grid(4);
	align-self: center;
}

.@{anwb-prefix}navigation {
	z-index: @z-index-menu - 3;
	position: relative;

	p, ol, ul {
		max-width: none;
	}

	@media (@breakpoint-m--menu) {
		white-space: nowrap;
		margin: 0 auto -@rem * 1.8;
		width: 100%;
		max-width: @viewport-l-max;

		.navigation-header-template();
		position: relative;

		@supports not (display: grid) {
			.navigation-header-legacy();
		}
	}

	@media not all and (@breakpoint-m--menu) {
		overflow: hidden;

		&.active {
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			height: 100%;
			z-index: @z-index-menu - 2;
			background: @white;
			overflow: hidden;
			& ~ * {
				display: none;
			}
		}
	}
}


.@{anwb-prefix}navigation__logo {
	.grid-layout-logo();
	//display: inline-block;
	pointer-events: visible;
	margin-left: @panel-grid-m;
	a {
		width: 90px;
		height: 49px;
		display: block;
		@media (@breakpoint-m--menu) {
			width: 160px;
		}
		&:before {
			position: absolute;
			top: ~'calc( @{grid-unit-s} - @{line} )';
			z-index: @z-index-popup;
			@media not all and (@breakpoint-l) {
				left: @rem * 1.5;
			}
			width: 72px;
			content: url(@anwb_logo);

			@media (@breakpoint-m--menu) {
				top: ~'calc( @{grid-unit-s})';
				width: 90px;
				height: 60px;
				margin-left: @margin-text-s;
				pointer-events: none;
			}

			@media (@breakpoint-l) {
				top: ~'calc( @{grid-unit-s} + @{line} * 3 )';
				width: 116px;
				margin-left: @grid-unit-l * 0.9;
			}

		}
	}
}

.@{anwb-prefix}navigation__search {
	.__clean-dropdown() {
		// todo: when test is done, move it to menu-dropdown
		.@{anwb-prefix}menu-dropdown {
			display: block;

			&__label,
			&__shadow {
				display: none;
			}
			&__menu {
				background-color: transparent;
				position: static;
				opacity: 1;
				top: 0;
				min-width: 220px;
				padding: 0;
				margin: 0;
			}
			&:hover .@{anwb-prefix}menu-dropdown__menu {
				box-shadow: none;
				transition: none;
			}
		}
	}
	@media all and (@breakpoint-g--menu) and (@breakpoint-s--menu) {
		@{test-a} &,
		@{test-b} & {
			.__clean-dropdown();
			.@{anwb-prefix}menu {
				width: auto;
				top: 3px;
				left: 120px;
				right: ~"calc(2 * 44px + @{panel-grid-l})";
				z-index: 1;
			}
			.@{anwb-prefix}search {
				padding-left: @rem-s;
				padding-right: @rem-s;

				&__form {
					padding: 0;
					max-width: 303px;
					margin-left: auto;
				}
			}
		}
	}
	@media (@breakpoint-m--menu) {
		.grid-layout-srch();
		margin-right: @panel-grid-m;
		padding: 0 @margin-text-m 0 @margin-text-m;
		//@media ( @breakpoint-l ) {
		//	padding: 0 @margin-text-m;
		//}
		@{test-a} & {
			.grid-layout-srch('A');
			padding: 0 @margin-text-m 0 0;
			//float: none;
			//padding-bottom: @margin-text-m - @offset-s;
			//margin-right: @panel-grid-m;
			//padding: 0 @margin-text-m 0 0;
		}
		@{test-b} & {
			.grid-layout-srch('B');
			margin-right: 0;
			//margin: 0;
			//padding: 0;
			//display: block;
			//pointer-events: inherit;
			//text-align: right;
		}
		@{test-a} &,
		@{test-b} & {
			.__clean-dropdown();
		}
	}
}

.@{anwb-prefix}navigation__user {
	@media (@breakpoint-s--menu) {
		@{test-a} &,
		@{test-b} & {
			//.@{anwb-prefix}menu--user {
			//	.@{anwb-prefix}menu__label {
			//		right: ~"calc(44px + @{panel-grid-l})";
			//	}
			//}
			//.@{anwb-prefix}menu__submenu {
			//	top: 116px; //TODO show search for mijn-anwb?
			//}
		}
	}

	@media (@breakpoint-m--menu) {
		.grid-layout-user();
		float: none;
		padding-bottom: @margin-text-m - @offset-s;
		margin-right: @panel-grid-m;
		padding: 0 @margin-text-m 0 0;
		//@media ( @breakpoint-l ) {
		//	padding: 0 @margin-text-m;
		//}
		@{test-a} &,
		@{test-b} & {
			.grid-layout-user('A');
			margin-right: @panel-grid-m;
			padding: 0 @margin-text-m;

			// todo: when test is done, remove --icon modifier from html
			.@{anwb-prefix}menu-dropdown--icon .@{anwb-prefix}menu-dropdown__link:after {
				display: none;
			}
		}
	}
}

.@{anwb-prefix}navigation__utilities {
	display: none;
	@media (@breakpoint-m--menu) {
		.grid-layout-util();
		margin: 0;
		padding: 0;
		display: block;
		pointer-events: inherit;
		text-align: right;
		@{test-a} & {
			.grid-layout-util('A');
		}
		@{test-b} & {
			.grid-layout-util('B');
			margin-right: @panel-grid-m;
			padding: 0 @margin-text-m 0 0;
		}
		& > a {
			display: inline-block;
			padding: @margin-text-m;
			margin: 0;
			color: @blue-dark;
			text-decoration: none;
			font-size: @font-size-s;
			&:hover {
				color: @blue;
			}
			&:active {
				color: @orange;
			}
			@media (@breakpoint-m) {
				font-size: 0.8em;
				line-height: 1.8;
			}
		}

	}
}

.@{anwb-prefix}navigation__content {
	//@media (@breakpoint-s--menu) {
	//	@{test-a} &,
	//	@{test-b} & {
	//		.@{anwb-prefix}menu__submenu {
	//			top: 116px;
	//		}
	//		.@{anwb-prefix}menu__submenu .@{anwb-prefix}menu__submenu {
	//			top: 97px;
	//		}
	//	}
	//}

	@media (@breakpoint-m--menu) {
		.grid-layout-cont();
		background: @yellow;
		border-radius: @border-radius;

		@media (@breakpoint-m) {
			margin: 0 @panel-grid-m;
			//& > * {
			//	pointer-events: inherit;
			//}
		}
	}
}

/* === Accessibility === */
//=================

// Skip-to-content link
body > header > .utilities > a {
	position: absolute;
	top: -1000px;
	left: -1000px;
	overflow: hidden;
	padding: @grid-unit-s;
	width: 0;
	height: 0;
	border-radius: 0 0 @border-radius-s 0;
	background: @blue-grey;
	text-align: left;
	text-decoration: none;
	font-weight: bold;
	pointer-events: auto;
	// States
	&:active,
	&:focus,
	&:hover {
		top: 0;
		left: 0;
		z-index: @z-index-popup;
		overflow: visible;
		width: auto;
		height: auto;
		outline: 0;

	}
}