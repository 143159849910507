.@{anwb-prefix}card {
	@block: ~"@{anwb-prefix}card";

	//** base styling */
	position: relative;

	// base default padding
	.spacing-panel();

	// setting the elements to display as block in the case inline elements are used
	&,
	&__main,
	&__header,
	&__wrapper,
	&__content,
	&__footer,
	&__title {
		display: block;
	}

	// main default rounded corners
	&__main {
		border-radius: @border-radius;
		.padding-panel();
		background-color: @blue-grey-lighter;
		position: relative;
	}

	&__wrapper {
		border-radius: @border-radius;
		background-color: @white;
		border: 2px solid @blue-grey-lighter;
	}

	&__title {
		margin: 0;
		margin-bottom: @rem-xs;
		color: @blue-dark;
		.anwb-thesans-bold-italic();
		font-size: @heading-panel-m;
	}

	&__indicator {
		display: block;
		position: relative;

		@media (@breakpoint-s) {
			cursor: pointer;
		}

		@media (@breakpoint-m) {
			pointer-events: none;
		}

		&::after {
			&:extend(.icon);
			display: inline-block;
			color: @blue;
			font-size: 16px;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -8px;

			content: @var-icon-chevron-up;

			@media (@breakpoint-m) {
				display: none;
			}
		}
	}

	&__extras { // in this section the application can put thing like the 'edit' button
		@media (@breakpoint-m) {
			position: absolute;
			right: @grid-unit-m;
			top: @grid-unit-m;
		}

		@media (@breakpoint-l) {
			right: @grid-unit-l;
			top: @grid-unit-l;
		}
	}

	&__content,
	&__footer {
		.padding-panel();

		p {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__footer {
		border-top: 2px solid @blue-grey-lighter;
	}

	// modifiers
	&--highlighted &__wrapper {
		border-color: @blue-grey-darker;
	}

	// Checkbox collapsable states
	[data-collapse]:not(:checked) {
		~ .@{block}__header {
			.@{block}__title {
				@media (@breakpoint-s) {
					margin-bottom: 0;
				}

				.@{block}__indicator {
					&:after {
						content: @var-icon-chevron-down;
					}
				}
			}
		}

		~ .@{block}__wrapper,
		~ .@{block}__extras {
			@media (@breakpoint-s) {
				display: none;
			}
		}
	}

	// Javascript qualifier states
	&:not([data-mode='read']) {
		[data-on-mode='read'] {
			display: none;
		}
	}

	&:not([data-mode='edit']) {
		[data-on-mode='edit'] {
			display: none;
		}
	}
}
