/*
A container is not a panel but can contain either a panel or a grid.
Currently being used by compare panel and horizontal tabbox.
Should probably also be used by panelWidget?

Needs further refactoring.
*/



// container
.container {
	&:extend(._panel);
	@media (@breakpoint-s) {
		main > section > div[class|=grid] & {
			margin-right: -@panel-grid-s;
			margin-left: -@panel-grid-s;
		}
		main > section > div[class|=grid] article & {
			margin-right: -@panel-grid-s * 2;
			margin-left: -@panel-grid-s * 2;
		}
	}


	background: @blue-grey-light;
	> .container-body > h2 {
		margin: auto;
		text-align: center;
	}
	& > .container-body > h2 {
		padding: @margin-text-m;
	}
	@media (@breakpoint-m) {
		border-radius: @border-radius;
	}
	> .container-body {
		& > :last-child:after { display: none; }
	}
	main > section > [class|='grid'] > & {
		margin-right: @panel-grid-s;
		margin-left: @panel-grid-s;
		@media (@breakpoint-m) {
			margin-right: @panel-grid-m;
			margin-left: @panel-grid-m;
		}
		@media (@breakpoint-l) {
			margin-right: @panel-grid-l;
			margin-left: @panel-grid-l;
		}

	}

}
.container_attention {
	&:extend(.container all);
	background-color: @blue-grey-lighter;
}