.flex(){
	display: block;
	@media (@breakpoint-m) {
		display: flex;
		flex-flow: row;
		margin: 0 auto;
		max-width: @viewport-l-max;
		& > * {
			flex: 1 0 auto;
		}
	}
}

.css-columns(@columns:1) {
	display: block;
	column-count: 1;
	column-gap: 0;
	column-fill: balance;

	& > * {
		break-inside: avoid;
		display: block;
		margin: 0;
		width: 100%;
		overflow: hidden;

		@media (@breakpoint-s) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	@media (@breakpoint-m) {
		column-count: @columns;
		margin: 0 auto @interline;
		max-width: @viewport-l-max;
	}
}


/**
 * asymmetric
 * This is for an asymmetric two column layout. Usually used for basic article layout
 * @type {string} shortname of a type of layout required
 * @columnbase {number} number of columns the two columns are based on (no unit size)
 */
.grid( @type; @columnbase:3; ) {
	& when ( @type = 'asymmetric' ) {
		.flex();
		@media (@breakpoint-m) {
			justify-content: flex-start;
			& > * {
				flex: 1 1 percentage(( ( @columnbase - 1 ) / @columnbase ));
				max-width: percentage(( ( @columnbase - 1 ) / @columnbase ));
			}
			& > *:first-child:not([data-sidebar='true']):not(aside) + *,
			& > [data-sidebar='true'],
			& > aside {
				flex-basis: percentage(( 1 / @columnbase ));
				max-width: percentage(( 1 / @columnbase ));
			}
		}
		// Never allow more than two children.
		& > :nth-child(2) ~ * {
			display: none;
		}
	}
}

/**
 * stacked
 * This is a three column layout that uses css columns
 * @type {string} shortname of a type of layout required
 * @columns {number} number of columns (no unit size)
 */
.grid(@type; @columns:3) when ( @type = 'stacked' ) {
	.css-columns(1);
	@media (@breakpoint-m) {
		.css-columns(@columns);
	}
}



/**
 * wrapped
 * This is a wrapping layout that uses css coulumns
 * @type {string} shortname of a type of layout required
 * @size {number} 1/n sets the basis width as a percentage with a default of 3
 */
.grid( @type; @columns:3 ) when ( @type = 'wrapped' ) {
	.flex();
	flex-wrap: wrap !important;
	justify-content: center;
	max-width: @viewport-l-max;
	& > * {
		@media (@breakpoint-m) {
			flex: 0 0 percentage((1/@columns));
			max-width: percentage((1/@columns));
		}
	}
}

/**
 * balanced
 * This is a standard balanced layout that uses flexbox with items of equal width
 * @type {string} shortname of a type of layout required
 * @size {number} 1/n sets the basis width as a percentage with a default of 3
 */
.grid(@type; @columns:3 ) when (  @type = 'balanced' ) {
	.flex();
	& > * {
		flex: 1 1 percentage( (1/@columns) );
	}
}