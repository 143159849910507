// TODO: move variable to core-less-imports
@overlay-color-dark: hsla(210, 53%, 34%, 0.8);

/*
    The lightbox-dialog has 3 modifiers:
     _small
     _medium
     _large

    These modifiers define the width of the lightbox.
*/

.lightbox {

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: @z-index-popup + 1;
	overflow: hidden;
	background-color: @overlay-color-dark;
	-webkit-overflow-scrolling: touch;

	&-dialog {
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		display: block;
		box-sizing: border-box;
		margin: @grid-offset-s;
		border-radius: @border-radius;
		background-color: @white;
		transform: translateY(-50%);

		@media (@breakpoint-m) {
			margin: 0;
			right: auto;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	/*
	  The .lightbox gets the _long modifier (JS) if the lightbox-container stretches higher than the viewport
	*/
	&_long {
		overflow-y: auto;
		.lightbox-dialog {
			top: @grid-unit-l;
			margin-bottom: @grid-unit-l;
			transform: translate(0);

			// :after to give extra space below the lightbox
			&:after {
				content: '';
				position: absolute;
				height: @line;
				width: 100%;
				bottom: -@grid-unit-l;
				left: 0;
			}

			@media (@breakpoint-m) {
				left: 50%;
				transform: translate(-50%, 0);
			}
		}
	}

	&-container {
		> iframe {
			width: 100%;
			height: 500px;
			border: 0;
		}
		h2 {
			.anwb-thesans-semi-bold-italic();
			font-size: @heading-l;
		}
		margin: @grid-unit-s;
		min-height: 100px;
		height: auto;
		user-select: all;

		@media (@breakpoint-m) {
			margin: @grid-unit-m;
		}
		@media (@breakpoint-l) {
			margin: @grid-unit-l;
		}
	}

	&-closebutton {
		position: absolute;
		top: 0;
		right: 0;
		padding: @rem;
		text-decoration: none;

		&:after {
			.icon;
			content: @var-icon-cross;
			.icon-sm;
			display: block;
		}
	}
}

/*
  this class is set to the body, so that the body stays in position while the lightbox is opened
*/

body.lightbox-isopen {
	position: fixed;
	z-index: 0;
	width: 100%;
	height: 100%;
	user-select: none;
}

.lightbox-dialog {
	&[data-size=l] {
		@media (@breakpoint-m) {
			width: 462px;
		}
		@media (@breakpoint-l) {
			width: 752px;
		}
	}

	&[data-size=m] {
		@media (@breakpoint-m) {
			width: 342px;
		}
		@media (@breakpoint-l) {
			width: 560px;
		}
	}

	&[data-size=s] {
		@media (@breakpoint-m) {
			width: 222px;
		}
		@media (@breakpoint-l) {
			width: 364px;
		}
	}
}