/**
 * Panel
 * - variables
 * - basic

 * ================
 * Parts
 * - panel-base
 * - panel-headings
 * - header-img
 *
 * ================
 * Modifiers
 * - panel-collapse
 * - panel-border
 * - panel-attention


 */

@import (reference) "../variables/layout";
@import (reference) "../variables/colours";
@import (reference) "../variables/typography";
@import (reference) "../mixins/fonts";

// Panels
// ================
// Variables
// Define the gutter between 2 panels, based on base grid
//
// TODO: Rename! Not clear what this is intended for.

// Basic
._panel {
	.panel-base();
	.panel-headings();
}

// Collapse
._panel-collapse {
	.panel-collapse();
}

// Border
._panel-border {
	.panel-border();
}

// Attention
._panel-attention {
	.panel-attention();
}

// Title h3 opmaak voor panels. Geldt voor componenten met een grote van 1/3 en 1/4
._panel-title-h3 {
	.anwb-thesans-semi-bold-italic();
	line-height: @line-height-s;
	margin-bottom: 0;
	max-width: 100%;
	font-size: @heading-panel-m;
}


._panel-grid-offset(){
	padding: @panel-grid-s;
	@media (@breakpoint-m) {
		padding: @panel-grid-m;
	}
	@media (@breakpoint-l) {
		padding: @panel-grid-l;
	}

}
._panel-padding(){
	padding: @grid-unit-s;
	@media (@breakpoint-m) {
		padding: @grid-unit-m;
	}
	@media (@breakpoint-l) {
		padding: @rem-l;
	}

}

._panel-bordered-padding(){
	padding: ~'calc( @{grid-unit-s} - @{line})';
	@media (@breakpoint-m) {
		padding: ~'calc( @{grid-unit-m} - @{line})';
	}
	@media (@breakpoint-l) {
		padding: ~'calc( @{rem-l} - @{line})';
	}
}

// Parts
// ================
.panel-base() {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: ( @border-radius * 2 );
	color: @blue-grey-darker;

	._panel-grid-offset();


	// IE flexbox "bugfix", makes sure the lines don't exceed box width
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: block;
	}

	.panel-body{
		._panel-padding();
		border-radius: @border-radius;
	}


	& p {
		margin-top: 0;
	}
	& img {
		width: 100%;
		@media (@breakpoint-l) {
			width: 100%;
		}
	}

	h1,
	h2,
	h3 {
		.anwb-thesans-semi-bold-italic();
		margin-top: 0;
		margin-bottom: @margin-text-s;
	}

	h1, h2 {
		font-size: @heading-panel-m;
	}
}

.panel-headings() {

	& .panel-body > header {

		h2 { font-size: @heading-s; }
	}
}


.header-img() {

	margin: -@grid-unit-s -@grid-unit-s 0;
	width: ~'calc( 100% + ( @{grid-unit-s} * 2 ) - @{line} * 2 )';
	@media (@breakpoint-m) {
		margin: -@grid-unit-m -@grid-unit-m 0;
		width: ~'calc( 100% + ( @{grid-unit-m} * 2 ) - @{line} * 2 )';
	}
	@media (@breakpoint-l) {
		margin: -@rem-l -@rem-l 0;
		width: ~'calc( 100% + ( @{rem-l} * 2 )  - @{line} * 2  ) ';
	}

	padding: 0;

	& a {
		display: block;
	}

	& img {
		margin-top: @line;
		margin-right: @line;
		margin-left: @line;
		border-radius: (@border-radius - @line ) (@border-radius - @line ) 0 0;
	}
	& + .panel-main {
		margin-top: 0;
	}
}



// Modifiers
// ================

// Border
.panel-border( @color:@blue-grey-light ) {
	border: @line solid @color;
	border-radius: @border-radius;
	._panel-bordered-padding();
}

// Collapse
.panel-collapse() {
	font-family: @anwb-font-family;

	// Border
	&:after {
		content: "";
		margin-top: @rem;
		margin-right: -@rem;
		margin-left: -@rem;
		border-bottom: solid @line;

		// IE11 Bugfix smallscreen...
		// @TODO: remove support for IE11 SMALLSCREEN?
		@media all and (-ms-high-contrast: none) {
			display: block;
		}
	}
	// Hide Checkbox
	> input[type=checkbox] {
		opacity: 0;
		margin: 0;
		padding: 0;
		display: inline-block;
		position: absolute;
		left: -9999px;
	}

	> h3 {
		max-width: inherit;
	}

	> h3 > label {
		font-size: inherit;
		margin-top: -@rem; // enlarge click area
		margin-bottom: -@rem; // enlarge click area
		padding-top: @rem; // enlarge click area
		padding-right: @rem-l;
		padding-bottom: @rem; // enlarge click area
		position: relative;
		width: 100%;
		display: flex;
		cursor: pointer;
	}

	> .panel-main {
		margin-bottom: @rem-xs;
	}

}

// Attention
.panel-attention() {

	background-color: @blue-grey-lighter;
	padding: @grid-unit-s;
	@media (@breakpoint-m) {
		padding: @grid-unit-m;
	}
	@media (@breakpoint-l) {
		padding: @rem-l;
	}
}