@media print {


  // Remove the header and footer of the website //
  body {
    > header {
      display: none !important;
    }

    > footer {
      display: none;
    }
  }

  main {
	  max-width: 160mm !important;
	  font-size: 11px !important;
  }
	article a[href]:after
	{ content:" (" attr(href) ") "; }
  // -- //
  // Style stuffs different //
  // TODO: this is waaaaay to broad...
  aside, article, div, section {
    *:not([class^="leaflet"]) & {
      display: block !important;
    }

    &:not([class^='sticker-']) {
        max-width: 100% !important;
        //width: 100% !important;
        clear: both;
        margin: 0;
      }
    }

  section {
    //page-break-inside: avoid;
    clear: both;
  }

  [class|='grid'] {
	  display: block !important;
    //flex-direction: column;
    margin: 0;
	column-count: initial !important;

  }

  // All the text should be black, this is very !important
  * {
    color: @black !important;
    overflow: visible !important;
  }

  // Border around button - if user chooses to disable background graphics
  [class*='btn-']:not([class*='link_']){
    border: 1px solid @blue-grey;
  }

  .tab-container > .panel-body, .tab-nav > .active {
    border: 1px solid @blue-grey;
  }

  .panel-compare > .panel-body {
    border: 1px solid @blue-grey;
  }

  .panel-product > .panel-body {
    border: 1px solid @blue-grey;
  }

  .panel-contact > .panel-body {
    border: 1px solid @blue-grey;
  }

  [class|=sticker]{
	  text-align: center;
  }

  .sticker-information {
    border: 1px solid @yellow;
  }

  .sticker-price {
    border: 1px solid @red;
  }


  // -- //



  // Remove parts of elements //

  // Gallery navigator (and arrows)
  .gallery-nav-holder {
    display: none;
  }
	.gallery-nav-holder,
  .gallery-nav-overlay,
  .gallery-nav-overlay *{
    display: none !important;
  }
	.gallery {
		input,
		img {
			display: none;
		}

	}
	.gallery :checked ~ .gallery-slide img {
		display: block;
	}

  // -- //

  // print-specific stuff //
  body {
    width: 100%;
    padding: 20mm;

    //margin: 0 auto;
	  &:before {
		  content: url('images/anwb-logo.svg');
		  position: absolute;
		  top: 0;
		  //left: 0;
		  width: 30mm !important;
		  max-width: 30mm !important;
	  }

  }

  main {
    margin-top: 10mm;
    border-top: @line solid @blue-grey-dark;
    position: relative;
    padding-top: 5mm;

    flex-direction: column;

  }


  [class|='panel'] {
    page-break-inside: avoid;
  }

  // -- //
  // Remove complete components //
  // Huge header-image
  .section-component {
	  display: none !important;
  }
  .grid-content > aside {
	  display: none !important;
  }

  // Wildcard, because javascript does some stuffs with the stickymenu and classnames
  [class*='stickymenu'],
  .tab-slide-container > footer > a,
  [class|=tab] :after,
  .panel-faq :after,
  //.panel-crossSell, // we need the crossSell
  .section-informational,
  .panel-twitter,
  .panel-more-news,
  .panel-poll,
  .widget-tabbed,
  .panel-list-group,
  .tab-nav,
  .panel-list-morenews,
  .btn,
  button,
  // Video
  .embed-container {
    display: none !important;
  }

  // Sticky feedback button on right side of the page
  [data-ui="nps-btn"] {
    display: none !important;
  }

  // The link lists in the <aside> have no added value to a printed page
  aside {
    [class*='list'] {
      display: none !important;
    }
  }


  // The tooltip link
  [data-ui="tooltip"] {
    display: none;
  }

  [class*='tooltip'] {
    display: none !important;
  }

  [class^='ad-'] {
    display: none !important;
  }

  .blockanchor {
    display: none !important;
  }

  *:not(h3) > [class|='btn'] {
    display: none !important;
  }


  // -- //
}