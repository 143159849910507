.@{anwb-prefix}sticker {
	display: block;
	padding: 0 5px;
	border-radius: 50%;
	font-weight: normal;
	font-family: @anwb-font-family;
	line-height: @line-height-s;
	text-align: center;

	width: @sticker-m;
	height: @sticker-m;
	@media (@breakpoint-l) {
		width: @sticker-l;
		height: @sticker-l;
	}

	&__body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}

	&__line {
		display: block;
		align-self: center;
		font-weight: normal;
		margin: 0;
		padding: 0;

		&--size-1 {
			font-size: @heading-l;
			font-weight: bold;
			font-style: normal;
		}

		&--size-2 {
			font-size: @font-size-l;
			font-weight: bold;

			@media (@breakpoint-l) {
				font-size: unit(( 20 / @target-px-size ), em);
			}
		}

		&--size-3 {
			font-size: @font-size-s;
		}

		&--size-4 {
			font-size: @font-size-xs;

			@media (@breakpoint-l) {
				font-size: unit(( 13 / @target-px-size ), em);
			}
		}

		&--size-icon {
			.text2icon();
		}
	}

	//** STICKER CLASSES **//

	&, // default
	&--information {
		background-color: @yellow;
	}

	&--price {
		background-color: @red;
		color: @white;
	}

	&--rating {
		background-color: @white;
		border: 2px solid @blue-dark;
		margin: 0;
		
		width: 40px;
		height: 40px;
		
		@media (@breakpoint-m) {
			width: 58px;
			height: 56px; // don't know why it is 2 px smaller...
		}
	}
}