//
// Panel Editorial
//--------------------------------------------------
.panel-editorial {
	&:extend(._panel all);
	.panel-body {
		&:extend(._panel-border all);

		> header {
			.header-img();
		}

		.ANWB-panel__content--border { // for forwards compability...
			position: relative;

			&:not(:last-child)::after {
				position: absolute;
				bottom: -@line;
				left: -@grid-unit-s;
				right: -@grid-unit-s;
				content: '';
				display: block;
				height: @line;
				background: @blue-grey-light;

				@media (@breakpoint-m) {
					left: -@grid-unit-m;
					right: -@grid-unit-m;
				}

				@media (@breakpoint-l) {
					left: -@rem-l;
					right: -@rem-l;
				}
			}
		}

	}
}