// Panel Responsive
.panel-responsive {
	&:extend(._panel all);
	&:extend(._panel-collapse all);

	border-bottom: 0;

	&:after {
		border-bottom-color: hsla(60, 100%, 100%, .1);
	}

	input[type=checkbox] ~ div.panel-main {
		display: none;

		@media (@breakpoint-m) {
			display: flex;
		}
	}

	@media (@breakpoint-m) {
		border-bottom: none;

		&:after {
			content: "";
			border-bottom: 0;
		}

		// reset cursor to disable visual que of a pointer
		> h3 > label {
			cursor: default;
		}

		// Hide Icon
		> h3 > label:after {
			display: none;
		}

		> input[type=checkbox]:focus + h3 > label {
			color: inherit;
		}

		input[type=checkbox] ~ .panel-main,
		header {
			display: flex;
		}
	}
}