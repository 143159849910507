.@{anwb-prefix}title {
	@block: ~"@{anwb-prefix}title";

	display: block;

	&:last-child {
		margin-bottom: 0;
	}

	&--full {
		max-width: none;
	}

	// content titles
	&--content {
		&-1 {
			&:extend(h1);
		}

		&-2 {
			&:extend(h2);
		}

		&-3 {
			&:extend(h3);
		}

		&-4 {
			&:extend(h4);
		}

		&-5 {
			&:extend(h5);
		}

		&-6 {
			&:extend(h6);
		}
	}

	// component titles
	&--component {
		.__component-familiy() {
			max-width: none;
			font-family: @anwb-font-family;
			font-style: italic;
			font-weight: normal;
			line-height: 1.2;
			margin-top: 0;
			margin-bottom: @margin-text-m;

			@media (@breakpoint-m) {
				margin-bottom: @margin-text-l;
			}
		}

		&-1 {
			.__component-familiy();
			font-size: @heading-l;
		}

		&-2 {
			.__component-familiy();
			font-size: @heading-m;
		}

		&-3 {
			.__component-familiy();
			font-size: @heading-panel-m;
		}

		&-4 {
			.__component-familiy();
			font-size: @heading-s;
		}
	}
}
