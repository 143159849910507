@traffic-prefix: TRW-;

.@{traffic-prefix}panel {
	@block: ~"@{traffic-prefix}panel";

	&__header {
		display: flex;
		flex-wrap: wrap;
	}

	&__icon {
		float: none;
		flex: 0 0 auto;
	}

	&__title-container {
		padding-right: 1rem;
		flex: 1 1 0;

		.@{block}__title {
			padding-left: @margin-text-m;

			.@{block}--route & {
				padding-left: 0;
			}
		}
	}

	&__header-text {
		flex:  0 0 100%;
		width: 100%;
		margin-bottom: 0;
		.padding-panel-sides(@border: true);
	}

	&__tweets,
	&__usp,
	&__form {
		@media (@breakpoint-s) {
			display: none;
		}
	}

	&__form {
		input, button {
			margin: 0.4em 0;
		}
	}

	&__cta-link {
		@media (@breakpoint-s) {
			margin-top: 0;
		}
		font-size: 1.2rem;
	}
}