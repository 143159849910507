@import (reference) "../forms/_buttons";

// Link settings
a {
	color: @blue;
	text-decoration: underline;

	&:focus,
	&:hover {
		color: @blue-dark;
	}
	&:active {
		color: @orange;
	}
}

a[class|='link'] {
	max-width: 100%;
	color: @blue-dark;
	&:after {
		display: inline-block;
		font-style: normal;
		font-weight: normal;
		color: @blue;
		margin-left: 7px;
		margin-right: 3px;
		transition: margin .2s;
	}
	// all links that have a href attribute have an arrow icon by default (if made visible)
	&[href]:after {
		content: @var-icon-arrow-right;
	}
	// all absolute urls that do not contain .anwb.nl/ will be interpreted as external
	&[href^='http']:not([href*='.anwb.nl']):after {
		content: @var-icon-external-link;
	}
	// supported document links
	&[href$='.pdf']:after,
	&[href$='.doc']:after,
	&[href$='.docx']:after {
		content: @var-icon-document;
	}

	&:hover {
		color: @blue;
		> *, &:before {
			color: @blue;
		}
		&::after {
			margin-left: 10px;
			margin-right: 0;
			transition: margin .2s;
		}
	}

	&:active {
		color: @orange;
		> *, &:before, &:after {
			color: @orange;
		}
	}

	text-decoration: none;
	cursor: pointer;
	font-family: @anwb-font-family;

	& > *:after {
		padding: 0;
		font-size: unit( @font-size-m, rem );
	}

	> small {
		font-family: @default-fonts;
	}
}

:not(.socialmedia-buttons) > .panel-body > a,
.panel-body h3 > a,
.panel-body h4 > a,
[class^="panel-"]:not(.panel-product):not(.panel-callToAction):not(.panel-compare) > .panel-body ul > li > a:not([data-iris-link]),
.panel-product-rating > .panel-body > :not(header) > a {
	&:extend(a[class|='link'] all);
}

// don't show icons after links in usp's and quick-to panel
.panel-usp a:after,
.panel-quick-to li > a:after {
	content: none!important;
}

// button links for product and call-to-action
.panel-body > :not(header) > ul > li {
	.panel-product > &,
	.panel-callToAction > & {
		a:extend(._btn all):extend(._btn_arrow-right all) {
			&:after {
				display: inline;
			}
		}
		&:first-of-type {
			a:extend(._btn-primary all) {};
		}
		&:not(:first-of-type) {
			a:extend(._btn-link all) {
				border-style: none!important;
			}
		}
	}
}

#ctaBtn,
.showcase-content .panel-main {
	> a:not(.btn-decide):extend(._btn-primary all):extend(._btn_arrow-right all) {};
}

main > section > div > a:not([href]):empty,
a[data-title]:empty {
	position: absolute;
	html[has-stickymenu] & {
		margin-top: -@rem-component-m * 1.5;
	}
}

:not(.panel-compare) > .panel-body > footer > ul {
	&:extend(.list-linklist all);
	.panel-editorial & > li > a {
		padding: @offset-s 0;
	}
}
