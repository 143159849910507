/**
 * Buttons
 * Private class to allow extending within CSS/LESS
 * Never use in HTML!!!
 */
@import (reference) "../variables/layout";
@import (reference) "../variables/typography";

._btn {
	display: inline-block;
	width: 100%;
	max-width: 100%;
	margin:0;
	border-radius: @border-radius;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	font-weight: bold;
	font-size: @font-size-m;
	font-family: @anwb-font-family;
	line-height: 1.2;
	cursor: pointer;
	outline: none;
	@media (@breakpoint-m) {
		width: auto;
	}
}



/**
 * MIXIN: button-variant
 * Creates specific ANWB buttons
 * @params {string}
 */
.button-variant(@colour; @background; @background-image; @border; @border-colour; @hover-colour; @hover-background-image; @active-colour; @active-text-colour; ) {

	background: @background;
	background-image: @background-image;
	color: @colour;
	border-width: @border;
	border-style: solid;
	border-color: @border-colour;
	padding:@rem-l;
	padding-top:(unit( ( 25 / @target-px-size ), rem ) / 2);
	padding-bottom:(unit( ( 25 / @target-px-size ), rem ) / 2);

	&:focus,
	&:hover {
		background-image: @hover-background-image;
		border-color: @hover-colour;
		color: @hover-colour;
	}
	&:active {
		background-image: @active-colour;
		border-color: @active-colour;
		color: @active-text-colour;
	}

	// Correct padding when there is a border
	& when ( @border > 0 ){
		padding-top:(unit( ( (25 - (@line * 2)) / @target-px-size ), rem ) / 2);
		padding-bottom:(unit( ( (25 - (@line * 2)) / @target-px-size ), rem ) / 2);
	}


	// Modifier
	// - Condensed button
	&[class*=_condensed]{

		padding-top:(unit( ( 15 / @target-px-size ), rem ) / 2);
		padding-bottom:(unit( ( 15 / @target-px-size ), rem ) / 2);

		// Correct the border with this Guard (border > 0)
		& when ( @border > 0 ){
			padding-top:(unit( ( (15 - (@line * 2)) / @target-px-size ), rem ) / 2);
			padding-bottom:(unit( ( (15 - (@line * 2)) / @target-px-size ), rem ) / 2);
		}
	}
}
