// The new grids are in _structure.less

/*******************
 * HERE BE DRAGONS *
 *******************/
// Here we'll do some fixes (the dragons) in BEM components in the old grid

body > main > section.ANWB-structure > *:not(.stickymenu):not(.showcase):not(.quick-to-spacer):not(.grid-section-title) {
	margin: 0;
}

.grid-full > .ANWB-panel > div:not([class|='grid']),
.grid-content > .ANWB-panel > div:not([class|='grid']),
.block-faq-container > .ANWB-panel > div:not([class|='grid']) {
	padding-left: 0;
	padding-right: 0;
}

[class|='grid'] article {
	& > .@{anwb-prefix}panel,
	& > * > .@{anwb-prefix}panel {
		padding: @panel-grid-m 0;
	}

	& > .@{anwb-prefix}structure,
	& > * > .@{anwb-prefix}structure {
		margin-left: -@panel-grid-s;
		margin-right: -@panel-grid-s;
		@media (@breakpoint-m) {
			margin-left: -@panel-grid-m;
			margin-right: -@panel-grid-m;
		}
		@media (@breakpoint-l) {
			margin-left: -@panel-grid-l;
			margin-right: -@panel-grid-l;
		}
	}
}

.grid-full > :not(.container) > div:not([class|='grid']),
.grid-content > :not(.container) > div:not([class|='grid']),
.block-faq-container > :not(.container) > div:not([class|='grid']) {
	*:not(.@{anwb-prefix}structure) .@{anwb-prefix}structure {
		margin-left: -@panel-grid-s;
		margin-right: -@panel-grid-s;
		@media (@breakpoint-m) {
			margin-left: -@panel-grid-m;
			margin-right: -@panel-grid-m;
		}
		@media (@breakpoint-l) {
			margin-left: -@panel-grid-l;
			margin-right: -@panel-grid-l;
		}
	}
}
