.@{anwb-prefix}list {
	@block: ~"@{anwb-prefix}list";

	&,
	> ul,
	> ol {
		.reset-list();
	}

	&:not(:last-child) {
		margin-bottom: 1rem;
	}
	//& + &,
	//& + p,
	//& + h2,
	//& + h3,
	//& + h4,
	//& + h5,
	//& + h6 {
	//	margin-top: 1rem;
	//}

	li {
		margin: 0;
	}

	&__item {
		padding-top: @offset-s;
	}

	&__title {
		font-size: @font-size-m;
	}

	&--checked {
		.@{block}__item {
			position: relative;
			padding-left: @rem-xl;
			.list-check();
		}
	}

	&--bulleted {
		list-style-type: disc;
		margin-left: 1.2rem;
	}

	&--media {
		.@{block}__item {
			position: relative;
			padding-left: 4.4rem;
		}

		.@{block}__icon {
			position: absolute;
			left: 0;
			.text2icon();
		}
	}
}