/*@viewport-menu-width: 100vw;
@viewport-menu-height: ~'calc(100vh - @{utilities-offset})';

._viewport-menu-size() {
	height: @viewport-menu-height;
	width: @viewport-menu-width;
}

._show-mobile-menu() {
	display: block;
	width: 100vw;
}

@media not all and (@breakpoint-m--header) {

	.checked() {

		& > ul {
			position: fixed;
			top: @utilities-offset;
			left: 100vw;
			transition: left .3s ease-out;
			display: block;
			margin: 0;
			max-height: none;
			max-width: none;
			width: 100vw;
			._viewport-menu-size();
			background: @white;
			overflow: hidden;
			&:before {
				content: '';
				font-family: @anwb-font-family;
				position: fixed;
				top: @utilities-offset;
				left: 0;
				padding: 0;
				display: block;
				height: 0;
				width: 0;
				background-color: @blue-grey-lighter;
				color: @blue-grey-lighter;
				transition: color .3s ease-out;
			}
			> li:first-of-type {
				border-top: @utilities-offset solid @blue-grey-lighter;
			}
		}

		& > ul > li:last-child {

			&:after {
				content: '';
				height: @line;
				width: 100%;
				display: block;
				position: relative;
				bottom: -@utilities-offset*3;
			}
		}

		& > :checked ~ ul {
			left: 0;
			transition: left .3s ease-in;
		}

		.onTop ~ ul {
			top: @utilities-offset;
			overflow-x: hidden;
			overflow-y: auto;
			&:before {
				content: attr(data-submenu-title);
				width: 100%;
				height: @utilities-offset;
				color: @blue;
				padding-top: @rem-s;
				padding-left: @rem-component-m;
			}

			&:after {
				.icon;
				content: @var-icon-chevron-left;
				font-size: @font-size-s;
				position: fixed;
				top: @rem-component-l + @grid-unit-l;
				left: @rem-s;
				z-index: @z-index-cookie;
			}

			> li:first-of-type {
				margin-top: @utilities-offset;
				border-top: 0;
			}
		}

		.onTop ~ label {
			position: fixed;
			z-index: 100;
			top: @utilities-offset;
			left: 0;
			background: transparent;

			user-select: none;
			color: @blue-grey-lighter;
			text-indent: -9999px;

			& > a {
				color: @blue-grey-lighter;
				border-bottom: 0;
				&::after {
					display: none;
				}
			}
			&:hover a {
				color: @blue-grey-lighter;
			}
		}
	}

	.@{anwb-prefix}navigation {


		//  Hide the menu when the sticky is visible.
		.stickymenu-open & {
			position: relative;
			z-index: -@z-index-sticky;
		}


		height: @utilities-offset;
		ul, li, label, a {
			margin: 0;
			padding: 0;
			//width: 100vw;
			display: block;
			max-width: none;
			list-style: none;
		}
		&:after {
			content: "";
			background: @yellow;
			width: 100%;
			height: 10px;
			left: 0;
			position: absolute;
			top: 39px;
		}

		ul {
			max-width: 100%;
		}
		[aria-label="submenu"] {
			._viewport-menu-size();
		}
		& > nav > ul > li[aria-haspopup] {
			background: @white;
			position: absolute;
			top: 0;
			left: 0;
			height: @utilities-offset;

			& > ul {
				position: fixed;
				top: @utilities-offset;
				//top: 0;
				left: 0;
				overflow-x: hidden;

				display: none;
				._viewport-menu-size();
				padding-bottom: @utilities-offset * 2;
			}

			& > :checked ~ ul {
				display: block;
			}
		}

		& > nav > ul > li > label {
			position: absolute;
			//z-index: @z-index-logo;
			top: 5px;
			overflow: hidden;
			width: 44px;
			height: 34px;
			text-align: center;
			line-height: 2.3;
			align-self: center;
			padding-bottom: 5px;
			padding-right: @margin-text-m;
			border-radius: @border-radius-s @border-radius-s 0 0;
			color: transparent;
			&:hover {
				color: transparent;
			}
			&:before {
				width: 44px;
				height: 34px !important;
				display: block;
				color: @blue-dark;
				font-size: @font-size-m;
				margin: 8px 14px;
			}

		}

		& > nav > ul > li {
			& > :checked ~ label {
				background: @yellow !important;
			}
		}

		// Main menu
		.@{anwb-prefix}menu > li {
			& > label {

				right: @panel-grid-l;
				z-index: 10;
				&:before {
					.icon;
					content: @var-icon-menu;
					.icon-lg;
				}
			}
			& > :checked ~ label {

				&:before {
					.icon;
					content: @var-icon-cross !important;
					.icon-lg;
				}
			}
		}

		// User menu
		.@{anwb-prefix}navigation__user .@{anwb-prefix}dropdown__label {
			right: ~'calc( ( @{utilities-offset} * 2 ) + @{panel-grid-l} )';
			z-index: 30;
			&:before {
				.icon;
				content: @var-icon-profile;
				.icon-lg;
			}

		}

		//Search
		.@{anwb-prefix}navigation__search .@{anwb-prefix}dropdown__label {
			right: ~'calc( @{utilities-offset} + @{panel-grid-l} )';
			z-index: 20;
			&:before {
				.icon;
				content: @var-icon-search;
				.icon-lg;
			}

		}
		.@{anwb-prefix}search__form {
			display: block;
		}
	}
	.@{anwb-prefix}navigation__logo a:last-child:before {
		top: ~'calc( @{offset-s} + @{line} )';
		left:inherit;
	}
	.@{anwb-prefix}navigation__search,
	.@{anwb-prefix}navigation__user,
	.@{anwb-prefix}navigation__content {

		ul, li, label, a {
			margin: 0;
			padding: 0;
			width: 100vw;
			display: block;
			max-width: none;
			list-style: none;
			//background: @white;
		}
		ul ul, ul ul li {
			width: 100%;
		}
		
		li {
			font-size: @font-size;

			a {
				text-decoration: none;
				color: @blue-dark;
				border-bottom: @line solid @blue-grey-lighter;
				padding: .8em @panel-grid-l;
			}

			:hover {
				color: @blue;
			}

		}

		& ul > [aria-haspopup] > ul [aria-haspopup] {
			.checked();
		}

		& [aria-haspopup ] > label {

			& > a {
				pointer-events: none;
				&:after {
					float: right;
					.icon;
					content: @var-icon-chevron-right;
					font-size: @font-size-xs;
					color: @blue;
					display: inline-block;
					line-height: 2;
					font-weight: bold;

				}
			}
			&:hover a {
				color: @blue;
			}

		}
	}
	.@{anwb-prefix}navigation__content-link--group {
		.@{anwb-prefix}navigation__utilities {
			overflow: hidden;
			width: 100%;
			z-index: @z-index-menu - 3;
			display: block;
			margin-top: @panel-grid-l;
		}
		.@{anwb-prefix}navigation__utilities a {
			border: 0;
			font-size: @font-size-xs;
		}
	}

	.subActive {
		overflow: hidden;
	}
}*/

@media not all and (@breakpoint-m--menu) {
	.@{anwb-prefix}navigation {

		//  Hide the menu when the sticky is visible.
		.stickymenu-open & {
			position: relative;
			z-index: -@z-index-sticky;
		}

		&:after {
			content: "";
			background: @yellow;
			width: 100%;
			height: 10px;
			left: 0;
			position: absolute;
			top: 39px;
		}
	}
}
