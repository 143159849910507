/*
 TODO: Refactor blocks to sections.
*/

/* Mixins for Extend
- Base
- Blue
- Yellow

// VARIANTS
- products (TODO: REFACTOR)
-- block-faq

*/

// Base (private)
._block-base {

	padding-bottom: (@grid-offset-s - @panel-grid-s);

	@media (@breakpoint-m){
		padding-bottom: (@rem-component-m * 2 - @panel-grid-m);
	}

	@media (@breakpoint-l){
		padding-bottom: (@rem-component-m * 2 - @panel-grid-l);
	}
	& > [class*='grid-'] > div:first-child {
		._panel-grid-offset();

	}

	> header {

		margin-bottom: @rem-xs;
		text-align: center;
		margin-top: 0;
		border: @grid-unit-s solid transparent;
		border-top:0;
		border-bottom:0;

		@media (@breakpoint-m) {
			border-width: @grid-unit-m;
		}

		@media (@breakpoint-l) {
			border-width: @grid-unit-l;
		}

		> i {
			&:extend(.icon);
			&:extend(.icon-jumbo-xxl);
			display: inline-block;
			margin-bottom: 1rem;
		}

		> h2 {
			max-width: none;
			font-family: @anwb-font-family;
			font-style: italic;
			font-weight: normal;
			font-size: @heading-l;
			margin-left: @rem-s;
			margin-bottom: @line;

			@media (@breakpoint-m) {
				margin-bottom: 0;
			}
		}

		> p {
			max-width: none;
			margin-top:@line;
			margin-bottom: 0;
		}
	}

	& .blockanchor header {
		margin: @rem-s 0 0;
	}
}

// Blue (private)
._block-blue {
	&:extend(._block-base all);
	background-color: @blue-grey-lighter;
}

// Yellow (private)
._blocks-yellow {
	&:extend(._block-base all);
	background-color: @yellow;
}

// VARIANTS
// -----------


// FAQ
// - Block
// - Container

.section-informational {
	&:extend(._block-blue all);

	section.panels-stacked:not(:empty) {
		margin-top: 0;
		padding-top: 0;
		margin-bottom: @rem-s;

		@media (@breakpoint-m) {
			padding-bottom: 0;
			margin-bottom: -@rem-s;
		}
		@media (@breakpoint-l) {
			padding-bottom: 0;
			margin-bottom: -@rem-s;
		}
	}
}
.section-promotional {
	&:extend(._blocks-yellow all);
}

.block-faq-container {
	&:extend(._panel all);
	&:extend(.grid-content all);

	padding-top: 0;
	border-top: 0;
	border-bottom: 0;

	.panels-stacked {
		margin-bottom: 0;
	}
}

// USP.
// products
.block-products-container,
.block-usp-container {
	.grid-centered;
}

.block-usp-container {
	padding-top: (@rem-component-s - @panel-grid-s);

	@media (@breakpoint-m) {
		padding-top: (@rem-component-m - @panel-grid-m);
	}

	@media (@breakpoint-l) {
		padding-top: (@rem-component-l - @panel-grid-l);
		}
}


// Section Titles
.section-title {
	border-bottom: 0;

	h2 {
		max-width: 100%;
	}

	+ section {
		border-top: 0;
	}
}
