//
// Panel List Group
//--------------------------------------------------

.panel-home-app {
	&:extend(._panel all);

	@media (@breakpoint-m) {
		// Very nasty hack to reduce the whitespace between the H1 and the panels
		// TODO: Remove after template fix in Hippo
		margin-top: -@grid-offset-m;
	}

	& > .panel-body {
		&:extend(._panel-border all);
		flex: 1; // Stretch the box
	}

	.panel-body {
		li {

			margin-top: @offset-s;
			margin-bottom: @offset-s;
			&:last-of-type {
				margin-bottom: 0;
			}
		}

		> header {
			._panel-padding();
			padding-top: 0;
			margin-right: -@grid-unit-s;
			margin-left: -@grid-unit-s;

			@media (@breakpoint-m) {
				padding-top: 0;
				margin-right: -@grid-unit-m;
				margin-left: -@grid-unit-m;
			}
			@media (@breakpoint-l) {
				margin-right: -@rem-l;
				margin-left: -@rem-l;
				padding-top: 0;
			}
		}
		> footer {
			border-top: solid @line @blue-grey-light;
			._panel-padding();
			margin-top: @rem;
			margin-right: -@grid-unit-s;
			margin-left: -@grid-unit-s;

			@media (@breakpoint-m) {
				margin-right: -@grid-unit-m;
				margin-left: -@grid-unit-m;
			}
			@media (@breakpoint-l) {
				margin-right: -@rem-l;
				margin-left: -@rem-l;
			}
		}
	}

	.header-border_bottom {
		border-bottom: solid @line @blue-grey-light;
	}

	.panel-main form {
		.form-layout();

		footer {
			display: block;
			> * {
				margin-right: @rem;
				margin-bottom: @rem;
			}
		}
	}
}



// Todo: make New generic component
.media-list {
	&:extend(._list-unstyled all);
	> * {
		margin-bottom: @rem;
		&:last-child{
			margin-bottom: 0;
		}
	}
}

.media {
	display: flex;
	flex-wrap: nowrap;
	position: relative;

	& > .media-object {
		padding-right: @grid-unit-s;
		@media (@breakpoint-m) {
			padding-right: @grid-unit-m;
		}
		@media (@breakpoint-l) {
			padding-right: @rem-l;
		}
		> i {
			&:extend(.icon);
			&:extend(.icon-jumbo-xl);
		}
	}
	& > .media-body {
		flex:1;
		h3 { ._panel-title-h3; }
		h4 { font-size: inherit;}
		h4,
		p { margin-bottom: 0;

		}
	}
	& ~ .media-link {
		margin: @rem-base 0 0;
	}

}


// Todo: make New generic component
.badge {
	display: table;
	padding: @rem-xs;
	border-radius: @border-radius-s;
	.anwb-thesans-semi-bold-italic();
	background: @blue-grey-light;
	font-size: @heading-s;
	line-height: @line-height-compressed;

	> * {
		display: table-cel;
		vertical-align: middle;
	}
}

.badge-default {
	.badge;
	margin-top: @rem-xs;

	& > .numbers {
		font-size: @heading-m;
		margin-right: @rem-xs;
		a {
			text-decoration: none;
			color: inherit;
		}
	}
}

.svg-triangle {
	&:extend(.icon-jumbo-xl);
	margin: 0 auto;

	polygon {
		stroke-width:20;
		stroke-linejoin: round;
		fill: @white;
		stroke: @white;

		&.warning {
			&-level-0 {
				fill: @green;
				stroke: @green;
			}

			&-level-1 {
				fill: @orange;
				stroke: @orange;
			}

			&-level-2 {
				fill: @red;
				stroke: @red;
			}

			&-level-3 {
				fill: @black;
				stroke: @black;
			}
		}
	}
	text {
		font-size: 1.5em;
		fill: white;
	}
}

.overlayed {
	position: absolute;
	display: inline-block;
	color: @white;
	font-size: @heading-m;
	top: 12px;
	left: 12px;
}