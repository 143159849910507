//
// Panel social bar
//--------------------------------------------------

._social-bar-padding() {
	padding: @offset-m 0 @grid-unit-l;
	@media (@breakpoint-m) {
		padding: @line 0 ( @grid-unit-m - @margin-text-s );
	}
	@media (@breakpoint-l) {
		padding: @offset-s 0 @rem-l;
	}
	&:last-child {
		padding-bottom: 0;
	}
	// override padding on the small viewport
	padding: 0 0 @offset-s;
}

.socialmedia-buttons {
	clear: both;
	p {
		margin: 0 auto @margin-text-s;
		display: none;
	}
	a {
		text-decoration: none; // not supported on pseudo-elements in IE/Edge
		//display: inline-block;
		margin-right: @rem;
		&:last-child {
			margin-right: 0;
		}
		&:before {
			display: inline-block;
			border-radius: @border-radius;
			line-height: 1.25;
			text-align: center;
			//outline: 2px solid red;
			text-decoration: none;
			font-size: @heading-l;
			height: 34px;
			width: ( @_rem-unit * 9 );
		}
	}
}

body > footer > .socialmedia-buttons {
	&:extend( ._panel all );
	._social-bar-padding();
	background: @blue-grey-lighter;
	text-align: center;
	p {
		display: block;
	}
}