// Grid definitions used inside a top level <section>

[class|='grid']:empty {
	display: none !important;
}
.grid-content  {
	.grid( @type: 'asymmetric' );

	& > div > section > [class|=panel],
	& > div > article > .socialmedia-buttons,
	& > div > article > [class|=panel] {
		.typographic-margin();
	}
}

.grid-full {
	.grid('balanced', 1);
} //[1/1]

main > section > .grid-full {
	// next line makes the margin collapse if theres nothing with display inside the grid element
	// NOTE: probably needs to be defined some place else, fixes hiding traffic warning for now.
	display: block!important;
	// other grids (such as grid-stacked) need to have their column-count set to auto.
	// for now, this is done using a js hack.
}

.grid-balanced {

	.grid('balanced', 4);

	@media(@breakpoint-l) {
		h3 {
			.hyphenate();
		}
	}

} //[1/4][1/4][1/4][1/4]

.grid-split  { .grid('wrapped', 2); } //[1/2][1/2]

.grid-centered { .grid('wrapped', 3); }

// This a Hippo only solution!
// TODO: Remove class name after HIPPO template cleanup
.grid-overview {
	& > div {
		.grid('stacked');
	}
}

/**
 * Basic grid types for external use
 */

.grid-asymmetric {

	// default arguments (in order):

	// type = asymmetric
	// columnbase = 3
	// sidebar-first = false

	&:not([data-column-limit]),
	&[data-column-limit='3'] {
		.grid( 'asymmetric' );
	}

	&[data-column-limit='4'] {
		.grid( 'asymmetric', 4 );
	}

	& > * + * ~ * {
		display: none;
	}

	& > div > section > [class|=panel],
	& > div > article > [class|=panel] {
		padding: 0;
		.typographic-margin();
	}
	.application & {
		& > div > section {
			._panel-grid-offset();
			margin: 0;
			max-width: none;
		}
	}

}

.grid-balanced {
	&[data-column-limit='1'] {
		.grid('balanced', 1);
		& > [class*=panel]:nth-of-type(1) ~ * {
			display: none;
		}
	}
	&[data-column-limit='2'] {
		.grid('balanced', 2);
		& > [class*=panel]:nth-of-type(2) ~ * {
			display: none;
		}
	}
	&[data-column-limit='3'] {
		.grid('balanced', 3);
		& > [class*=panel]:nth-of-type(3) ~ * {
			display: none;
		}
	}
	&:not([data-column-limit]),
	&[data-column-limit='4'] {
		.grid('balanced', 4);
		& > [class*=panel]:nth-of-type(4) ~ * {
			display: none;
		}
	}
}
.grid-wrapped {
	.grid('wrapped', 3);
	&[data-column-limit='2'] {
		.grid('wrapped', 2);
	}
	&:not([data-column-limit]),
	&[data-column-limit='3'] {
		.grid('wrapped');
	}
	&[data-column-limit='6'] {
		.grid('wrapped', 6);
	}
}
.grid-stacked {
	&:not([data-column-limit]),
	&[data-column-limit='3'] {
		.grid('stacked', 3);
	}
}

.grid-pulled {

	@media (@breakpoint-m){
		display: block;
		margin: 0 auto;
		max-width: @viewport-l-max;
		overflow: hidden;

		> * {
			float: right;
			width: percentage(( ( 3 - 1 ) / 3 ));
		}

		.pull {
			float: left;
			width: percentage(( 1 / 3 ));
		}
	}
}


._flexed-outer-grid-padding(){
	padding: 0  (@grid-offset-s - (@grid-unit-s / 2) );
	@media (@breakpoint-m) {
		padding: 0 (@grid-offset-m - (@grid-unit-m / 2) );
	}
	@media (@breakpoint-l) {
		padding: 0 (@grid-offset-l - (@grid-unit-l / 2) );
	}
}

// Setting base margin to all sections
body {
	> header {
		//z-index: 1;
		margin: 0;

		@media (@breakpoint-m--menu) {
			margin: 0.225em 0 0 0;
		}

		@media (@breakpoint-l) {
			margin: 1.235em 0 0 0;
		}
	}

	> main {
		display: block;

		> section > *:not(.stickymenu):not(.showcase):not(.quick-to-spacer):not(.grid-section-title):not([class|="ANWB"]) {
			background-clip: padding-box;
			margin-top: ( @rem-component-s * 2 );
			margin-bottom: ( @rem-component-s * 2 );

			&.section-promotional [class*='grid-']:not(.grid-overview):not(.grid-stacked):not(.grid-nogrid),
			&.section-informational [class*='grid-']:not(.grid-overview):not(.grid-stacked):not(.grid-nogrid),
			&[class*='grid-']:not(.grid-overview):not(.grid-stacked):not(.grid-nogrid){
				._flexed-outer-grid-padding();
			}
			&.grid-stacked,
			&.grid-overview > * {
				padding: 0  (@grid-offset-s );
				@media (@breakpoint-m) {
					padding: 0 (@grid-offset-m - (@grid-unit-m / 2) );
				}
				@media (@breakpoint-l) {
					padding: 0 (@grid-offset-m - (@grid-unit-l / 2) );
				}
			}

			@media (@breakpoint-m) {
				margin-top: ( @rem-component-m );
				margin-bottom: ( @rem-component-m );
			}

		}

		> section > .stickymenu {
			margin-bottom: ( @rem-component-s  );
		}

		> section > .showcase {
			margin-bottom: ( @rem-component-s  * -1 );

			@media (@breakpoint-m) {
				margin-bottom: ( @rem-component-m * -1 );
			}
			@media (@breakpoint-l) {
				margin-bottom: ( @rem-component-l * -1 );
			}


		}
		& > section > .grid-section-title:not(:empty) {
			._flexed-outer-grid-padding();
			margin: 0 auto;
			max-width: @viewport-l-max;
			h1{
				margin-top: @rem-l;
				margin-bottom: -@rem-l;
			}
			h1,h2{
				._panel-grid-offset();
			}

			@media (@breakpoint-m) {
				h1 {
					margin-top: @rem-component-l;
					margin-bottom: -2.5rem;
				}
				h2 {
					margin-bottom: -2.5rem;
					max-width: @viewport-l-max;
				}
			}

		}

	}

	> footer {
		> section {}
	}
}

.grid-full,
.grid-content {
	> :not(.container) > div:not([class|='grid']) {
		padding-right: @panel-grid-s;
		padding-left: @panel-grid-s;
		@media (@breakpoint-m) {
			padding-right: @panel-grid-m;
			padding-left: @panel-grid-m;
		}
		@media (@breakpoint-l) {
			padding-right: @panel-grid-l;
			padding-left: @panel-grid-l;
		}
	}
}

.grid-insert(@mixinSmall,@mixinMedium,@mixinLarge){
	.grid-asymmetric > *:first-child &,
	.grid-asymmetric[data-sidebar-first] > *:last-child &,
	.grid-asymmetric[data-sidebar-first='false'] > *:first-child &,
	.grid-content > *:first-child & {
		@mixinMedium();
	}
	.grid-asymmetric > *:last-child &,
	.grid-asymmetric[data-sidebar-first] > *:first-child &,
	.grid-asymmetric[data-sidebar-first='false'] > *:last-child &,
	.grid-asymmetric > aside &,
	.grid-content > aside & {
		@mixinSmall();
	}

	.grid-full & {
		@mixinLarge();
	}

	.grid-split &,
	.grid-balanced &,
	.grid-wrapped &,
	.grid-centered &,
	.grid-stacked &,
	.grid-overview & {
		@mixinSmall();
	}
}


// ==========================DEBUG==================================
//body,
//.debug {
//	color:white;
//
//	~ header { background: olivedrab; }
//
//	~ main {
//
//		> section { background: red; }
//	}
//
//	~ footer {
//		background: hotpink;
//		> section { background: green; }
//	}
//}
//
//.debug ~ main > section > div > *:nth-child(even) { background: rgba(0, 0, 0, .5) !important; }
//
//.debug ~ main > section > div > *:nth-child(odd) { background: rgba(50,50,50, .5); }
//// ==========================DEBUG==================================

