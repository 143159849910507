
/* Reset / Normalize & General Styling ------------------------- */
/* ------------------------------------------------------------- */

select {
	text-transform: none;
}

input[type="button"],
input[type="reset"],
input[type="submit"] {
	appearance: button;
	cursor: pointer;
}
fieldset[disabled] input,
fieldset[disabled] textarea,
fieldset[disabled] select,
fieldset[disabled] button,
textarea[disabled],
select[disabled],
button[disabled],
input[disabled] {
	cursor: default;
	pointer-events: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}


fieldset,
legend {
	padding: 0;
	border: 0;
	margin: 0;
}

legend {
	display: inline-block; /* ZzZ: IE Fix for word-wrap within the "Legend" element */
}

input,
optgroup,
select,
textarea {
	margin: 0;
	color: inherit;
	line-height: @line-height-s;
	font-size: @font-size-m;
	font-family: @default-fonts;
}


input[type="checkbox"],
input[type="radio"] {

	font-size: @font-size-m;
	.hide();
}

input[type="radio"] + span,
input[type="checkbox"] + span {

	padding-left: 1.8rem;
	position: relative;

	display: inline-block; /* ZzZ: IE11 Weird Renderign Glitch Fix s*/

	&::before {
		width: 1.2rem;
		height: 1.2rem;

		top: 0;
		left: 0;

		margin-top: 0.2rem;

		background: @input-bg;
		background-image: none;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: 0.8rem;

		border: @line solid @blue-grey;

		content: '';

		position: absolute;
		display: inline-block;
	}
}



input[type="radio"].invalid + span,
input[type="checkbox"].invalid + span {
	&::before {
		border-color: @red;
	}
}
input[type="radio"]:focus + span,
input[type="checkbox"]:focus + span {
	&::before {
		border-color: @blue;
	}
}
input[type="radio"] + span {
	&::before {
		border-radius: 1.1rem;
	}
}
input[type="radio"]:checked + span {
	&::before {
		background-image: url('images/input-radiobutton-checked.svg');
	}
}

input[type="checkbox"] + span {
	&::before {
		border-radius: 0.1rem;
	}
}
input[type="checkbox"]:checked + span {
	&::before {
		background-image: url('images/input-checkbox-checked.svg');
	}
}

input[type="radio"]:disabled + span,
input[type="checkbox"]:disabled + span {
	&::before {
		background-color: @blue-grey-lighter;
	}
}

input[type="number"] {
	&::-webkit-inner-spin-button{
		height: auto;
	}
	&::-webkit-outer-spin-button{
		height: auto;
	}
}

input[type="search"] {
	appearance: textfield;
	box-sizing: inherit;

	&::-webkit-search-cancel-button {
		-webkit-appearance: none;
	}
	&::-webkit-search-decoration {
		-webkit-appearance: none;
	}
}

select, textarea,
input[type="file"],
input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"],
input[type="date"], input[type="month"], input[type="time"], input[type="week"],
input[type="number"], input[type="email"], input[type="url"], input[type="search"],
input[type="tel"], input[type="color"] {
	width: 100%;
	height: 2.934rem;

	padding: 0 0.7rem;

	color: @blue-grey-darker;

	border: @line solid @blue-grey;
	border-radius:	  @border-radius;

	background-color: @input-bg;
	background-image: none;

	appearance: none;

	&:disabled {
		background-color: @blue-grey-lighter;
	}

	&:focus,
	&.invalid:focus {
		border-color: @blue;
		outline: 0;
	}

	&::placeholder {
		font-style: italic;
		color: @blue-grey-dark;
	}

	&:invalid {
		box-shadow: none;
	}

	&.invalid{
		border: @line solid @red;
	}
}
input[type="file"] {
	padding: 0.6rem 0.7rem;
	cursor: pointer;
	height: auto;
	min-height: 2.934rem;
	max-height: none;
}
select {
	padding: 0 2rem 0 0.7rem;

	overflow: hidden;
	font-size: @font-size-m;
	white-space: nowrap;
	text-overflow: ellipsis;

	background: @input-bg;
	background-image: url('images/input-select-chevron-down.svg');
	background-repeat: no-repeat;
	background-position: ~"calc(100% - 1rem)" 52%;
	background-size: 1rem;

	display: inline-block;

	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 @blue-grey-darker;
	}

	&::-ms-expand {
		display: none;
	}

	&::-ms-value {
		color: @blue-grey-darker;
		background: @input-bg;
	}

	&:disabled::-ms-value {
		background: @blue-grey-lighter;
	}

	&[size] {
		height: auto;
		background-image: none;
		overflow-y: auto;
	}

	// render a select with one item with the same height as a normal select
	&[size="1"] {
		height: 2.934rem;
	}

	& > optgroup {
		font-weight: bold;
	}
}

textarea {
	height: auto;
	padding-top: 0.5rem;
	overflow: auto;
	resize: vertical;

	font-family: inherit;  /* ZzZ: FF Override default browser beaviour */
}
