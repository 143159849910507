@utilities-offset: 49px;
@menu-min-breakpoint-size: '920px';
@menu-max-breakpoint-size: '919px'; // @menu-min-breakpoint-size - 1. Cannot perform this calculation as it is a string
@breakpoint-s--menu: ~"max-width: @{menu-max-breakpoint-size}";
@breakpoint-m--menu: ~"min-width: @{menu-min-breakpoint-size}";

// Header initieel verbergen voor stand-alone applicaties.
// :not([itemtype]) is een tijdelijke fix voor een oude template die nog in omloop is.
html > body:not([itemtype]) > header {
	display: none;
}
// Toon de header alleen in geval van een website of webpage weergave of indien
// een applicatie fullscreen wordt weergegeven.
html[itemtype="http://schema.org/WebSite"] > body > header,
html[itemtype="http://schema.org/WebPage"] > body > header,
html[data-fullscreen-app] > body > header {
	display: block;
}

@media (@breakpoint-s--menu) {
	body > header.active{
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		z-index: @z-index-menu - 2;
		background: @white;
		overflow: hidden;
		//-webkit-overflow-scrolling: touch;
		& ~ * {
			display: none;
		}
		.utilities {
			position: fixed;
			top: 0;
		}

	}

	body > header {
		.utilities {
			overflow: hidden;
			position: relative;
			top: 0;
			left: 0;
			width: 100%;
			height: @utilities-offset;
			z-index: @z-index-menu - 3;
		}
	}
}

@media (@breakpoint-m--menu) {
	body > header {
		//._parent-reset;
		//position: relative;
		z-index: @z-index-menu - 3;
	}
}


.utilities {
	margin: 0 @margin-text-m;
	width: 100%;
	pointer-events: none;
	.logo {
		height: 40px;
		display: inline-block;
		position: relative;
		pointer-events: visible;

		& > a {
			position: relative;
			top: 0;
			width: 90px;
			height: 60px;
			display: block;

			@media (@breakpoint-m--menu) {
				width: 160px;
				top: -( @line);
			}

			&::before {
				position: absolute;
				top: ~'calc( @{grid-unit-s} - @{line} )';
				z-index: @z-index-logo + 1;
				width: 72px;
				content: url(@anwb_logo);

				@media (@breakpoint-m--menu) {
					width: 90px;
					margin-top: 1px;
					margin-left: @grid-unit-m;
					pointer-events: none;
				}

				@media (@breakpoint-l) {
					width: 116px;
					top: 1px;
					margin-left: 1.4em;
				}

			}
		}
	}

	@media (@breakpoint-s--menu) {
		&:after {
			content: "";
			background: @yellow;
			width: 100%;
			height: 10px;
			left: 0;
			position: absolute;
			top: 39px;
		}
	}
}
