/* generic function for defining a cross-browser font */
.font-src(@baseurl) {
  src: url('@{baseurl}.eot');
  src: url('@{baseurl}.eot?#iefix') format('embedded-opentype'),
  url('@{baseurl}.woff2') format('woff2'),
  url('@{baseurl}.woff') format('woff'),
  url('@{baseurl}.ttf') format('ttf');
}

/* Bold */
.anwb-thesans-bold() {
  font-family: @anwb-font-family;
  font-style: normal;
  font-weight: bold;
}

/* Bold Italic */
.anwb-thesans-bold-italic() {
  font-family: @anwb-font-family;
  font-style: italic;
  font-weight: bold;
}

/* Semi Bold Italic */
.anwb-thesans-semi-bold-italic() {
  font-family: @anwb-font-family;
  font-style: italic;
  font-weight: 600;
}