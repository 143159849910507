/* ==========================================================================
Stacked and Splitted
========================================================================== */

/* Stacked
========================================================================== */
.icon-stacked
{
	position: relative;
	display: inline-block;
	vertical-align: middle;

	&.icon-marker,
	&.icon-marker-o {

		> .text-icon{
			font-size: .65em;
		}
	}

	> [class^='icon'] {
		position: absolute;
		top: -.015em;
		left: -.015em;
		width: 100%;
        font-size: 1.03em;
 	}

	> .text-icon {
		position: absolute;
		top: 0.12em;
		font-size: .8em;
		font-family: @anwb-font-family;
		width: 100%;
		text-align: center;
		font-style: normal;
	}

}

/* Stacked Within
========================================================================== */
.icon-stacked-within
{
    position: relative;
    display: inline-block;
    vertical-align: middle;

    > [class^='icon'] {
		position: absolute;
		top: .3em;
		left: 0;
		width: 100%;

		text-align: center;
		font-size: .6em;
    }
}

/* Stack Within Correction
========================================================================== */
.icon-stacked-within{
    &.icon-marker,
    &.icon-marker-o {

        > [class^='icon'] {
            top: .25em;
            left:.02em;
            font-size: .55em;
        }

    }
}

/* Split
========================================================================== */
.icon-split
{
    position: relative;
    display: inline-block;
    vertical-align: middle;

    [class^='icon'] {
        position: absolute;
        top: .1em;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: .4em;
    }

    [class^='icon'] + [class^='icon'] {
        top: 50%;
    }
}

/* Split Correction
========================================================================== */
.icon-split
{
    &.icon-marker,
    &.icon-marker-o {

        [class^='icon'] {
            top: .3em;
            font-size: .30em;
        }

        [class^='icon'] + [class^='icon'] {
            top: 1.3em;
        }
    }
}
