@import "_dropdown";

.@{anwb-prefix}menu {
	@block: ~"@{anwb-prefix}menu";

	&, ul& {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	@media (@breakpoint-s--menu) {
		position: absolute;
		top: 0;
		width: 100%;

		&__label {
			display: block;
			cursor: pointer;
			position: absolute;
			top: 5px;
			overflow: hidden;
			width: 44px;
			height: 34px;
			text-align: center;
			line-height: 2.3;
			border-radius: @border-radius-s @border-radius-s 0 0;
			right: @panel-grid-l;
			z-index: 10;

			&, a {
				color: transparent;
			}

			a {
				.hide();
			}

			&:before {
				.icon;
				content: @var-icon-menu;
				.icon-lg;
				color: @blue-dark;
				position: absolute;
				left: 0;
				padding: 8px 13px;
			}
		}

		&__controller:checked ~ &__label {
			background-color: @yellow;

			&:before {
				content: @var-icon-cross;
			}
		}

		&__submenu {
			padding: 0;
			margin: 0;
			list-style: none;
			position: fixed;
			top: 49px;
			background-color: @white;
			width: 100%;
			left: 100%;
			height: ~"calc(100% - 49px)";
			overflow: auto;
			overflow-x: hidden;
			//clip: rect(auto, auto, auto, auto);
			//backface-visibility: hidden;
			transition: left .3s ease-in;
			//transition-delay: .3s;

			& & {
				@top: 49px + 50px;
				top: @top;
				height: ~"calc(100% - @{top})";
			}
		}

		&__controller:checked ~ &__submenu {
			left: 0;
			z-index: 1;
		}

		&__item {
			border-bottom: @line solid @blue-grey-lighter;
			font-size: @font-size;

			a {
				display: block;
				text-decoration: none;
				color: @blue-dark;
				padding: .8em @panel-grid-l;

			}

			&--mobile {
				border-bottom: none;

				a {
					//height: auto !important;
					line-height: 1.2;
					//border: 0;
					font-size: .8em;
				}
			}
		}

		// animations for (back)label positions
		@keyframes move-label-up {
			from {
				position: static;
				top: auto;
				background-color: @white;
				color: @blue-dark;
				font-family: @default-fonts;
			}
			to {
				position: fixed;
				top: 49px;
				background-color: @blue-grey-lighter;
				color: @blue;
				font-family: @anwb-font-family;
			}
		}

		@keyframes move-label-down {
			from {
				position: fixed;
				top: 49px;
				background-color: @blue-grey-lighter;
				color: @blue;
				font-family: @anwb-font-family;
			}
			to {
				position: static;
				top: auto;
				background-color: @white;
				color: @blue-dark;
				font-family: @default-fonts;
			}
		}

		&__sublabel {
			display: block;
			width: 100%;
			cursor: pointer;

			animation: move-label-down 0s;
			animation-delay: 0s;
			animation-fill-mode: forwards;

			> a {
				pointer-events: none;
				color: inherit;

				&::after {
					float: right;
					.icon;
					content: @var-icon-chevron-right;
					font-size: @font-size-xs;
					color: @blue;
					display: inline-block;
					line-height: 2;
					font-weight: bold;
				}
			}
		}

		&__controller:checked ~ &__sublabel {

			animation: move-label-up .1s;
			animation-delay: .3s;
			animation-fill-mode: forwards;

			a {
				&::before {
					content: @var-icon-chevron-left;
					color: @blue-dark;
					margin-right: @rem-s;
				}

				&::after {
					display: none;
				}
			}
		}

		&--user {
			.@{block}__label {
				right: ~"calc(1 * 44px + @{panel-grid-l})";

				&::before {
					content: @var-icon-profile;
				}
			}
		}

		&--search {
			.@{block}__label {
				right: ~"calc(2 * 44px + @{panel-grid-l})";

				&::before {
					content: @var-icon-search;
				}
			}
		}

	}

	@media (@breakpoint-m--menu) {
		margin: 0;
		@media (@breakpoint-l) {
			margin: 0 @panel-grid-s;
		}

		&__label {
			display: none;
		}

		&__item--current {
			a {
				border-radius: @border-radius-s;
				background: hsla(0, 0%, 100%, 0.5);
			}
		}

		&__item--mobile {
			display: none;
		}


		& > &__submenu {
			//position: relative;
			list-style: none;
			z-index: 1;

			&--flexbox {
				display: flex;
				justify-content: flex-start;
				padding: ( @margin-text-m / 2 ) 0;
				margin: 0 0 0 0.7rem;
			}
		}

		&--search {
			.@{block}__submenu {
				padding: @rem-xs @rem-s;
			}
		}

		.@{anwb-prefix}dropdown {
			& > label a,
			& > a {
				font-size: @font-size-m;
				@media ( @breakpoint-l) {
					font-size: @font-size-l;
				}
			}
		}

	}
}

.@{anwb-prefix}menu-dropdown {
	@block: ~"@{anwb-prefix}menu-dropdown";

	list-style: none;
	margin: 0;
	padding: 0;

	@media (@breakpoint-s--menu) {
	}

	// when in bigger screens, it is shown as dropdowns
	// unfortunaly we have to copy the whole dropdown css into a this class
	@media (@breakpoint-m--menu) {
		._dropdown();

		.@{anwb-prefix}menu__item .@{anwb-prefix}menu__controller {
			pointer-events: none;
			background-color: coral;
		}
	}

}