.@{anwb-prefix}pane {
	@block: ~"@{anwb-prefix}pane";

	//** base styling */
	position: relative;

	// base default padding
	.spacing-panel();

	&--column {
		padding-top: 0;
		padding-bottom: 0;
	}

	&--row {
		padding-left: 0;
		padding-right: 0;
	}

	&--no-left {
		padding-left: 0;
	}

	&--no-right {
		padding-right: 0;
	}

	&--no-top {
		padding-top: 0;
	}

	&--no-bottom {
		padding-bottom: 0;
	}

	&--no-margin {
		padding: 0;
	}
}
