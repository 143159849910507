.font(@baseurl) {
  src: url('@{baseurl}.woff2') format('woff2'), url('@{baseurl}.woff') format('woff');
}

@font-face {
	font-family: @anwb-font;
	.font('fonts/ANWB-TheSans_7_');
	font-weight: bold;
}

@font-face {
	font-family: @anwb-font;
	.font('fonts/ANWB-TheSans_6i');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: @anwb-font;
	.font('fonts/ANWB-TheSans_7i');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: @ui-icons-font;
	.font('../anwb-icons/ui');
}

@font-face {
	font-family: @ui-fill-icons-font;
	.font('../anwb-icons/ui-fill');
}

@font-face {
	font-family: @illustrative-icons-font;
	.font('../anwb-icons/illustrative');
}
