/* Progress
 * - progress
 * - progress-default
 */


.progress {
	display: flex;
	flex-direction: row;
	margin-bottom: @rem-xs;
	width: 100%;
	flex-flow: nowrap;
	align-items: center;
	.progress-value {
		flex: 0 1 auto;
		margin-right: @rem-s;
	}

	.progress-bar {
		width: 100%;
		border-radius: 10px;
		height: 10px;
		flex: 1 1 auto;
		display: flex;
		align-items: stretch;

	}

	.progress-bar-inner {
		border-radius: 10px 0 0 10px;
		height: 10px;
	}
}

.progress-default {
	.progress-value {
		color: @blue;
	}
	.progress-bar {
		background: @blue-grey-lighter;
	}
	.progress-bar-inner {
		background: @blue;
	}
}
