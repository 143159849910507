//
// Panel FAQ
//--------------------------------------------------
.panel-faq {
	&:extend(._panel all);
	&:extend(._panel-collapse all);

	// TODO: Move to mixin from _import-core-less
	> input[type=checkbox] {
		top: inherit;
	}
	// END TODO

	padding-right: 0;
	padding-left: 0;
	&:not(:first-of-type) {
		padding-top: 0;
	}
	&:last-of-type {
		padding-bottom: 0;
	}

	// First element gets a top border
	&:first-of-type:before {
		content: "";
		display: block;
		margin-bottom: @rem;
		border-top: solid @line @blue-grey-light;
		margin-left: -@panel-grid-l;
		margin-right: -@panel-grid-l;

		@media (@breakpoint-m) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&:after {
		border-bottom-color: @blue-grey-light;
		margin-left: -@panel-grid-l;
		margin-right: -@panel-grid-l;

		@media (@breakpoint-m) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	h3 {
		color: @blue-dark;
		margin-bottom: 0;
		font-size: @font-size-m;
		.anwb-thesans-bold();
		
		> label {
			// IE flexbox "bugfix", makes sure the lines don't exceed box width
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				display: block;
			}
		}
	}

	~ p:last-of-type {
		margin-bottom: 0;
	}

	.panel-main {
		margin-bottom: 0;
		font-family: @default-fonts;
		font-size: @font-size-s;

		// IE flexbox "bugfix", makes sure the lines don't exceed box width
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			> p {
				display: block;
				max-width: 100%;
			}
		}
	}

	> input[type=checkbox]:checked + h3 {
		margin-bottom: @rem-N;
	}

	> input[type=checkbox]:focus + h3 > label,
	> input[type=checkbox] + h3 > label:after {
		color: @blue;
	}

	input[type=checkbox]:checked + h3 {
		color: @blue-dark;
	}
	input[type=checkbox]:hover + h3 {
		color: @blue;
	}
}