/**
 * Toggle tooltips
 */
.receipt {
	// show only when data-panels-compact on .receipt
	&:not([data-viewport-compact]) a[data-ui="tooltip"] {
		display: none;
	}

	//More lists than 1
	&[data-viewport-compact] {
		.panel-main {
			& > dl {
				position: relative;
				a[data-ui="tooltip"] {
					display: inline-block;
				}
			}
			dd:not(.catalogue) {
				display: none;
			}
		}
	}

}

/**
 * Base receipt styling
 */
.receipt {

	// extend panel-editorial
	&:extend(._panel all);

	.panel-body {
		&:extend(._panel-border all);
	}

	.panel-body > header {
		padding-bottom: @margin-text-s;
	}

	// receipt main/sub block
	dl {
		position: relative;
		// all lines with price
		dt{
			.hyphenate();
			color: @blue-dark;
			display: flex;
			flex-flow: row nowrap;
			.price {
				margin-left: auto;
				white-space: nowrap;
				padding-left: @rem-xs;
			}
		}
		.discount {
			color: @blue-dark;
			.price {
				color: @orange;
			}
			& + dd {
				display: block;
				margin:0;
			}
		}

		// line with price sub
		dt ~ dt:not(.discount) {
			font-size: @font-size-xs;
			color: @blue-grey-dark;
		}
		dd + dt,
		dt + dd {
			margin-top: @margin-text-s / 2;
		}
		// descritpion body text
		dd {
			display: list-item;
			color: @blue-grey-dark;
			margin-left: @heading-s;
			font-size: @font-size-xs;
		}
		.catalogue {
			display: block;
			margin-left: 0;
		}
	}
	h4 {
		display: block;
		width: 100%;
		min-width: 100%;
		font-size: @font-size-m;
		line-height: 0;
		span {
			float: right;
			color: @orange;
		}
	}

	.panel-main > dl > dt:first-of-type{
		font-size: @font-size-m;
		line-height: @line-height-s;
		.anwb-thesans-bold();
	}

	// section in receipt
	.panel-main > dl ~ dl {
		margin-top: @grid-unit-s;
		padding-top: @grid-unit-s;
		@media (@breakpoint-m) {
			margin-top: @grid-unit-m;
			padding-top: @grid-unit-m;
		}
		@media (@breakpoint-l) {
			margin-top: @rem-l;
			padding-top: @rem-l;
		}

		& > dt:first-child::before {
			content: ' ';
			position: absolute;
			top: 0;
			width: 100%;
			height:0;
			border-bottom: @line dotted @blue-grey;
		}
	}
	.panel-main > :last-child {
		margin-bottom: @rem-l;
		@media (@breakpoint-m) {
			margin-bottom: @grid-unit-m;
		}
		@media (@breakpoint-l) {
			margin-bottom: @rem-l;
		}
	}

}


/**
 * Footer receipt styling
 */
.receipt {
	// the footer of the receipt with the total amount
	footer {
		background-color: @blue-grey-light;
		dl {
			display: flex;
			//line-height: @line-height-s;
			line-height: @line-height-s;
			margin: @rem-xs 0 0;
			font-size: @font-size-s;
			&:first-of-type {
				.anwb-thesans-bold();
				color: @blue-dark;
				dt,
				dd{
					color: @blue-dark;
					font-size: @heading-s;
					@media (@breakpoint-l) {
						font-size: @heading-m;

					}
				}
			}

			// description
			dt {
				flex: 1;
				color: @blue-grey-dark;
			}
			//price
			dd {
				display: inline-block;
				text-align: right;
				line-height: @line-height-s;
				margin: 0;
				color: @blue-grey-dark;
			}
		}
		p{
			margin-top: @margin-text-m;
			margin-bottom: @margin-text-m;
			font-size: @font-size-xs;
			line-height: @line-height-s;
			color:inherit;
		}
		div:last-of-type {
			margin-top: @margin-text-m;
			border-top: @line solid @blue-grey;
		}
		button,[class|=btn] {
			width: 100%;
			margin-top: @margin-text-m;
		}
	}
}


/**
 * Base receipt styling
 */
.receipt {
	// the handle to open the fixed receipt
	// only visible on fixed
	#layout-fixed-toggle-main {
		display: none;
	}

}



// add .receipt in combination with data-layout-fixed
// to get the sticky receipt at the bottom of the page.
// it starts with collapsed version

@label-expand-button: 40px;

// media (@breakpoint-s) is not working!
// therefor this is done by hand
@media (max-width: @viewport-m) {

	html[data-mobile-popup],
	html[data-mobile-popup] > body {
		position: absolute;
		overflow: hidden !important;
		height:100vh !important;
		pointer-events: none;
		.receipt, .receipt * {
			pointer-events: all;
		}
	}
	html[data-mobile-popup] > body > main > a {
		display: none;
	}
	html[data-mobile-popup] > body > header {
		z-index: -1 !important;
	}

	.receipt[data-layout-fixed] {
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: visible;
		padding: 0 (@panel-grid-s * 2) 0;
		border-top: @line solid @blue-grey-light;
		background: @white;

		.panel-body, footer {
			margin: 0;
			padding: 0;
		}

		// hide everything but the total amount
		.panel-body{
			height:auto;
			position: relative;
			background: none;
			border: 0 none;
			header,
			.panel-main {
				display: none;
			}
		}

		// give the footer a negative margin so the backgroundcolor is full with
		footer{
			// is there a better way to reset the margins?
			//.header-img();
			margin: 0 (@panel-grid-s * -2);
			padding: @rem-s (@panel-grid-s*2);
			//width: calc( 100% + ( 0.7999999999999999rem * 2 )  );
			border-radius: 0;
			background-color: @white;
		}

		// the 'button' to expand and collapse the receipt
		#layout-fixed-toggle-main {
			position: absolute;
			right: @rem-l;
			top: -@label-expand-button; // the
			z-index: @z-index-popup;
			display: block;
			width: @label-expand-button;
			height: @label-expand-button;
			padding: 0;
			color: @blue;
			background: @white;
			border: @line solid @blue-grey-light;
			border-bottom: none;
			border-radius: @border-radius @border-radius 0 0;
			&:before{
				position: absolute;
				text-align: center;
				width: 100%;
				padding-top: 5px;
				content: '\ea97';
			}
		}

		//--------------------------------
		//--------------------------------
		//------- EXPANDED RECEIPT -------
		//--------------------------------
		//--------------------------------

		// change the chevron to up
		> input[type=checkbox]:checked ~ #layout-fixed-toggle-main{
			&:before{
				content: '\ea94';
			}
		}

		> input[type=checkbox]:checked ~ .panel-body {
			margin-top: @line;
			// show the main content of the receipt
			header,
			.panel-main {
				display: block;

			}
			header {
				padding-top: @offset-xs;

			}
			// set the receipt height
			.panel-main{
				max-height: ~'calc(90vh - 260px)';
				overflow-x: hidden;
				overflow-y: auto;
			}

			// change the footer color to blue
			footer{
				background-color: @blue-grey-light;
			}

			// place a background overlay
			&:before{
				position: absolute;
				top: calc(-100vh - @rem-s);
				left: (@panel-grid-s * -2);
				z-index: 1;
				width: 100vw;
				height: 100vh;
				background: fade(@blue-grey-darker, 80%);
				content:"";
				pointer-events: none;
			}
		}

	}
}


//More lists than 1
.receipt[data-panels-compact] {
	.panel-main {
		.panel-item {
			position: relative;
			a[data-ui="tooltip"] {
				display: inline-block;
			}
		}
		dd:not(.condition) {
			display: none;
		}
	}
}
