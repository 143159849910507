.@{anwb-prefix}structure {
	@block: ~"@{anwb-prefix}structure";

	//** private mixins **//

	.__asymmetric(@columnbase: 3, @target: 'children', @orientation: 'left') {
		@big: percentage(( ( @columnbase - 1 ) / @columnbase ));
		@small: percentage(( 1 / @columnbase ));

		& when (@target = 'self') {
			flex: 1 1 @big;
			max-width: @big;

			& when (@orientation = 'right') {
				order: 2;
			}

			&:first-child + * {
				flex-basis: @small;
				max-width: @small;

				& when (@orientation = 'right') {
					order: 1;
				}
			}
		}
		& when (@target = 'children') {
			> * {
				flex: 1 1 @big;
				max-width: @big;

				& when (@orientation = 'right') {
					order: 2;
				}
			}

			> *:first-child + * {
				flex-basis: @small;
				max-width: @small;

				& when (@orientation = 'right') {
					order: 1;
				}
			}
		}
	}

	.__pulled(@columnbase: 3, @orientation: right, @pull: 2) {
		@big: percentage(( ( @columnbase - 1 ) / @columnbase ));
		@small: percentage(( 1 / @columnbase ));

		.___rowpos_ie(@child) when (@child > 0) {
			.___rowpos_ie((@child - 1));    // next iteration
			&:nth-child(@{child}) {
				-ms-grid-row: @child;
			}
		}

		.__no_grid_support() {
			& {
				overflow: hidden;
				position: relative;

				> * {
					float: @orientation;
					width: @big;
				}

				> *:nth-child(@{pull}) {
					width: @small;
					position: absolute;
					top: 0;
					& when (@orientation = right) {
						float: left;
						left: 0;
						right: auto;
					}
					& when (@orientation = left) {
						float: right;
						right: 0;
						left: auto;
					}
				}
			}
		}

		.__grid_support() { // incl. -ms-grid
			& {
				display: grid;
			}

			& when (@orientation = right) {
				grid-template-columns: @small @big;
			}
			& when (@orientation = left) {
				grid-template-columns: @big @small;
			}

			> * {
				& when (@orientation = right) {
					grid-column: ~"2 / 3";
					-ms-grid-column: 2;
				}
				& when (@orientation = left) {
					grid-column: ~"1 / 2";
					-ms-grid-column: 1;
				}

				// IE and Edge cannot auto-flow...
				.___rowpos_ie(7); // max 7 items to keep the amount of css a bit in check...
			}

			> *:nth-child(@{pull}) {
				float: none;
				width: auto;
				& when (@orientation = right) {
					grid-column: ~"1 / 2";
					-ms-grid-column: 1;
				}
				& when (@orientation = left) {
					grid-column: ~"2 / 3";
					-ms-grid-column: 2;
				}
				grid-row: ~"1 / span 999";
				-ms-grid-row: 1;
				-ms-grid-row-span: 999;
			}
		}

		// IE10 / IE11: Grid support, but no @supports support
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			.__grid_support();
		}

		// No grid support, but @supports support
		@supports not (display: grid) {
			.__no_grid_support();
		}

		// Grid support and @supports support
		@supports (display: grid) {
			.__grid_support();
		}
	}

	.__symmetric(@columnbase: 3, @target: 'children') {
		& when (@target = 'children') {
			> * {
				flex: 0 0 percentage((1/@columnbase));
				max-width: percentage((1/@columnbase));
			}
		}
	}

	.__strechted(@columnbase: 4, @limit: 4) {
		> * {
			flex: 1 1 percentage((1/@columnbase));
		}

		& when (@limit > 0) {
			> [class*=panel]:nth-of-type(@{limit}) ~ * {
				display: none;
			}
		}
		& when (@columnbase < @limit) {
			flex-wrap: wrap;
		}
	}

	margin: 0 auto;
	max-width: 80.8em;

	margin-top: 2rem;

	padding: 0  (@grid-offset-s - (@grid-unit-s / 2) );
	@media (@breakpoint-m) {
		padding: 0 (@grid-offset-m - (@grid-unit-m / 2) );
	}
	@media (@breakpoint-l) {
		padding: 0 (@grid-offset-l - (@grid-unit-l / 2) );
	}

	// nested structures
	[class|="grid"] &:not(.@{block}--title), // FIXME: remove when old grid is gone...
	& .@{block}:not(.@{block}--title) {
		//margin: 0;
		padding: 0;

		&:first-child {
			margin-top: 0;
		}
	}
	& .@{block}:not(.@{block}--title) { // FIXME: combine with block above when old grid is gone
		margin: 0;
	}

	&--full {
		display: block;
	}

	&--title {
		> h1, > h2, > h3, > h4, > h5, > h6,
		> p, > article {
			margin: 0;
			.spacing-panel-sides();
		}

		+ .@{block} {
			margin-top: 0;// 0.4rem;
		}

		// nested
		[class|="grid"] &, // FIXME: remove when old grid is gone...
		.@{block} & {
			margin-left: 0;
			margin-right: 0;
			padding-left: 0;
			padding-right: 0;
		}
	}

	&--content,
	&--asymmetric {
		display: block;

		@media (@breakpoint-m) {
			display: flex;
			flex-flow: row;
			justify-content: flex-start;
			align-items: flex-start;

			.__asymmetric();
		}

		// Never allow more than two children.
		& > :nth-child(2) ~ * {
			display: none;
		}
	}

	&--asymmetric {
		@media (@breakpoint-g) {
			&[data-column-limit='4'] {
				.__asymmetric(4);

				&[data-column-reverse] {
					.__asymmetric(@columnbase: 4, @orientation: 'right');
				}
			}
		}

		@media (@breakpoint-m) {
			&[data-column-reverse] {
				.__asymmetric(@orientation: 'right');
			}
		}
	}

	// nested asymmetric structures

	&--content,
	&--asymmetric {
		[class|="grid"]:not(.grid-full) &, // FIXME: remove when old grid is gone...
		& & {
			@media (@breakpoint-m) {
				flex-wrap: wrap;

				> *,
				> *:first-child + * {
					flex: 1 1 100%;
					max-width: 100%;
				}
			}

			@media (@breakpoint-g) {
				.__asymmetric(@columnbase: 4, @target: 'self');

				&,
				&[data-column-limit] {
					.__asymmetric();
				}
			}
		}
	}

	&--pulled {
		display: block;

		@media (@breakpoint-m) {
			&[data-pulled-item="1"] {
				.__pulled(@pull: 1);

				&[data-pulled-out="right"] {
					.__pulled(@pull: 1, @orientation: left);
				}
			}

			&:not([data-pulled-item]),
			&[data-pulled-item="2"] {
				.__pulled();

				&[data-pulled-out="right"] {
					.__pulled(@orientation: left);
				}
			}

			&[data-pulled-item="3"] {
				.__pulled(@pull: 3);

				&[data-pulled-out="right"] {
					.__pulled(@pull: 3, @orientation: left);
				}
			}

			&[data-pulled-item="4"] {
				.__pulled(@pull: 4);

				&[data-pulled-out="right"] {
					.__pulled(@pull: 4, @orientation: left);
				}
			}

			&[data-pulled-item="5"] {
				.__pulled(@pull: 5);

				&[data-pulled-out="right"] {
					.__pulled(@pull: 5, @orientation: left);
				}
			}
		}

		@media (@breakpoint-g) {
			&[data-column-limit="4"] {
				&[data-pulled-item="1"] {
					.__pulled(@columnbase: 4, @pull: 1);

					&[data-pulled-out="right"] {
						.__pulled(@columnbase: 4, @pull: 1, @orientation: left);
					}
				}

				&:not([data-pulled-item]),
				&[data-pulled-item="2"] {
					.__pulled(@columnbase: 4);

					&[data-pulled-out="right"] {
						.__pulled(@columnbase: 4, @orientation: left);
					}
				}

				&[data-pulled-item="3"] {
					.__pulled(@columnbase: 4, @pull: 3);

					&[data-pulled-out="right"] {
						.__pulled(@columnbase: 4, @pull: 3, @orientation: left);
					}
				}

				&[data-pulled-item="4"] {
					.__pulled(@columnbase: 4, @pull: 4);

					&[data-pulled-out="right"] {
						.__pulled(@columnbase: 4, @pull: 4, @orientation: left);
					}
				}

				&[data-pulled-item="5"] {
					.__pulled(@columnbase: 4, @pull: 5);

					&[data-pulled-out="right"] {
						.__pulled(@columnbase: 4, @pull: 5, @orientation: left);
					}
				}
			}
		}
	}

	&--split {
		display: block;

		@media (@breakpoint-m) {
			display: flex;
			flex-flow: row;
			flex-wrap: wrap;
			justify-content: center;

			.__symmetric(2);

			// when nested, breaks at 1
			[class|="grid"]:not(.grid-full) &, // FIXME: remove when old grid is gone...
			.@{block}:not(.@{block}--full) & {
				.__symmetric(1);
			}
		}

		@media (@breakpoint-g) {
			&,
			// and nested
			[class|="grid"]:not(.grid-full) &, // FIXME: remove when old grid is gone...
			.@{block}:not(.@{block}--full) & {
				.__symmetric(2);
			}
		}
	}

	&--centered,
	&--wrapped {
		display: block;

		@media (@breakpoint-m) {
			display: flex;
			flex-flow: row;
			flex-wrap: wrap;
			justify-content: center;

			.__symmetric(2);

			// when nested, breaks at 1
			[class|="grid"]:not(.grid-full) &, // FIXME: remove when old grid is gone...
			.@{block}:not(.@{block}--full) & {
				.__symmetric(1);
			}
		}

		@media (@breakpoint-g) {
			.__symmetric(3);

			// when nested, breaks at 2
			[class|="grid"]:not(.grid-full) &, // FIXME: remove when old grid is gone...
			.@{block}:not(.@{block}--full) & {
				.__symmetric(2);
			}
		}

		@media (@breakpoint-l) {
			.@{block}[data-column-limit='4'] & {
				.__symmetric(3);
			}
		}
	}

	// wrapped can have defined columns
	&--wrapped {
		@media (@breakpoint-m) {
			&[data-column-align='left'] {
				justify-content: flex-start;
			}

			&[data-column-align='right'] {
				justify-content: flex-end;
			}
		}

		@media (@breakpoint-m) {
			&[data-column-limit='2'],
			&[data-column-limit='4'] {
				.__symmetric(2);
			}

			&[data-column-limit='6'] {
				.__symmetric(3);
			}
		}
		@media (@breakpoint-g) {
			&[data-column-limit='4'] {
				.__symmetric(3);
			}

			&[data-column-limit='6'] {
				.__symmetric(4);
			}
		}
		@media (@breakpoint-l) {
			&[data-column-limit='4'] {
				.__symmetric(4);
			}

			&[data-column-limit='6'] {
				.__symmetric(6);
			}
		}
	}

	&--overview,
	&--stacked {
		display: block;
		column-count: 1;
		column-gap: 0;
		column-fill: balance;

		@media (@breakpoint-m) {
			column-count: 2;

			// when nested, breaks at 1
			[class|="grid"]:not(.grid-full) &, // FIXME: remove when old grid is gone...
			.@{block}:not(.@{block}--full) & {
				column-count: 1;
			}
		}

		@media (@breakpoint-g) {
			column-count: 3;

			// when nested, breaks at 2
			[class|="grid"]:not(.grid-full) &, // FIXME: remove when old grid is gone...
			.@{block}:not(.@{block}--full) & {
				column-count: 2;
			}

			.@{block}[data-column-limit='4'] & {
				column-count: 3;
			}
		}

		& > * {
			break-inside: avoid;
		}
	}

	&--stacked {
		@media (@breakpoint-m) {
			&[data-column-limit='2'],
			&[data-column-limit='4'] {
				column-count: 2;
			}

			&[data-column-limit='6'] {
				column-count: 3;
			}
		}
		@media (@breakpoint-g) {
			&[data-column-limit='4'] {
				column-count: 3;
			}

			&[data-column-limit='6'] {
				column-count: 4;
			}
		}
		@media (@breakpoint-l) {
			&[data-column-limit='4'] {
				column-count: 4;
			}

			&[data-column-limit='6'] {
				column-count: 6;
			}
		}
	}

	&--balanced {
		display: block;
		@media (@breakpoint-m) {
			display: flex;
			flex-flow: row;
			justify-content: flex-start;

			// don't fill out, when aligned
			&[data-column-align] {
				> * {
					max-width: percentage((1/3));
				}
			}

			&[data-column-align="right"] {
				justify-content: flex-end;
			}

			&[data-column-limit='1'] {
				.__strechted(@columnbase: 1, @limit: 1);
			}
			&[data-column-limit='2'] {
				.__strechted(@columnbase: 2, @limit: 2);
			}
			&[data-column-limit='3'] {
				.__strechted(@columnbase: 3, @limit: 3);
			}
			&:not([data-column-limit]),
			&[data-column-limit='4'] {
				// 2 columns
				.__strechted(@columnbase: 2, @limit: 4);
			}

			// when nested, not more than 1 column and 2 items
			[class|="grid"]:not(.grid-full) &, // FIXME: remove when old grid is gone...
			.@{block}:not(.@{block}--full):not(.@{block}--embedded):not([data-column-limit='4']) & {
				.__strechted(@columnbase: 1, @limit: 2);
			}

			// when nested in a asymetric with 4 columns, not more than 1 column and 3 items
			.@{block}[data-column-limit='4'] & {
				.__strechted(@columnbase: 1, @limit: 3);
			}
		}

		@media (@breakpoint-g) {
			&:not([data-column-limit]),
			&[data-column-limit='4'] {
				.__strechted(@columnbase: 4, @limit: 4);
				// IE10 / IE11: the max-width of 33% overrides, even when 4 items, so set it to 25% always, no flexible spacing for them...
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					& > * {
						max-width: percentage((1/4));
					}
				}
			}

			// when nested, not more than 2 items
			[class|="grid"]:not(.grid-full), // FIXME: remove when old grid is gone...
			.@{block}:not(.@{block}--full):not(.@{block}--embedded):not([data-column-limit='4']) & {
				.__strechted(@columnbase: 2, @limit: 2);
			}

			// when nested in a asymetric with 4 columns, not more than 3 items
			.@{block}[data-column-limit='4'] & {
				.__strechted(@columnbase: 3, @limit: 3);
			}
		}
	}

	&--no-margin {
		margin-top: 0;
	}

	&--embedded {
		margin: -@panel-grid-s 0;

		@media (@breakpoint-m) {
			margin: -@panel-grid-m 0;
		}
	
		@media (@breakpoint-l) {
			margin: -@panel-grid-l 0;
		}
	}
}
