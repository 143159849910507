@blockanchor-width: 130px;
@blockanchor-height: 29px;

.blockanchor {
	position: relative;
	height: @rem-component-l;
	top: -0.01rem; // safari hack TODO: fix in core-less-imports
	@media (@breakpoint-m){
		height:(25px + @blockanchor-height);
	}

	> header {
		display: flex;
		flex-flow: column;

		> * {
			text-align: center;
			align-self: center;
		}
	}
}

.pagedivider {
	margin: @rem-s auto;
	&::after {
		content: " ";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: @blockanchor-height;
		background: transparent url("images/scroll-bar.svg") no-repeat center center;
		transform: rotate(180deg);
	}
	.blockanchor-link {
		background: none;
	}
}

.blockanchor-link {
	display: block;
	margin: 0 auto;
	padding: 0;
	width: @blockanchor-width;
	height: @blockanchor-height;
	border-radius: 0;
	background: transparent url('images/tab-lib.svg') no-repeat top center;
	background-size: @blockanchor-width auto;
	text-align: center;
	text-decoration: none;
	outline: none;

	&:before {
		.icon;
	    content: @var-icon-chevron-down;
		position: relative;
		top: 0;
		transition: all 0.1s ease-in-out;
	}
	&:hover:before {
		color: @blue-dark;
		top: @line;
	}
	&:focus:before {
		color: @blue-dark;
	}
	&:active:before {
		color: @blue;
	}
}