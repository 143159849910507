@var-icon-OV:'\eecb';
@var-icon-abs:'\ee79';
@var-icon-absorber:'\ee99';
@var-icon-acceleration:'\ee59';
@var-icon-account-overview:'\eee3';
@var-icon-adults:'\ee7e';
@var-icon-airbag:'\ee7c';
@var-icon-airco:'\ee96';
@var-icon-alarm-off:'\ee67';
@var-icon-alarm:'\eaeb';
@var-icon-arrow-left:'\ea7a';
@var-icon-arrow-right:'\ea7b';
@var-icon-ask-question:'\ee62';
@var-icon-baby:'\ee80';
@var-icon-banden:'\ee7a';
@var-icon-battery-distance:'\eeb7';
@var-icon-battery-range:'\eeb6';
@var-icon-battery:'\eeb8';
@var-icon-beach:'\eed3';
@var-icon-besturing:'\ee7b';
@var-icon-bike-low:'\eeba';
@var-icon-bike-storage:'\eed6';
@var-icon-bike:'\eebb';
@var-icon-bonfire:'\eeaa';
@var-icon-book:'\ee85';
@var-icon-brake:'\ee5a';
@var-icon-bus-tram:'\eed8';
@var-icon-bus:'\eed7';
@var-icon-calendar:'\ea83';
@var-icon-camera:'\eea3';
@var-icon-camper:'\eea1';
@var-icon-car-battery:'\ee5d';
@var-icon-car-crash:'\ee4a';
@var-icon-car-front:'\ee53';
@var-icon-car-info:'\ee4c';
@var-icon-car-trouble:'\ee49';
@var-icon-car:'\ee48';
@var-icon-caravan:'\eebe';
@var-icon-cart-2:'\eafe';
@var-icon-cart-add:'\eacf';
@var-icon-cart:'\ea7c';
@var-icon-charging-station-point:'\eeca';
@var-icon-charging-station:'\eea8';
@var-icon-chat-bubbles:'\ee70';
@var-icon-chat:'\eae2';
@var-icon-check-usp:'\ea93';
@var-icon-checkbox:'\eaf0';
@var-icon-chevron-down:'\ea94';
@var-icon-chevron-left:'\ea95';
@var-icon-chevron-right:'\ea96';
@var-icon-chevron-up:'\ea97';
@var-icon-circle-closed:'\ee8d';
@var-icon-circle-open:'\ee8c';
@var-icon-clock:'\eae6';
@var-icon-cloud:'\eaf8';
@var-icon-clutch:'\ee9c';
@var-icon-collision-damage:'\eebf';
@var-icon-commute-home-work:'\eede';
@var-icon-compass:'\ee6d';
@var-icon-construction:'\ee4d';
@var-icon-coolant-level:'\ee98';
@var-icon-copy:'\ee82';
@var-icon-cornering:'\ee5c';
@var-icon-correspondence:'\eec0';
@var-icon-crash:'\ee95';
@var-icon-cross:'\ea9a';
@var-icon-cruise:'\eea9';
@var-icon-damage-claims:'\eec1';
@var-icon-damage-to-others-their-car:'\eec2';
@var-icon-direction:'\eaed';
@var-icon-discounts:'\eec3';
@var-icon-document:'\ea9c';
@var-icon-dongel:'\ee5b';
@var-icon-driver-score:'\ee65';
@var-icon-edit:'\eebd';
@var-icon-electric-car:'\ee51';
@var-icon-engine:'\ee5e';
@var-icon-error:'\ead0';
@var-icon-euro:'\eafd';
@var-icon-exhaust:'\ee97';
@var-icon-external-link:'\ea81';
@var-icon-eye-off:'\ee75';
@var-icon-eye:'\eaf4';
@var-icon-favourite-add:'\ead1';
@var-icon-favourite:'\ead2';
@var-icon-female:'\eeac';
@var-icon-ferrry-boat:'\ee89';
@var-icon-filter:'\eaec';
@var-icon-filters:'\eee2';
@var-icon-fine:'\eedd';
@var-icon-flag:'\ee69';
@var-icon-flat-tire:'\ee5f';
@var-icon-flip-left:'\ee6f';
@var-icon-flitser:'\ee4b';
@var-icon-folding-trailer:'\eec4';
@var-icon-food-drink:'\eeab';
@var-icon-foot-print:'\ee88';
@var-icon-fullscreen-mode:'\eacd';
@var-icon-gas-euro:'\ee4e';
@var-icon-gas-wrong:'\ee50';
@var-icon-gasstation:'\ee4f';
@var-icon-headlights:'\ee94';
@var-icon-help:'\eae3';
@var-icon-history:'\eae7';
@var-icon-home:'\eaf5';
@var-icon-hotel-and-campings:'\ee8b';
@var-icon-hotel:'\ee9f';
@var-icon-ignition:'\ee9a';
@var-icon-improve:'\ee63';
@var-icon-information:'\eaa4';
@var-icon-injection:'\ee9b';
@var-icon-inline-fold:'\eeae';
@var-icon-inline-unfold:'\eaef';
@var-icon-insurance:'\ee83';
@var-icon-invoices:'\eec5';
@var-icon-kebap-menu:'\eea6';
@var-icon-kids:'\ee7f';
@var-icon-koeling:'\ee78';
@var-icon-last-update:'\eae8';
@var-icon-leaf:'\ee73';
@var-icon-ledenvoordeel:'\eafc';
@var-icon-live:'\eee5';
@var-icon-location:'\eae9';
@var-icon-lock:'\ead3';
@var-icon-mail:'\ea9f';
@var-icon-mailservice:'\eafa';
@var-icon-make-changes:'\ee64';
@var-icon-male:'\eead';
@var-icon-map:'\ee8f';
@var-icon-maximize:'\ee81';
@var-icon-meatballs:'\eea7';
@var-icon-member-card:'\eeaf';
@var-icon-menu:'\eabc';
@var-icon-message:'\eafb';
@var-icon-minimize:'\ee7d';
@var-icon-minus:'\eaf2';
@var-icon-my-ANWB:'\eec6';
@var-icon-my-insurances:'\eec7';
@var-icon-my-personal-data:'\eec8';
@var-icon-my-products:'\eec9';
@var-icon-nature:'\eed4';
@var-icon-new-modal:'\eea4';
@var-icon-new:'\eebc';
@var-icon-normal-mode:'\eacc';
@var-icon-oil:'\ee58';
@var-icon-online:'\ee9d';
@var-icon-other-transport:'\eedb';
@var-icon-parking-garage:'\eee0';
@var-icon-parking:'\ee54';
@var-icon-payments-invoices:'\eecc';
@var-icon-percentage:'\eea5';
@var-icon-personal-information:'\eed5';
@var-icon-pet:'\ee9e';
@var-icon-phone:'\eaad';
@var-icon-pin:'\eeb5';
@var-icon-pinpoint:'\ee61';
@var-icon-plane:'\ee76';
@var-icon-plus:'\eaf1';
@var-icon-podcast:'\eee4';
@var-icon-printer:'\eaf3';
@var-icon-profile:'\eac6';
@var-icon-purchase-damage-guarantee:'\eecd';
@var-icon-questions-information:'\eece';
@var-icon-radio-message:'\ee6b';
@var-icon-refresh:'\eace';
@var-icon-reorder:'\ee68';
@var-icon-roetfilter:'\ee77';
@var-icon-route:'\ee56';
@var-icon-sale:'\eeb2';
@var-icon-save:'\ee74';
@var-icon-scooter:'\eedc';
@var-icon-search:'\eab1';
@var-icon-security-camera:'\ee84';
@var-icon-settings:'\eae4';
@var-icon-share_ios:'\ead4';
@var-icon-shoe:'\eeb3';
@var-icon-shop-home:'\eea2';
@var-icon-shoppingbag:'\eaee';
@var-icon-shut-out:'\ee60';
@var-icon-sign-out:'\eae5';
@var-icon-size:'\ee8e';
@var-icon-smartphone:'\ee66';
@var-icon-smiley-happy:'\eada';
@var-icon-smiley-neutral:'\eadb';
@var-icon-smiley-not-so-happy:'\eadc';
@var-icon-smiley-unhappy:'\eadd';
@var-icon-smiley-very-happy:'\eade';
@var-icon-snow:'\eeb4';
@var-icon-social-apps:'\ea6a';
@var-icon-social-community:'\ea99';
@var-icon-social-facebook:'\ea63';
@var-icon-social-google:'\ea66';
@var-icon-social-instagram:'\ee90';
@var-icon-social-linkedin:'\ea69';
@var-icon-social-newsletter:'\ea98';
@var-icon-social-pinterest:'\ea6c';
@var-icon-social-twitter:'\ea70';
@var-icon-social-whatsapp:'\ee6a';
@var-icon-social-youtube:'\ea76';
@var-icon-sound-off:'\eab6';
@var-icon-sound-on:'\eab7';
@var-icon-spanner:'\ee86';
@var-icon-speed:'\ee57';
@var-icon-star-closed:'\eabb';
@var-icon-star-open:'\eaba';
@var-icon-steering-wheel:'\ee72';
@var-icon-subway:'\eeda';
@var-icon-success:'\ead5';
@var-icon-suitcase:'\eeb0';
@var-icon-sun:'\eaf9';
@var-icon-switch:'\ee87';
@var-icon-t-shirt:'\eeb1';
@var-icon-taxi:'\ee6e';
@var-icon-tent:'\ee6c';
@var-icon-theft:'\eecf';
@var-icon-thumb-down:'\eac1';
@var-icon-thumb-up:'\eac2';
@var-icon-tow-truck:'\eee1';
@var-icon-traffic:'\ee52';
@var-icon-train:'\ee8a';
@var-icon-tram:'\eed9';
@var-icon-trash:'\ead6';
@var-icon-travel-guides:'\eeb9';
@var-icon-unlock:'\eaea';
@var-icon-walking-person:'\eed0';
@var-icon-warning:'\eac8';
@var-icon-weather-damage:'\eed1';
@var-icon-wegenwacht:'\ee55';
@var-icon-wheelchair:'\eea0';
@var-icon-window-damage:'\eed2';
@var-icon-winter-tires:'\ee93';
@var-icon-wiper-fluid:'\ee92';
@var-icon-wipers:'\ee91';
@var-icon-work-from-home:'\eedf';
@var-icon-work:'\eaf7';
@var-icon-zoom-in:'\ead8';
@var-icon-zoom-out:'\ead9';