/**
 * Accessibility safe hiding
 */
.hide() {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}
.sr-only,
.hidden{
	.hide();
}

// HIDDEN

audio:not([controls]) {
	display: none;
	height: 0;
}

[hidden],
template {
	display: none;
}

svg:not(:root) {
	overflow: hidden;
}


// generic visual document states

// signed-in state - TODO: change Hippo FreeMarker template for menu!
html:not([signed-in]) [signed-in-only], html[signed-in] [not-signed-in-only] {display: none!important}
// TECHDEBT: remove next line once Hippo FreeMarker template for menu is updated!
html:not([signed-in]) .signed-on-only, html[signed-in] .not-signed-on-only {display: none!important}

// hide the google tagmanager iframe that is used when javascript is disabled
iframe[src*="googletagmanager.com"] {
	display: none;
}

//FIXME: Add 'data-async-app=pending' to the freemarker templates of eforms
.eforms form:not([data-async-app]),

[data-async-app=pending],
[data-async-styles*=pending],
[data-async-styles=""] {
	.hide();
}