//
// Panel Product Rating
//--------------------------------------------------
.panel-product-rating {
	&:extend(._panel all);
	width: 100%;
	& > .panel-body {
		&:extend(._panel-border all);
		width: 100%;
		header {
			// IE flexbox "bugfix", makes sure the lines don't exceed box width
			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				@media (@breakpoint-m) {
					> section:first-child {
						max-width: 255px;
						width: 70%;
					}
				}
			}
		}

		p {
			margin-bottom: 0;
		}

		.sticker-rating {
			margin: 0;
			@media (@breakpoint-m) {
				margin: 0;
			}
			@media (@breakpoint-l) {
				margin: 0;
			}

			~ .panel-main > p {
				margin-right: @sticker-s;

				@media (@breakpoint-m) {
					margin-right: @sticker-s/2;
				}

				@media (@breakpoint-l) {
					margin-right: @sticker-s;
				}
			}

		}

		// Tablet smaller rating in accord with UX due to on width of e.g. 650px, rating would fall outside panel
		// TODO: Keep an eye on other elements where rating will be used, needs the same fix or its just for this element?
		@media screen and (min-width: @viewport-m) and (max-width: @viewport-l) {
			.sticker-rating {
				margin-left: 0;
				height: 40px;
				width: 40px;

				> .sticker-body > * {
					font-size: @font-size-l;
				}
			}
		}

		.link-external {
			display: block;
			margin-top: @rem-xs;
		}

	}

}

// Rating
// --------------------------------------------------
.rating {
	margin-top: @rem-s;
	margin-bottom: @rem-xs;
	color: @yellow;
	font-size: @font-size-m;
	letter-spacing: 0.8px;
	display: table; // makes the element the parent for determining the percentage of the child
	&:before {
		content: "@{var-icon-star-open}@{var-icon-star-open}@{var-icon-star-open}@{var-icon-star-open}@{var-icon-star-open}";
	}
	> span {
		display: block;
		margin-top: -24px;
		overflow: hidden;
		position: relative;
		&:before {
			content: "@{var-icon-star-closed}@{var-icon-star-closed}@{var-icon-star-closed}@{var-icon-star-closed}@{var-icon-star-closed}";
			white-space: nowrap;
		}
		em {
			display: none;
		}
	}
}

// Rating input
// --------------------------------------------------
.rating-input {
	//display: inline-flex;
	//flex-direction: row-reverse;
	margin-top: 0;

	// Hide radio button
	input[type=radio] {
		position: absolute;
		left: -9999px;
	}

	// Default label icon
	label:before {
		.icon;
		color: @yellow;
		.icon-md;
		content: @var-icon-star-open;
		transition: all .2s;
	}

	//All labels after the checked get a new icon
	input[type=radio]:checked ~ label:before {
		content: @var-icon-star-closed;
	}

	// Hover effects
	label:hover ~ label:before,
	input[type=radio] ~ label:hover:before {
		content: @var-icon-star-closed;
		color: @blue;
	}

	// disabled
	input[type=radio]:disabled + label:hover ~ label:before,
	input[type=radio]:disabled ~ label:hover:before {
		content: @var-icon-star-open;
		color: @yellow;
		cursor: default;
	}
}

