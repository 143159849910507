
.panel-counter {
	&:extend(._panel all);

	.panel-body {
		&:extend(._panel-border all);
	}

	.panel-main {
		> p {
			font-size: @font-size-s;
		}
	}
}

// Counter
// Could me a agnostic element
@breakpoint-counter-m: ~"min-width: 53rem";

// Base panel overwrites this value via a LAST-CHILD.
// that's why we need to set it more specific
[class^='panel'] .counter {
	margin-top: @rem-xs;
	//margin-bottom: @rem-s;
}

.counter {
	&:extend(._panel-title-h3 all);
	display: flex;
	flex-wrap: nowrap;
	width: 100%;

	> span {
		flex: 1;
		background: @blue-grey-lighter;
		border-radius: @border-radius-s;
		font-size: @heading-xxl;
		padding: @line 0;
		margin-right: @line;
		min-width: @grid-offset-m;
		max-width: @grid-offset-l;
		text-align: center;

		@media (@breakpoint-m) {
			font-size: @heading-l;
			min-width: @grid-unit-m;
			max-width: @grid-unit-l;
		}

		@media (@breakpoint-counter-m) {
			font-size: @heading-xl;
			min-width: @grid-offset-m;
		}

		@media (@breakpoint-l) {
			font-size: @heading-xxl;
			max-width: @grid-offset-l;
		}
	}
}