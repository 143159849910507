/* === showcase === */
// --------------------------

/* === Base === */
// showcase
// - showcase-vision
// - showcase-content

// showcase-vision
// - panel

/* === optional elements / components === */
// Sticker: added sticker inside a showcase panel

.showcase > div > img {
	border-radius: 0;
}

.showcase {
	opacity: 1!important; // prevent flickering caused by stupid BlueConic script
	position: relative;
	width: 100%;
	margin-top: 0;
	border-top: 0;
	border-bottom: solid transparent @rem-component-s;
	padding: 0;

	@media (@breakpoint-m--menu) {
		border-bottom: solid transparent @rem-component-m;
	}

	@media (@breakpoint-l) {
		border-bottom: solid transparent @rem-component-m;
	}

	.showcase-media {
		max-height: 60vh;
		overflow: hidden;
		display: flex;
		background-size: cover;
		background-position: center;
		position: relative;

		@media (@breakpoint-m--menu) {
			min-height: 420px;
			max-height: 60vh;
		}

		> img {
			visibility: hidden;
			align-self: center;
		}
	}

	.showcase-media:before {
		position: absolute;
		display: block;
		content: "";
		bottom: 0px;
		width: 100%;
		height: 100px;
		background: @gradient-blue-transparent;

		@media (@breakpoint-m--menu) {
			height: 140px;
		}
	}

	.showcase-content {
		//z-index: 2;
		width: 100%;
		position: relative;
		margin-top: -(@rem-xl * 2);
		padding:0 @grid-unit-s;

		@media (@breakpoint-m) {
			padding: 0 (@grid-offset-l - (@grid-unit-s / 2) );
			max-width: @viewport-l-max;
		}

		@media (@breakpoint-m--menu) {
			padding: 0 (@grid-offset-m - (@grid-unit-m/2) );
			margin-top: -9rem;
			max-width: @viewport-l-max;
			left:-(@grid-offset-l * 2.05); // this just works :)
		}

		@media (@breakpoint-l) {
			padding: 0 (@grid-offset-l - (@grid-unit-l / 2) );
			left:-(@grid-offset-l * 2);
		}
	}

	@media (@breakpoint-m--menu) {
		.showcase-content-container {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			display: flex;
		}
	}
}

.showcase-content {
	@media (@breakpoint-m--menu) {
		align-self: center;
		margin: 0 auto;
	}

	> .panel,
	> .panel-basic {
		&:extend(._panel all);
		&:extend(._panel-border all);
		border-radius:(@border-radius * 1.5); // prevent background leaking
		position: relative;
		background-color: @white;

		& > .panel-body {
			padding:0;
		}

		.panel-main > ul.list-checklist {
			padding-left: 0;
			line-height: @line-height-s;

			li {
				margin-bottom: @rem-xs;
				font-style: italic;
				text-align: left;
			}
		}

		@media (@breakpoint-m--menu) {
			display: flex;
			justify-content: center;
			box-shadow: none;

			background: none;
			background-image: url('images/speechbubble.svg');

			background-repeat: no-repeat;
			background-position: center center;
			border:0;
			overflow: visible;
			padding: 6rem 4rem;
			margin-bottom: -9rem;

			width: 26rem;
			background-size: contain;
			min-height:~'calc(26rem + 6rem)';
			height: 0;
			&::before {
				content: none;
			}

			.panel-body {
				position: relative;

				left: @grid-unit-s;
				padding: @grid-unit-s;

				> h2 {
					margin: 0 0 ( @interline / 3 );
					font-weight: normal;
					font-size: @heading-l;
				}
				> ul.list-checklist {
					> li {
						line-height: @line-height-s;
					}
				}

			}
		}
	}
}


// Stickers
// We need a different position and different sizing depending on the viewport
.showcase-content .panel-body > [class*='sticker-'] {
	//position: absolute;
	//right:0px;
	//top: -@offset-s;

	@media (@breakpoint-m--menu) {
		position: absolute;
		top: -45px;
		right: -15px;
		width: 85px;
		height: 85px;

		> .sticker-body {

			> h2 { font-size: unit(( 20 / @target-px-size ), em); }

			> h4 { font-size: unit(( 13 / @target-px-size ), em); }
		}
	}

}

// Prevent panel-body to be hidden BELOW the sticker.
.showcase-content [class^='sticker'] + .panel-main > :first-child{
	margin-right: ~'calc(@{sticker-m} - @{grid-unit-s})';

	@media (@breakpoint-m) {
		margin-right:0;
	}
}

/* header on 'half' height of default header: ONTLW-6134 */
.half-header {
    .showcase .showcase-media {
        max-height: 15vh;
        min-height: 10vh;
    }

    .showcase .showcase-content {
        margin-bottom: 0;
        margin-top: -1.1rem;
    }
    
    .quick-to-spacer {
        margin-top: -1.2rem;
    }
    
    @media (@breakpoint-m) {
        .quick-to-spacer {
            margin-top: -3.2rem;
        }

        .showcase .showcase-content {
            margin-top: -1.6rem;
        }

        .showcase-content>.panel, .showcase-content>.panel-basic { 
            box-shadow: none;
            background: #fff;
            background-image: none;
            border: 2px solid @blue-grey-light;
            overflow: visible;
            padding: 1.2rem;
            width: 66.666666666%;
            max-width: 36rem;
            margin-bottom: 0;
            background-size: contain;
            min-height: 0;
            height: auto;
            left: 0;
        }
    }

    @media (@breakpoint-m--menu) {
        .quick-to-spacer {
            margin-top: -5rem;
        }
    
        .showcase .showcase-media {
            min-height: 280px;
            max-height: 30vh;
        }
    
        .showcase .showcase-content {
            left: 0;
            bottom: -3.6rem;
            margin-top: 0;
        }
    
        .showcase-content .panel-body>[class*='sticker-'] {
            top: -4px;
            right: 8px;
        }
    }
    
    @media (@breakpoint-l) {
        .quick-to-spacer {
            margin-top: -6rem;
        }
    }
}