//
// Panel List Group
//--------------------------------------------------

.panel-list-group {
	._panel-list;
	& > .panel-body {
		&:extend(._panel-border all);

		h3 { border-bottom: solid @line @blue-grey-light; }

		ul > li {
			border-bottom: solid @line @blue-grey-light;
			&:last-child { border: none; }
		}

	}
}

.numbered {
	counter-reset: topX;

	li:before {
		._panel-padding();
		padding-top: 0;
		padding-bottom: 0;
		display: table-cell;
		.anwb-thesans-bold();
		font-size: @heading-l;
		counter-increment: topX;
		content: counter(topX);
		vertical-align: middle;

		@media (@breakpoint-m) {
			padding-top: 0;
			padding-bottom: 0;
		}
		@media (@breakpoint-l) {
			padding-top: 0;
			padding-bottom: 0;
		}

	}

	li a {
		// TODO: please fix me it's important :-|
		padding-left: 0 !important;
	}
}