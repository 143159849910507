@media (@breakpoint-m--menu) {

	.@{anwb-prefix}navigation__search {

		// Fantastically horrible Firefox hack!
		// Looks like we're not done with buggy browsers yet!
		//@-moz-document url-prefix() {
		//	& > ul {
		//		@media ( @breakpoint-m) {
		//			margin-top: -0.2rem;
		//		}
		//		@media ( @breakpoint-l) {
		//			margin-top: 0rem;
		//		}
		//	}
		//}

		.@{anwb-prefix}menu-dropdown {
			&__link {
				font-size: @font-size-m;
				@media ( @breakpoint-l) {
					font-size: @font-size-l;
				}

				&:after {
					.icon;
					content: @var-icon-search;
					display: inline-block;
					font-size: @font-size-m;
					position: relative;
					margin-left: @rem-s;
					top: -1px;
				}
			}

			&__menu {
				@media (@breakpoint-m--menu) {
					min-width: 35rem;
				}
			}
		}
	}

}

