/*
	StickyMenu

	- main
	- stickymenu-panel
	- Polyfill CSS
*/

// Stickymenu
.stickymenuPlaceholder {
	width: 100%;
	display: none;

	&-visible {
		display: block;
	}
}

.stickymenu {
	border: @line solid @blue-grey;
	border-style: solid none;
	background-color: @blue-grey-lighter;
	position: relative;

	@media (@breakpoint-m) {
		background-color: white;
		box-shadow: 0 5px 10px 5px rgba(232,242,248,0.5);
	}
}

// Panel-main
.stickymenu-panel {

	&:extend(._panel-collapse all);

	&:after { display: none; }

	> .panel-main { margin-bottom: 0; }

	/* overwrite default behavior (panel-collapse) and display panel-main on medium screen */

	.stickymenu-more > a {
		display: none;

		@media (@breakpoint-m) {
			display: block;
		}
	}
}

.stickymenu-list li {
	padding: 0;
	margin-bottom: 0;
	margin-top: 0;
	@media (@breakpoint-s) {
		margin: 0;
		&:not(#ctaBtn) {
			border-top: @line solid @blue-grey;
		}
		& #moreItems {
			margin-top: -@line;
		}
	}
}

// list
.stickymenu-list,
.stickymenu-list > li > nav > ul,
.stickymenu-list > li > ul { &:extend(._list-unstyled all); }

// list item
.stickymenu-list li {
	align-self: center;
	margin: 0;
}

.sticky-active main :not(.stickymenu) {
	display: none;
}

// Medium Screen
.stickymenu-list {

	@media (@breakpoint-m) {
		// list
		display: flex;
		flex-flow: wrap;
		._parent-reset;
		padding-right: @offset-l;
		padding-left: @offset-l;

		// list item
		> li,
		> li > ul > li,
		> li > nav > ul > li {
			flex: 0 1 auto;
		}

		& li > a {
			font-weight: bold;

			&:hover {
				color: @blue;
			}
		;
		}

		// list item aside
		.stickymenu-list-aside {
			margin-left: auto;
			align-content: flex-end;

			+ .stickymenu-list-aside {
				margin-left: 0;

				a {
					font-size: @font-size-l;
				}
			}
		}
	}
	@media (@breakpoint-l) {
		padding-right: @offset-l + @rem-xs;
		padding-left: @offset-l + @rem-xs;
	}
}

// Submenu
.stickymenu-more {

	> label {
		padding-right: 0;
		&:after {
			.icon;
			content: @var-icon-chevron-down;
			padding-left: @rem-N;
			color: @blue;
		}
		@media (@breakpoint-s) {
			display: none!important;
		}
	}
	&:hover > label:after {
		content: @var-icon-chevron-up;
	}

	@media (@breakpoint-m) {

		> nav {
			// The before pseudo-element is used to make sure we won't lose
			// the hover state when moving the mouse cursor downwards.
			&:before {
				content: '';
				border: 1px solid white;
				display: block;
				margin-top: -2px;
			}
			position: absolute;
			background-color: @white;
			border: @line solid @blue-grey;
			border-top: 0;
			border-bottom-left-radius: @border-radius;
			border-bottom-right-radius: @border-radius;
			box-shadow: 0 5px 10px 0 fadeout(@blue-grey-lighter, 50%);
			margin-top: 2px;
			margin-left: -14px;
			z-index: @z-index-sticky + 1;
			transition: opacity 0.2s linear;
			visibility: hidden;
			opacity: 0;
			> ul > li {
				> a {
					padding-top: 0;
					padding-bottom: @rem-s;
					padding-right: calc(@rem - ((@rem-xs / 5) * 2));
					padding-left: calc(@rem - ((@rem-xs / 5) * 2));
				}

				&:first-child > a { padding-top: @rem-s; }

				&:last-child > a { padding-bottom: @rem-l; }
			}
		}
		&:hover > nav {
			visibility: visible;
			opacity: 1;
		}
	}
}

// Typography
.stickymenu-panel {

	h3 {
		display: inherit;
		margin-bottom: 0;
		padding: @grid-unit-s;
		font-size: @rem;
	}

	a, .stickymenu-more > label {
		cursor: pointer;
		display: inline-block;
		padding: @margin-text-m;
		padding: @grid-unit-s @rem;
		width: 100%;
		text-decoration: none;
		white-space: nowrap;
		color: @blue-dark;
		&:hover { color: @blue; }
		&.active,
		&:active { color: @orange; }
	}

	#ctaBtn > a {
		color: @white;
		&:after {
			content:@var-icon-arrow-right;
		}
		max-width: 400px;
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.2s linear;

		/* TODO: btn_condensed can not be used as a mixin :-|
        that's why we have this padding-top|bottom thingy below.
        */
		padding-top: (unit( ( 15 / @target-px-size ), rem ) / 2);
		padding-bottom: (unit( ( 15 / @target-px-size ), rem ) / 2);

		@media (@breakpoint-s) {
			position: absolute;
			top: 4px;
			right: @margin-text-s * 2;
			max-width: 200px;
		}
	}

	// Medium Screen
	@media (@breakpoint-m) {

		h3 { display: none; }

	}
}

@media (@breakpoint-s) {
	.stickymenu {
		.panel-main {
			height: 0;
			overflow: hidden;
		}
		.stickymenu-panel > h3 {
			> label:after {
				position: static;
				line-height: unset;
				color: @blue;
				content: @var-icon-chevron-down;
			}
		}
	}
	html.stickymenu-open {
		.stickymenu {
			top: 0;
			left: 0;
			right: 0;
			.panel-main {
				height: auto;
				overflow: visible;
			}
			.stickymenu-panel > h3 > label:after {
				content: @var-icon-chevron-up;
			}
		}
	}
}

.stickymenu[data-show-cta] #ctaBtn > a {
	opacity: 1;
	visibility: visible;
}

.stickymenu[data-title]:empty {
	.hide();
}

html > .stickymenu {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 697;
}
