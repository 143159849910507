.@{anwb-prefix}gallery {
	@block: ~"@{anwb-prefix}gallery";

	.__indicatorareas(@counter) when (@counter > 0) {
		.__indicatorareas((@counter - 1));    // next iteration

		.@{block}__controller:nth-of-type(@{counter}),
		.@{block}__indicator:nth-of-type(@{counter}) {
			grid-area: ~"indicator@{counter}";
			-ms-grid-row: 2;
			-ms-grid-column: 1 + @counter;
		}
	}

	.__templateareas(@slides) {
		// internal mixins
		.___horizontal(@slides: 1, @row: 'indicators') when (@slides > 0) {
			.___horizontal(@slides: (@slides - 1), @row: @row);    // next iteration

			& when (@row = 'indicators') {
				grid-template-areas+_: ~"indicator@{slides}";
			}
			& when (@row = 'slides') {
				grid-template-areas+_: ~"slide";
			}
		}

		// "slide slide      slide      slide      slide      slide      slide"
		// ".     indicator1 indicator2 indicator3 indicator4 indicator5     .";
		grid-template-areas+_: ~"' slide";
		.___horizontal(@slides: @slides, @row: 'slides');
		grid-template-areas+_: ~"slide '";

		grid-template-areas+_: ~"' .";
		.___horizontal(@slides: @slides, @row: 'indicators');
		grid-template-areas+_: ~". '";
	}

	@numslides: 12;
	@slidespeed: .5s;

	position: relative;
	margin-bottom: @rem-s;

	//@supports (display: grid) { // ie11 does not support supports, but does support some css grid
		& {
			display: grid;
			justify-content: stretch;
			align-items: stretch;
			//grid-template-areas:
			//		"slide slide      slide      slide      slide      slide      slide"
			//		".     indicator1 indicator2 indicator3 indicator4 indicator5     .";
			.__templateareas(@slides: @numslides);
			grid-template-columns: 1fr repeat(@numslides, auto) 1fr;
			grid-template-rows: auto;
		}

		.__indicatorareas(@numslides);

		&__slide {
			grid-area: slide;
			-ms-grid-row: 1;
			-ms-grid-column: 1;
			-ms-grid-column-span: @numslides + 2;

			align-self: center;
			overflow: hidden;
			border-radius: @border-radius;
			margin: 0;

			// hide (non current) slides for browsers that not support grid (also see lines further on)
			display: none;

			img {
				width: 100%;
				margin: 0;
				margin-left: -100%;
				transition: margin-left @slidespeed;
				border-radius: 0;
			}
		}

		// unhide sliders on browsers that support grids
		@supports (display: grid) {
			&__slide {
				display: block;
			}
		}
		// and IE11
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			&__slide {
				display: block;
			}
		}

		&__controller:checked ~ &__slide {
			img {
				margin-left: 100%;
			}
		}

		// and the current one...
		&__controller:checked + &__slide {
			display: block;

			img {
				margin-left: 0;
			}
		}

		&__sliders {
			grid-area: slide;
			-ms-grid-row: 1;
			-ms-grid-column: 1;
			-ms-grid-column-span: @numslides + 2;

			display: none;

			position: absolute;
			width: 100%;
			height: 40px;
			line-height: 34px;
			top: ~"calc(50% - 20px)";
			pointer-events: none;

			::selection {
				background: transparent;
			}
		}

		&__controller:checked + &__slide + &__sliders {
			display: block;
		}

		&__prev,
		&__next {
			display: block;
			position: absolute;
			width: 40px;
			height: 100%;
			text-align: center;
			background-color: @white;
			border: @line solid @blue-grey;
			border-radius: 20px;
			color: @blue;
			cursor: pointer;
			pointer-events: auto;
			font-size: .8rem;

			&:hover {
				border-color: darken(@blue-grey, 25%);
			}
		}

		&__prev {
			left: 15px;

			@media (@breakpoint-m) {
				left: 25px;
			}
		}

		&__next {
			right: 15px;

			@media (@breakpoint-m) {
				right: 25px;
			}
		}

		&__sliders:first-of-type &__prev {
			display: none;
		}

		&__sliders:last-of-type &__next {
			display: none;
		}

		&__indicator {
			position: relative;
			bottom: 30px;
			display: block;
			background-color: @white;
			font-size: 0;
			height: 30px;
			margin-bottom: -30px;
			padding: 10px 5px;
			cursor: pointer;

			// hide indicators on browsers that not support grid (also see lines further on)
			visibility: hidden;

			& > span {
				display: inline-block;
				padding: 0;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				background-color: @blue-grey-light;
			}

			&:hover > span {
				background-color: @blue;
			}

			&:first-of-type {
				border-top-left-radius: 8px;
				margin-right: -1px;
				padding-left: 10px;

				&::before {
					position: absolute;
					bottom: -1px;
					width: 10px;
					height: 10px;
					content: " ";
					left: -9px;
					border: 1px solid @white;
					border-bottom-right-radius: 12px;
					border-width: 0 1px 1px 0;
					box-shadow: 2px 2px 0 @white;
				}
			}

			&:last-of-type {
				border-top-right-radius: 8px;
				margin-left: -1px;
				padding-right: 10px;

				&::before {
					position: absolute;
					bottom: -1px;
					width: 10px;
					height: 10px;
					content: " ";
					right: -9px;
					border: 1px solid @white;
					border-bottom-left-radius: 12px;
					border-width: 0 0 1px 1px;
					box-shadow: -2px 2px 0 @white;
				}
			}
		}

		// unhide indicators on browsers that support grids
		@supports (display: grid) {
			&__indicator {
				visibility: visible;
			}
		}
		// and IE11
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			&__indicator {
				visibility: visible;
			}
		}

		&__controller:checked + &__slide + &__sliders + &__indicator {
			& > span {
				background-color: @orange;
			}
		}

		&__title {
			grid-row-start: 2;
			grid-column-start: 1;
			grid-row-end: 3;
			grid-column-end: @numslides + 3;
			-ms-grid-row: 3;
			-ms-grid-column: 1;
			-ms-grid-column-span: @numslides + 2;

			display: none;

			padding-top: @rem-s;
			margin: 0;
		}

		&__controller:checked + &__slide + &__sliders + &__indicator + &__title {
			display: block;
		}

		&__indicator {
			@manyslides: @numslides+1;
			z-index: 1;

			&:nth-of-type(1n + @{numslides}) {
				border-top-right-radius: 8px;
				padding-right: 10px;

				&::before {
					position: absolute;
					bottom: -1px;
					width: 10px;
					height: 10px;
					content: " ";
					right: -9px;
					border: 1px solid @white;
					border-bottom-left-radius: 12px;
					border-width: 0 0 1px 1px;
					box-shadow: -2px 2px 0 @white;
				}

				& ~ .@{block}__indicator {
					display: none;
				}
			}

			&:nth-of-type(1n + @{numslides}):not(:last-of-type) {
				padding-left: 3px;
				padding-right: 8px;

				& > span {
					width: 14px;
				}
			}

			.@{block}__controller:checked + .@{block}__slide + .@{block}__sliders + & {
				display: block;
			}

			&:nth-of-type(1n + @{manyslides}) {
				grid-area: ~"indicator@{numslides}";
				-ms-grid-row: 2;
				-ms-grid-column: 1 + @numslides;

				padding-left: 3px;
				padding-right: 8px;

				& > span {
					width: 14px;
				}
			}
		}

	&__youtube-link {
		display: block;
		padding-bottom: 56.25%;
		position: relative;
		text-decoration: none;
		z-index: -1;

		&::after {
			border-radius: 10px;
			content: '\25B6';
			color: @white;
			font-size: 1.6rem;
			margin-left: -45%;
			position: absolute;
			top: ~"calc(50% - 20px)";
			transition: margin-left @slidespeed;
			.button-variant(@white; @blue-dark; @gradient-blue-dark; 0; none; @white; @gradient-lighter-blue-dark; @gradient-blue-btn-active; @white;);
			padding: 4px 14px 1px 16px;
		}

		// MS Edge
		@supports (-ms-ime-align:auto) {
			&::after {
				font-family: "Segoe UI Symbol"; // fix for MS Edge showing blue square
				padding: 2px 14px;
			}
		}

		// IE11
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			&::after {
				font: 1.6rem "Segoe UI Symbol";
				padding: 5px 14px;
				top: ~"calc(50% - 5px)";
			}
		}

		img {
			object-fit: cover;
			position: absolute;
			height: 100%;
			width: 100%;
		}
	}

	&__controller:checked ~ &__slide &__youtube-link::after {
		margin-left: 145%;
	}

	&__controller:checked + &__slide &__youtube-link {
		z-index: 0;

		&::after {
			margin-left: 45%;
		}
	}
}
