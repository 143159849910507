/* ==========================================================================
   Icon definition
   ========================================================================== */

@import (reference) "../variables/icon-sizes";

.icon{
  display: inline;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align:middle;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-transform: rotate(-0.001deg);
}

.icon-xs{
  &:extend(.icon);
  .size(@icon-size-xs);
  .font-size(@icon-size-xs);
}

.icon-sm{
  &:extend(.icon);
  .size(@icon-size-sm);
  .font-size(@icon-size-sm);
}
.icon-md{
  &:extend(.icon);
  .size(@icon-size-md);
  .font-size(@icon-size-md);
}
.icon-lg{
  &:extend(.icon);
  .size(@icon-size-lg);
  .font-size(@icon-size-lg);
}
.icon-xl{
  &:extend(.icon);
  .size(@icon-size-xl);
  .font-size(@icon-size-xl);
}
.icon-xxl{
  &:extend(.icon);
  .size(@icon-size-xxl);
  .font-size(@icon-size-xxl);
}
.icon-xxxl{
  &:extend(.icon);
  .size(@icon-size-xxxl);
  .font-size(@icon-size-xxxl);
}
.icon-jumbo{
  &:extend(.icon);
  .size(@icon-size-jumbo);
  .font-size(@icon-size-jumbo);
}

.icon-jumbo-xl{
  &:extend(.icon);
  .size(@icon-size-jumbo-xl);
  .font-size(@icon-size-jumbo-xl);
}
.icon-jumbo-xxl{
  &:extend(.icon);
  .size(@icon-size-jumbo-xxl);
  .font-size(@icon-size-jumbo-xxl);
}
.icon-jumbo-xxxl{
  &:extend(.icon);
  .size(@icon-size-jumbo-xxxl);
  .font-size(@icon-size-jumbo-xxxl);
}
.icon-mega{
  &:extend(.icon);
  .size(@icon-size-mega);
  .font-size(@icon-size-mega);
}


.size(@square) {
  @unit: 'rem';
  .process(@square) when (ispixel(@square)), (isem(@square)), (ispercentage(@square)), (iskeyword(@square)) {
    width: @square;
    height: @square;
  }

  .process(@square) when not (ispixel(@square)) and not (isem(@square)) and not (ispercentage(@square)) and not (isstring(@square)) and not (iskeyword(@square)) {
    @remSize: (@square / 16);
    width: ~`@{remSize} + @{unit}`;
    height: ~`@{remSize} + @{unit}`;
  }

  .process(@square);

}

.size(@width, @height) {
  @unit: 'px';
  .process(@width, @height) when (ispixel(@width)), (isem(@width)), (ispercentage(@width)), (iskeyword(@width)) {
    .kittens(@height) when (ispixel(@height)), (isem(@height)), (ispercentage(@height)), (iskeyword(@height)) {
      width: @width;
      height: @height;
    }
    .kittens(@height) when not (ispixel(@height)) and not (isem(@height)) and not (ispercentage(@height)) and not (iskeyword(@height)) {
      width: @width;
      height: ~`@{height} + @{unit}`;
    }
    .kittens(@height);
  }

  .process(@width, @height) when (ispixel(@height)), (isem(@height)), (ispercentage(@height)), (iskeyword(@height)) {
    .kittens(@width) when (ispixel(@width)), (isem(@width)), (ispercentage(@width)), (iskeyword(@width)) {}
    .kittens(@width) when not (ispixel(@width)) and not (isem(@width)) and not (ispercentage(@width)) and not (iskeyword(@width)) {
      width: ~`@{width} + @{unit}`;
      height: @height;
    }
    .kittens(@width);
  }

  .process(@width, @height) when not (ispixel(@width)) and not (isem(@width)) and not (ispercentage(@width)) and not (iskeyword(@width)) and not (ispixel(@height)) and not (isem(@height)) and not (ispercentage(@height)) and not (iskeyword(@height))  {
    width: ~`@{width} + @{unit}`;
    height: ~`@{height} + @{unit}`;
  }

  .process(@width, @height);

}

.font-size(@font-size: @font-size-base) {
  @rem: (@font-size / 16);
  font-size : @font-size * 1px;
  font-size : ~"@{rem}rem";
}
