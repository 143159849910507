.link-icon(@icon-width: 26px) {
	cursor: pointer;
	display: inline-block;
	padding-right: @icon-width;

	&::after {
		color: @blue;
		display: inline-block;
		font-size: 16px;
		font-style: normal;
		font-weight: normal;
		margin-right: -(@icon-width - 1px);
		width: @icon-width;
		text-indent: 7px;
		transition: text-indent .2s;
	}

	&:hover {
		color: @blue;

		&::after {
			text-indent: 10px;
		}
	}

	&:active {
		&, &::after {
			color: @orange;
		}
	}
}

.list-check() {
	&:before {
		.icon;
		content: @var-icon-check-usp;
		color: @green;
		display: block;
		position: absolute;
		left: 0;
		top: @offset-s;
		line-height: inherit;
	}
}

.text2icon() {
	// todo: new mixin
	&:extend(.icon);
	&:extend(.icon-jumbo-xl);
}