// Fullscreen & Campaign
// Hide some elements in the header that are currently not seperate Hippo components

// Currently supports BEM and non-BEM markup
// TODO: Remove non-BEM selectors after BEM markup has implemented

html[data-fullscreen-app],
html.campaign {

	.@{anwb-prefix}navigation {
		&__utilities,
		&__user,
		&__content,
		&__search,
		&__breadcrumb {
			display: none !important;
		}
		position: relative;
		z-index: 1;
		margin-top: 0;
		width: 100%;
		max-width: none;
		&:after {
			content: "";
			background: @yellow;
			width: 100%;
			height: 10px;
			left: 0;
			position: absolute;
			top: 39px;
		}
		&__logo {
			width: auto;
			float: left;
			margin-left: @grid-unit-s;
			.logo {
				margin-left: 0;
				height: 40px;
				width: auto;
				> a {
					width: auto;
					&::before {
						top: ~'calc( @{grid-unit-s} )';
						width: 72px;
						margin: 0;
					}
				}
			}
		}
	}

	.@{anwb-prefix}fullscreen {
		&__links {
			display: block;
			position: relative;
			margin-top: @rem-xs;
			font-weight: bold;
			font-family: @anwb-font-family;
		}
		&__leave {
			float: right;
			width: auto;
			margin-right: @grid-unit-m;
			pointer-events: visible;
			text-decoration: none;
			color: @blue-dark;
			&:before {
				content: 'Sluit ' attr(data-name);
			}
			&:after {
				content: @var-icon-normal-mode !important;
			}
			@media (max-width: 320px) {
				&:before {
					content: 'Sluit';
				}
			}
		}
	}

}

// Fullscreen
// Hide leaderboard, all main sections, fullscreen link and all footer parts except disclaimer
html[data-fullscreen-app] > body {
	.@{anwb-prefix}navigation__logo {
		max-width: 100%;
		.logo > a {
			width: 90px;
			&:before {
				margin-left: 0;
			}
		}
	}
	> .ad-leaderboard,
	> main > section,
	> [data-ui="nps-btn"],
	> footer {
		display: none;
	}
}
.@{anwb-prefix}fullscreen__links {
	display: none;
}

[data-ui="fullscreen-button"] {
	display: none;
}

.@{anwb-prefix}navigation ~ main [data-ui="fullscreen-button"] {
	&:extend(a[class|='link'] all);
	display: inline;
	&:before {
		content: 'Open ';
	}
	&:after {
		content: @var-icon-fullscreen-mode!important;
	}
}
// logo left 24, top: 20
// logo mobile left 12, top 12,
//