
.form-progress {

	display: flex;
	width: auto;

	height: auto;


	flex-direction: row;

	counter-reset: section;

	.grid-form & {
		max-width: @viewport-m;
		margin-bottom: -@rem-s;
	}

	@media ( @breakpoint-m) {
		margin-bottom: @rem-l;
		.grid-form & {
			margin-bottom: @rem-s;
		}
	}

	& > a {
		width: auto;
		height: auto;
		padding: 0.22rem 0.5rem;

		text-align: center;
		align-self: flex-end;

		font-family: @anwb-font-family;
		font-weight: bold;
		font-size: @font-size-m;

		color: @blue-grey;
		border-bottom: @line solid @blue-grey;

		flex: 1;

		position: relative;

		counter-increment: section;
		display: none;
		text-decoration: none;
		cursor: default;

		@media ( @breakpoint-m) {
			display: inline-block;
		}

		&::before {
			content: counter(section) ". ";
		}
		&[data-counter]::before {
			counter-increment: section;
			content: attr(data-counter);
		}
		&::after {
			.icon;

			content: '@{var-icon-chevron-right}';

			right: -0.28rem;
			bottom: -0.57rem;

			position: absolute;
			display: inline-block;
		}
		&:last-child::after {
			content: '';
		}

		&:hover {
			color:  @blue-grey;
		}
		&[data-ready=true],
		&[href] {
			cursor: pointer;
			color: @blue-grey-darker;
			border-color: @blue-grey-darker;
		}
		&[data-ready=true]:hover,
		&[href]:hover {
			color: @blue;
			border-color: @blue;
		}
		&.hidden {
			display: none;
		}
		&.active {

			padding: 0.2rem 0;

			text-align: left;
			border: none;

			color: @orange;
			font-size: @font-size-l;

			display: inline-block;

			&::before {
				color: @blue-grey-darker;
				content: attr(title) ": ";
			}
			&::after {
				display: none;
			}

			@media (@breakpoint-m) {
				padding: 0.2rem 0.5rem;
				text-align: center;
				font-size: @font-size-m;
				border-bottom: @line solid @orange;

				&::before {
					color: @orange;
					content: counter(section) ". ";
				}
				&[data-counter]::before {
					content: attr(data-counter);
				}

				&::after {
					display: inline-block;
				}
			}
			pointer-events: none;
		}
	}
}
