//
// Panel List Group
//--------------------------------------------------

.panel-list-morenews {
	&:extend(._panel all);
	& > .panel-body {
		padding: 0;

		& > .panel-main {
			padding: 0;
		}
	}
	.panel-main {
		ul {
			&:extend(.list-linklist all);
			max-width: 100%;

			> li {
				line-height: 1;
				> a {
					display: inline-block;
					padding-top: @offset-xs;
					padding-bottom: 0;
					padding-left: 0;
					font-size: @font-size-m;
					line-height: @line-height-s;
				}

				> small {
					display: inline;
					overflow: hidden;
					padding-top: 0;
					padding-left: 0;
					color: @blue-grey-dark;
					text-overflow: ellipsis;
					white-space: wrap;
					font-weight: normal;
					line-height: @line-height-base;
				}
			}
		}
		& > a:last-child {
			display: inline-block;
			padding-top: @offset-m;

		}
	}

	[class|=grid] > div > article > & {
		padding: 0;
	}
}