/*

	Mouse styles, in this file the behaviour of the mouse is defined.
	 This should only happen on top level elements.

*/

// Buttons are by default not with a pointer
a, button, input[type="button"], input[type="submit"] {
	cursor: pointer;
}

[disabled] {
	cursor: not-allowed;
}

body[data-lock-content="true"] {
	overflow-y: hidden;
	overflow-x: auto;
}
