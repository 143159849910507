.@{anwb-prefix}collapsible {
	@block: ~"@{anwb-prefix}collapsible";

	&__title {
		margin: 0;
		padding-top: 1rem;
		padding-bottom: 1rem;
		.spacing-panel-sides();
		font-size: @font-size-m;
		.anwb-thesans-bold();
	}

	&__indicator {
		cursor: pointer;
		display: block;
		position: relative;

		&::after {
			&:extend(.icon);
			display: inline-block;
			color: inherit;
			font-size: 16px;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -8px;

			content: @var-icon-chevron-up;
		}
	}

	&__content {
		margin: 0;
		padding-bottom: 1rem;
		.spacing-panel-sides();
	}

	[data-collapse] {
		~ .@{block}__content {
			@media (@breakpoint-s) {
				border-bottom: 2px solid @blue-grey-darker;
			}
		}
	}

	[data-collapse]:not(:checked) {
		~ .@{block}__content {
			display: none;
		}

		~ .@{block}__title {
			@media (@breakpoint-s) {
				border-bottom: 2px solid @blue-grey-darker;
			}

			.@{block}__indicator {

				&:after {
					content: @var-icon-chevron-down;
				}
			}
		}
	}

	&--mobile-only {
		@media (@breakpoint-m) {
			[data-collapse] {
				&:not(:checked) ~ .@{block}__content {
					display: block;
				}

				~ .@{block}__title,
				&:not(:checked) ~ .@{block}__title {
					.@{block}__indicator {
						pointer-events: none;

						&::after {
							content: '';
						}
					}
				}
			}
		}
	}
}