.@{anwb-prefix}slats {
	@block: ~"@{anwb-prefix}slats";

	&,
	> ul,
	> ol {
		.reset-list();
	}

	li {
		margin: 0;
	}

	.border-section();

	&__item {
		padding-top: 1rem;
		padding-bottom: 1rem;
		.border-section();

		&:last-child {
			border-bottom: 0 none;
		}
	}

	&--quagga,         // `Useless` fact of this modifier name:
	&--quagga &__item, // Quagga is an extinct species of zebra with no stripes on its back...
	&--zebra,
	&--zebra &__item {
		border-bottom: 0 none;
	}

	&--quagga &__item {
		padding-top: .5rem;
		padding-bottom: .5rem;

		&:last-child {
			padding-bottom: 1rem;
		}
	}

	// These will have stripes
	&--zebra &__item:nth-child(odd) {
		background-color: @blue-grey-light;
	}

	&--zebra &__item:nth-child(even) {
		background-color: @blue-grey-lighter;
	}

	&--links,
	&--numbered {
		color: @blue-dark;
		font-family: @anwb-font-family;
		font-size: @font-size-m;
		font-weight: bold;

		a {
			color: inherit;
			font: inherit;
			text-decoration: inherit;

			&:focus, &:hover {
				color: @blue;
			}
		}
	}

	&--numbered {
		counter-reset: topX;

		.@{block}__item {
			align-items: center;
			display: flex;

			&::before {
				counter-increment: topX;
				content: counter(topX);
				display: inline-block;
				vertical-align: middle;
				font-size: @heading-l;
				line-height: 1;
				margin-right: .5em;
				min-width: 1em;
			}
		}
	}
}