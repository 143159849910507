// Pseudo-elements worden op iframes niet ondersteund dus wordt de spinner op het element zelf gezet.
// De animatie is echter bevroren zolang het iframe laadt.
// Dat is eventueel op te lossen door een dedicated fysiek element voor de spinner te introduceren.
iframe[lazy-src] {
	background: url("images/spinner_static.svg") no-repeat center center;
}

// any element with a data-loading attribute will show the spinner with the text below
body > main [data-loading] {
	&::before {
		display: block;
		width: 40px;
		margin-left: ~'calc(50% - 20px)';
		text-align: center;
		height: 40px;
		background: url("images/spinner_static.svg") no-repeat center center;
		background-size: 100%;
		content: '';
		animation: spin 2.5s infinite linear;
	}
	&:not([data-loading=""])::after {
		display: block;
		text-align: center;
		width: 100%;
		content: attr(data-loading)'...';
	}

	&.spinner--huge {
		&::before {
			width: 80px;
			height: 80px;
			margin-left: ~'calc(50% - 40px)';
		}
	}

	&.spinner--small {
		&::before {
			width: 20px;
			height: 20px;
			margin-left: ~'calc(50% - 10px)';
		}
		&::after {
			font-size: smaller;
		}
	}

	button&, button&:hover,
	&.btn, &[class^=btn],
	&.btn:hover, &[class^=btn]:hover {
		&::before {
			width: 19px;
			height: 19px;
			margin-left: .3rem;
			padding-right: 0;
			right: -2px;
			float: right;
		}
		&::after {
			content: '';
			display: none;
		}
	}

	&.btn-next,
	&[class^=btn-reversed],
	&[class^=btn-primary],
	&[class^=btn-decide] {
		&::before {
			background-image: url("images/spinner_static_white.svg");
		}
	}
}

/* === Animation === */
// -------------------------
@keyframes spin {
	0% {
		transform: rotate(0deg);
		transform-origin: center center;
	}
	100% {
		transform: rotate(360deg);
		transform-origin: center center;
	}
}
