// Fullscreen & Campaign
// Hide some elements in the header that are currently not seperate Hippo components
html[data-fullscreen-app],
html.campaign {
	> body > header:not(.ANWB-navigation) {
		> .breadcrumb,
		nav.navigation-main {
			display: none !important;
		}
		position: relative;
		z-index: 1;
		margin-top: 0;
		// Reset header elements to 'mobile'
		> .utilities {
			width: 100%;
			.logo {
				height: 40px;
				> a::before {
					top: ~'calc( @{grid-unit-s} - @{line} )';
					width: 72px;
				}
			}
			&:after {
				content: "";
				background: @yellow;
				width: 100%;
				height: 10px;
				left: 0;
				position: absolute;
				top: 39px;
			}
			.fullscreen {
				display: inline-block;
				float: right;
				position: relative;
				margin-top: @rem-xs;
				font-weight: bold;
				font-family: @anwb-font-family;
				.link-fullscreen-leave {
					pointer-events: visible;
					&:before {
						content: 'Sluit ' attr(data-name);
					}
					&:after {
						content: @var-icon-normal-mode!important;
					}
					@media (max-width: 320px) {
						&:before {
							content: 'Sluit';
						}
					}
				}
			}
		}
	}
}

// Fullscreen
// Hide leaderboard, all main sections, fullscreen link and all footer parts except disclaimer
html[data-fullscreen-app] > body {
	> header:not(.ANWB-navigation) > nav.utilities {
		max-width: 100%;
		.logo > a {
			width: 90px;
			&:before {
				margin-left: 0;
			}
		}
	}
	> .ad-leaderboard,
	> main > section,
	> [data-ui="nps-btn"],
	> footer {
		display: none;
	}
}

body > header:not(.ANWB-navigation) .fullscreen {
	display: none;
}

body > header:not(.ANWB-navigation) + main > section [data-ui="fullscreen-button"] {
	&:extend(a[class|='link'] all);
	display: inline;
	&:before {
		content: 'Open ';
	}
	&:after {
		content: @var-icon-fullscreen-mode!important;
	}
}
// logo left 24, top: 20
// logo mobile left 12, top 12,
//