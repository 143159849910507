
// animations
@duration-in: 0.2s;
@duration-out: 0.1s;
@box-shadow-duration-in: @duration-in / 2;
@box-shadow-duration-out: @duration-out / 2;

@delay-direct: 0s;
@delay-in: @duration-in / 2;
@delay-out: @duration-out / 2;

@label-delay-in: @delay-direct;
@menu-delay-in: @delay-in;
@box-shadow-delay-in: @menu-delay-in + @duration-in;

@box-shadow-delay-out: @delay-direct;
@menu-delay-out: @box-shadow-duration-out;
@label-delay-out: @box-shadow-duration-out + @delay-out;

._dropdown() {
	position: relative;
	list-style: none;
	padding: 0 !important; // fixme
	display: inline-block;

	&__shadow {
		//display: block;

		&::before {
			content: " ";
			position: absolute;
			padding: 0;
			z-index: 0;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			border-top-left-radius: @border-radius-s;
			border-top-right-radius: @border-radius-s;
			box-shadow: 0 0 0 fade(@shadow-color-light, 0);
			transition-property: box-shadow;
			transition-duration: @box-shadow-duration-out;
			transition-delay: @box-shadow-delay-out;
			transition-timing-function: ease-out;
		}

		.@{block}__controller:checked ~ &,
		.@{block}:not([data-touched="true"]):not([data-touched="possible"]):hover & {
			&::before {
				z-index: 799;
				box-shadow: 0 0 5px @shadow-color-light;
				transition-property: box-shadow;
				transition-duration: @box-shadow-duration-in;
				transition-delay: @box-shadow-delay-in;
				transition-timing-function: ease-in;
			}
		}
	}

	&__label {
		position: relative;
		z-index: 0;
		cursor: pointer;
		border-top-left-radius: @border-radius-s;
		border-top-right-radius: @border-radius-s;
		display: inline-block;
		transition-property: background, z-index;
		transition-duration: @duration-out;
		transition-delay: @label-delay-out;
		transition-timing-function: ease-out, step-start;

		.@{block}__controller:checked ~ &,
		.@{block}:not([data-touched="true"]):not([data-touched="possible"]):hover & {
			z-index: 801;
			background: @white;
			transition-property: background, z-index;
			transition-duration: @duration-in;
			transition-delay: @label-delay-in;
			transition-timing-function: ease-in, step-start;
		}
	}

	&__link {
		display: block;
		width: 100%;
		padding: @margin-text-m;
		margin: 0;
		color: @blue-dark;
		text-decoration: none;
		font-family: @anwb-font-family;

		&:hover {
			color: @blue;
		}

		&:active {
			color: @orange;
		}
	}

	&__menu {
		display: block;
		min-width: ~'calc(100% + 2 * @{border-radius-s})';
		margin: 0;
		// IE10 / IE11: fix the little gap it shows
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			margin-top: -1px;
		}
		// Edge:
		@supports (-ms-ime-align: auto) {
			margin-top: -1px;
		}
		// padding-top set to 0, so there is no gap
		padding: 0 @margin-text-m @margin-text-s @margin-text-m;
		border-top-right-radius: @border-radius-s;
		border-bottom-left-radius: @border-radius-s;
		border-bottom-right-radius: @border-radius-s;
		list-style: none;
		background-color: @white;
		box-shadow: 0 0 0 @shadow-color-light;
		transition-property: opacity, box-shadow, top, z-index;
		transition-duration: @duration-out, @box-shadow-duration-out, @duration-out, @duration-out;
		transition-delay: @menu-delay-out, @box-shadow-delay-out, @menu-delay-out, @menu-delay-out;
		transition-timing-function: ease-out, ease-out, step-end, step-end;

		position: absolute;
		z-index: 0;
		left: 0;
		// normaly closed
		opacity: 0;
		top: -1000px;

		.@{block}__controller:checked ~ &,
		.@{block}:not([data-touched="true"]):not([data-touched="possible"]):hover & {
			z-index: 799;
			opacity: 1;
			top: 100%;
			box-shadow: 0 0 5px @shadow-color-light;
			transition-property: opacity, box-shadow, top, z-index;
			transition-duration: @duration-in, @box-shadow-duration-in, @duration-in, @duration-in;
			transition-delay:  @menu-delay-in, @box-shadow-delay-in, @menu-delay-in, @menu-delay-in;
			transition-timing-function: ease-in, ease-in, step-start, step-start;
		}

		&--hidden,
		& & {
			// do not show hidden or  nested menu's
			display: none;
		}
	}

	&__item {
		display: block;
		font-weight: normal;
		font-size: @font-size;
		line-height: 2;
		white-space: nowrap;

		& a {
			display: block;
			text-decoration: none;
			color: @blue-dark;
		}

		&:hover a {
			color: @blue;
		}

		& a:active {
			color: @orange;
		}

		&:first-child a {
			// border top needed for bridging the gap
			border-top: @line solid transparent;
		}
	}

	&--align-right {
		.@{block}__menu {
			border-top-right-radius: 0;
			border-top-left-radius: @border-radius-s;
			left: auto;
			right: 0;
		}
	}

	&--icon {
		.@{block}__link {
			&:after {
				.icon;
				color: @blue;
				content: @var-icon-chevron-down;
				display: inline-block;
				position: relative;
				margin-left: @rem-xs;
				transition: transform .2s;
			}
		}

		.@{block}__controller:checked ~ .@{block}__label .@{block}__link,
		.@{block}:not([data-touched="true"]):not([data-touched="possible"]):hover .@{block}__label .@{block}__link {
			&:after {
				transform: rotate(180deg);
			}
		}
	}
}

.@{anwb-prefix}dropdown {
	@block: ~"@{anwb-prefix}dropdown";

	._dropdown();

	&[data-hide-on="breakpoint-s"] {
		@media (@breakpoint-s) {
			display: none;
		}
	}

	&[data-hide-on="breakpoint-s--menu"] {
		@media (@breakpoint-s--menu) {
			display: none;
		}
	}
}