
/* License Plates ---------------------------------------------- */
/* ------------------------------------------------------------- */

.grid-form {

	span.form-info {
		.icon;

		margin-top: -0.15rem;
		font-size: 1.6rem;
		color: @blue;
		text-align: center;
		position: relative;
		display: inline-block;

		&::before {
			content: '@{var-icon-information}';
		}

		&:hover {
			color: @blue-grey-darker;
			cursor: pointer;
		}
	}

	.license-plate {
		color: @black;

		font-family: @anwb-font-family;
		font-weight: bold;

		text-align: center;
		text-transform: uppercase;

		border-radius: @border-radius;
		background-color: @yellow;
	}

	input.license-plate {
		width: 12.4rem;
		height: 3.33333333rem;

		font-size: 26px;
		line-height: 24px;

		padding-left: 2.2rem;

		border: @line solid hsla(213, 100%, 26%, 1);

		background-image: url('images/input-text-licence-plate.svg');
		background-repeat: no-repeat;
		background-position: 0% 50%;
		background-size: 2rem;

		&:focus,
		&.invalid:focus {
			border-color: @blue;
		}
		&.invalid {
			border-color: @red;
		}
	}

	textarea.license-plate {

		overflow: hidden;
		resize: none;
	}

	textarea.license-plate {

		&.motor {
			width: 7.33333333rem;
			height: 4.66666667rem;

			padding: 0.6rem 0.4rem 0 0.4rem;

			font-size: 1.75rem;
			line-height: 1.6rem;

			border: @line solid hsla(213, 100%, 26%, 1);

			background-image: url('images/input-text-licence-plate.svg');
			background-repeat: no-repeat;
			background-position: -1% -2%;
			background-size: 1.3rem;

			text-indent: 0.5rem;

			&:focus,
			&.invalid:focus {
				border-color: @blue;
			}
			&.invalid {
				border-color: @red;
			}
		}

		&.scooter {
			width: 4.66666667rem;
			height: 4.66666667rem;

			font-size: 1.34rem;
			line-height: 1.2rem;

			padding: 1rem 0.4rem 0 0.4rem;

			&.yellow {
				border: @line solid hsla(213, 100%, 26%, 1);
				box-shadow: 0 0 0 @line @yellow;
			}

			&.blue {
				color: @white;
				background-color: #0054C5;
				border: @line solid @white;
				box-shadow: 0 0 0 @line #0054C5;
			}

			&:focus,
			&.invalid:focus {
				box-shadow: 0 0 0 @line @blue;
			}

			&.invalid {
				box-shadow: 0 0 0 @line @red;
			}
		}
	}
}
