.@{anwb-prefix}tags-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: auto;
	margin: @rem 0;
	padding: .6rem 0;

	&__tag {
		background-color: @blue-grey-lighter;
		color: @blue-dark;
		border-radius: @border-radius;
		padding: @rem-xs @rem-s;
		margin-right: @rem-s;
		margin-bottom: @rem-s;
		font-family: @anwb-font-family;
		text-decoration: none;
		font-size: @font-size-s;
		font-weight: 600;
		&:hover {
			color: @blue;
		}
	}
}