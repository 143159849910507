.@{anwb-prefix}footer {
	@block: ~"@{anwb-prefix}footer";

	&__section {
		padding-bottom: 0.4em;

		// override because content in footer needs to touch the edges
		@media (@breakpoint-s) {
			& > .@{anwb-prefix}structure,
			& .@{anwb-prefix}pane {
				padding-left: 0;
				padding-right: 0;
			}

			& .@{anwb-prefix}collapsible__title,
			& .@{anwb-prefix}collapsible__content {
				padding-left: @panel-grid-s +
					(@grid-offset-s - (@grid-unit-s / 2));
				padding-right: @panel-grid-s +
					(@grid-offset-s - (@grid-unit-s / 2));
			}
		}

		@media (@breakpoint-m) {
			padding-top: @rem-component-l;
			padding-bottom: @rem-component-m;
		}
	}

	&__usp-list {
		font-size: @font-size-xs;
		margin-bottom: @rem-xl;

		&-item {
			display: flex;

			+ .@{anwb-prefix}footer__usp-list-item {
				margin-top: @rem-s;
			}

			svg {
				width: @rem;
				height: @rem;
				margin-top: 0.15rem;
				margin-right: @rem-s;
				fill: @white;
			}
		}
	}

	&__image {
		text-decoration: none;

		&,
		img {
			width: 55%;
			max-width: 100%;
			margin: 0;

			@media (@breakpoint-l) {
				width: auto;
			}
		}

		img {
			image-rendering: -webkit-optimize-contrast;
		}
	}

	&__button {
		border-color: @white;
		margin-top: 1.66666rem;
	}

	&__links {
		.reset-list();
		font-size: @font-size-xs;

		a {
			position: relative;
			display: inline-block;
			padding-top: @offset-s;
			padding-bottom: @offset-s;

			color: @white;
			text-decoration: none;

			&:hover {
				color: @blue-grey;
				text-decoration: underline;
			}

			&,
			&:before,
			&:hover:before {
				text-decoration: none;
			}

			&:active {
				color: @white;
			}

			&:before {
				color: @white;
				border-radius: 50%;
				width: 22px;
				display: inline-block !important;
				background-color: @yellow;
				margin-right: 6px;
				vertical-align: bottom;
				font-size: @rem-base;
				text-align: center;

				@media (@breakpoint-l) {
					top: @rem-xs;
				}
			}

			&:hover:before {
				background-color: lighten(@yellow, 20%);
			}

			&[href*="nieuwsbr"],
			&[href$="/mobiel"],
			&[href$="/community"],
            &[href*="/wandelen/podcast"] {
				&:before {
					color: @blue-dark;
				}
			}
			&[href*="nieuwsbr"]:before {
				content: @var-icon-mail;
			}
			&[href$="/mobiel"]:before {
				content: @var-icon-social-apps;
			}
            &[href*="/wandelen/podcast"]:before {
                content: @var-icon-podcast;
            }
			&[href$="/community"]:before {
				content: @var-icon-social-community;
			}
			// twitter
			&[href*="twitter"] {
				&:before {
					content: @var-icon-social-twitter;
					background-color: @twitter-color;
				}
				&:hover:before {
					background-color: lighten(@twitter-color, 10%);
				}
			}
			// Facebook
			&[href*="facebook.com"] {
				&:before {
					content: @var-icon-social-facebook;
					background-color: @facebook-color;
				}
				&:hover:before {
					background-color: lighten(@facebook-color, 10%);
				}
			}
			// youtube
			&[href*="youtube.com"] {
				&:before {
					content: @var-icon-social-youtube;
					background-color: @youtube-color;
				}
				&:hover:before {
					background-color: lighten(@youtube-color, 10%);
				}
			}
			// Google+
			&[href*="google.com"] {
				&:before {
					content: @var-icon-social-google;
					background-color: @google-color;
				}
				&:hover:before {
					background-color: lighten(@google-color, 10%);
				}
			}
			// LinkedIn
			&[href*="linkedin.com"] {
				&:before {
					content: @var-icon-social-linkedin;
					background-color: @linkedin-color;
				}
				&:hover:before {
					background-color: lighten(@linkedin-color, 10%);
				}
			}
			// instagram
			&[href*="instagram.com"] {
				&:before {
					content: @var-icon-social-instagram;
					background-color: @instagram-color;
				}
				&:hover:before {
					background-color: lighten(@instagram-color, 10%);
				}
			}
		}
	}

	&__disclaimer {
		a {
			padding: @margin-text-s 0;
			margin-right: 2rem;
			font-size: @font-size-xs;
			display: block;
			color: @blue-dark;
			text-decoration: none;

			@media (@breakpoint-m) {
				display: inline-block;
			}
		}
	}
}
