/*
Panels
--------------------------------------------------
- base

Modifiers
--------------------------------------------------
- panel-folded
- panel-unfolded

*/

// Layout Panels
// -----------------------------------------------

// TODO: Remove :not([class|=panels]) Refactor all 'panels-*' to 'panel-*'
article {
	._panel-grid-offset();
}

// Panels Base
// -----------------------------------------------

.panel,
.panel-basic {
	&:extend(._panel all);
}

.panel_attention {
	.panel-attention();
}

.panel-body {
	/*  used only to nest panels in a grid
		that can only render in a single row. */
	& .grid-balanced,
	& .grid-split {
		._nested-panel-row-inset();
	}
}

.panel-main {
	display: block;

	> h1,
	> h2,
	> h3,
	> p {
		margin-top: 0;
	}

	> h2,
	> h3 {
		margin-bottom: @rem-xs;
		&:extend(._panel-title-h3 all);
	}

	> h3 {
		> * {
			text-align: left;
			padding: 0;
			font-weight: normal;
			font-size: inherit;

			&:after {
				font-weight: normal;
				transition: left @duration-out;
				font-size: @font-size-xs;
				//color: ;
			}
		}
		&.pretitle {
			.anwb-thesans-semi-bold-italic();
			color: @blue-dark;
			font-size: @font-size-l;
		}
	}
	.list-linklist {

		& > li {
			&:last-child {
				margin-bottom: -@margin-text-s;
			}

			&:first-child {
				margin-top: -@margin-text-s;
			}

			& > * {
				padding: @margin-text-s 0;
			}
		}

		&:empty {
			display: none;
		}
	}
	li {
		margin-top: @offset-s;
		margin-bottom: @offset-s;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	p {
		margin-bottom: @rem-s;
	}

	& > :last-child {
		margin-bottom: 0;
	}


}

.panel-body > header {
	& > h3 {
		&:extend(._panel-title-h3 all);
		margin: 0;
		hyphens: auto;
	}
}

// allow for nested panels, allows for loading agnostic applications inside a panel
.panel-body > [class|=panel]:not(.panel-faq) {
	padding: 0;
}

section:not(.panel-compare):not(.panel-callToAction) > .panel-body > footer > ul > li {
	> a {
		padding: 0px!important;
	}
	&:not(:last-child) > a {
		padding-bottom: 20px!important;
	}
}

.panel-footer() {
	._panel-padding();
	margin: auto -@grid-unit-s -@grid-unit-s;

	@media (@breakpoint-m) {
		margin: auto -@grid-unit-m -@grid-unit-m;
	}
	@media (@breakpoint-l) {
		margin: auto -@rem-l -@rem-l;
	}
	border-bottom-right-radius: @border-radius;
	border-bottom-left-radius: @border-radius;
}

.panel-body > .panel-main + footer {
	.panel-footer();
}

// Containers
//--------------------------------------------------

//Panels stacked
.panels-stacked {
	display: flex;
	flex-direction: column;
	flex: 1;
	> .panel-body {
		margin-bottom: @grid-unit-s;

		@media (@breakpoint-m) {
			margin-bottom: @grid-unit-m;
		}

		> h2 {
			max-width: none;
			font-family: @anwb-font-family;
			font-style: italic;
			font-weight: normal;
			font-size: @heading-m;
			margin-bottom: @rem-xs;

			@media (@breakpoint-m) {
				margin-bottom: 0;
				font-size: @heading-l;
			}
		}
	}
}

// Collapse
.panel-collapse() {
	> h3 > label {
		// prepare Icon display
		&:after {
			.icon;
			position: absolute;
			top: 1.2em;
			right: 0;
			width: 2em;
			text-align: right;
		}
	}

	// Inactive (CLOSED)
	input[type=checkbox] + h3 > label:after { content: @var-icon-chevron-down; }

	input[type=checkbox] ~ div.panel-main {
		display: none;
	}

	// Active (OPEN)
	input[type=checkbox]:checked + h3 > label:after { content: @var-icon-chevron-up; }

	input[type=checkbox]:checked ~ div.panel-main {
		display: block;
	}

	// Focus Tabbing Footer labels (WAI ARIA)
	> input[type=checkbox]:focus + h3 > label {
		color: @blue-grey;
	}
}

// Stickers
//-------------------------------------
// base position (without a header img)
.panel-body > [class^=sticker-] {
	float: right;
	z-index: @z-index-sticky - 10;
	margin-bottom: -@grid-unit-m;
	margin-top: -@grid-unit-m;
	margin-right: ~'calc(-@{grid-unit-s} - @{panel-grid-s})';

	@media (@breakpoint-m) {
		margin-top: ~'calc(-@{rem} - @{panel-grid-l})';
		margin-right: ~'calc(-@{grid-unit-m} - @{panel-grid-m})';
	}

	@media (@breakpoint-l) {
		margin-top: ~'calc(-@{rem-l} - @{panel-grid-l})';
		margin-right: ~'calc(-@{rem-l} - @{panel-grid-l})';
	}

}

// position sticker in a different location when there's a header
.panel-body > header + [class^=sticker-] {
	margin-top: ~'calc(-@{sticker-m} )';

	@media (@breakpoint-m) {
		margin-top: ~'calc(-@{sticker-m} )';
	}

	@media (@breakpoint-l) {
		margin-top: ~'calc(-@{sticker-l} )';
	}

	& + .panel-main {
		@media (@breakpoint-m) {
			h3 { margin-right: 0; }
		}
	}
}

// h3 margin when there is NO header IMG
.panel-body [class^=sticker-] + .panel-main {
	@media (@breakpoint-m) {
		h3 { margin-right: ~"calc(@{sticker-l} - (@{rem-l} + 10px))"; }
	}
}

._nested-panel-row-inset() {

	// The direct child must be a grid
	&:first-child{
		& > [class|=panel] {
			padding-top: 0;
		}
	}
	&:last-child{
		& > [class|=panel] {
			padding-bottom:0;
		}
	}
	& > [class|=panel] {
		&:first-child {
			margin-left: -@grid-unit-s / 2;
			@media (@breakpoint-m) {
				margin-left: -@grid-unit-m / 2 ;
			}
			@media (@breakpoint-l) {
				margin-left: -@rem-l / 2;
			}
		}
		&:last-child {
			margin-right: -@grid-unit-s / 2;
			@media (@breakpoint-m) {
				margin-right: -@grid-unit-m / 2;
			}
			@media (@breakpoint-l) {
				margin-left: -@rem-l / 2;
			}
		}

	}
}
