
/**
 * Private classes for mixins and extends
 */
@import (reference) "../variables/forms";
@import (reference) "../variables/layout";

._form-flex_block {
	justify-content: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	display: flex;
	& > * {
		margin-bottom: @form-margin-base;
	}
}


._inputset(){

	form & {
		._form-flex_inline;
		& > * {
			flex: 1 0 auto;
			margin-bottom: @form-margin-base;
		}
		& + p {
			position: relative;
			@media (@breakpoint-m) {
				top: -@form-margin-base;
			}

		}
		@media (@breakpoint-m){

			& > * {
				flex: 1;
				width: auto;
				margin-right:  @form-gutter-base;
				margin-left:   @form-gutter-base;
				margin-bottom: 0;
			}
			& + p {
				position: static;
			}
			& > *:last-child{
				margin-right: 0;
			}
			& > *:first-child {
				margin-left: 0;
			}
			& > *.btn,
			& > *[class|='btn'],
			& > *[type][data-input-size] {
				padding-right: 0.7rem;
				padding-left: 0.7rem;
			}

			& > * + :last-child {
				flex: 0.5;
				max-width: 33.3333%;
			}
			& > input,
			& > select,
			& > button,
			& > div,
			& > span {
				&[data-input-size='1'] {
					flex: 0.5;
					max-width: 33.3333%;
				}
				&[data-input-size='1.5'] {
					flex: 1.5;
					max-width: 50%;
				}
				&[data-input-size='2'] {
					flex: 2;
					max-width: 66.6666%;
				}
				&[data-input-size='0'] {
					flex: 0 0 auto;
				}
				&[data-input-size] select,
				&[data-input-size] [type] {
					display: block;
					width: 100%;
				}
			}


		}
	}
	form[data-column-limit='4'] & {
		@media (@breakpoint-m) {
			& > * + :last-child {
				max-width: 25%;
			}
			& > input,
			& > button,
			& > div,
			& > span {
				&[data-input-size='1'] {
					max-width: 25%;
				}
				&[data-input-size='2'] {
					flex: 2;
					max-width: 50%;
				}
				&[data-input-size='3'] {
					flex: 3;
					max-width: 75%;
				}
			}
		}
	}
}

._form-flex_inline {
	margin-bottom: 0;

	justify-content: space-between;
	flex-direction: column;
	flex-wrap: nowrap ;
	display: flex;

	@media (@breakpoint-m) {
		flex-direction: row;
		flex-wrap: wrap;
	}

}


/**
 * Public mixins for form layout
 * This mixin allow us to reuse the form layout pattern on forms that are part of other panels.
 * NOTE: '.form-layout()' must only be applied to a form element!
 */

//
.form-layout(){

	& > header {
		width: auto;
		max-width: @viewport-m;

		margin-bottom: @form-margin-base;

		& > {
			margin-bottom: @form-margin-xs;
		}
	}
	& > footer {
		width: auto;
		max-width: @viewport-m;

		margin: @form-margin-base 0;

		justify-content: space-between;
		flex-direction: row;
		flex-wrap: wrap;
		display: flex;

		& > * {
			flex: 1;
			margin-right:  @form-margin-base;
			margin-bottom: @form-margin-base;
		}

		& > *:last-child {
			margin-right: 0;
		}

		@media ( @breakpoint-m) {
			& > * {
				flex: none;
			}
		}
	}

	fieldset {
		width: auto;
		max-width: @viewport-m;

		// Form Title
		& > legend {

			font-family: @anwb-font-family;
			font-weight: bold;
			font-size: @heading-m;

			line-height: @line-height-base;

			color: @blue-dark;
		}

		// Form Sub-Title
		& > h4 {
			margin-top: @form-margin-base;
			margin-bottom: @form-margin-s;
		}

		// Default div container with Flex-Direction: column
		& > div {
			margin-bottom: @form-margin-s;

			&:extend( ._form-flex_block all );

			& > label {
				margin: @form-margin-m 0;
			}

			& > label:first-of-type {
				//width: 100%;
				margin: 0 0 @form-margin-xs 0;
				display: inline-block; /* IE 10 Weird fix */
			}

			& > .inputset + p,
			& > span + p,
			& > div + p,
			& > input + p,
			select + p,
			textarea + p  {

				padding: 0;
				margin-top: @form-margin-xxxs;

				color: @blue-grey-darker;
				font-size: @font-size-xs;

				line-height: @line-height-base;
			}

			& .message-validity {
				padding: 0;

				margin-bottom: @form-margin-l;

				font-size: @font-size-xs;
				line-height: @line-height-s;
				color: @red;
				display: none;
				white-space: pre-line;
			}


			// Different Margin for the "Message Validity" container that is placed after Radio / Checkbox elements
			& .message-validity:empty {
				display: none;
			}
			& > label + .message-validity,
			& > span + .message-validity {
				margin-top: @form-margin-xs;
			}
			& > .inputset .message-validity {
				margin-top: @form-margin-l;
			}

			& > span {
				&:extend( ._form-flex_inline );

				justify-content: flex-start;

				& > label {
					margin: @form-margin-m 0;
					margin-right: @form-gutter-xl;
				}
			}
		}
	}
}