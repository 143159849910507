.@{anwb-prefix}question-answer {
	display: flex;
	align-items: center;

	&__image {
		border-radius: 50%;
		width: 70px;
		height: 70px;
		margin: 0;
		overflow: hidden;

		img {
			margin: 0;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		margin-left: @rem;
	}

	&__title {
		font-style: italic;
		margin: 0;
	}
}