.@{anwb-prefix}conversation {
	@block: ~"@{anwb-prefix}conversation";

	[hidden] {
		display: none !important;
	}

	&__lines {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 0 (3rem + @panel-grid-s);

		&--info {
			align-items: flex-start;

			.panel-contact &, // FIXME: remove when panel-contact is refactored
			.@{block}--small & {
				padding-left: @grid-unit-s;
				padding-right: @grid-unit-s;
			}

			& .@{block}__attendant {
				left: 0;
				// attendant should be an icon, they need to be a bit bigger
				font-size: 1.5rem;
				line-height: 2.5rem;
			}
		}

		&--partner {
			align-items: flex-start;
			padding-right: 0;

			.panel-contact &, // FIXME: remove when panel-contact is refactored
			.@{block}--small & {
				padding-left: @grid-unit-s;
			}

			& .@{block}__attendant {
				left: 0;
			}

			& .@{block}__bubble {
				background-color: @blue-grey-light;

				.panel-contact &, // FIXME: remove when panel-contact is refactored
				.panel-widget &, // FIXME: remove when panel-widget is refactored
				.@{block}--inverted & {
					background-color: @white;
					border-color: @blue-grey-lighter;
				}
			}
		}

		&--you {
			align-items: flex-end;
			padding-left: 0;

			.panel-contact &, // FIXME: remove when panel-contact is refactored
			.@{block}--small & {
				padding-right: @grid-unit-s;
			}

			& .@{block}__attendant {
				background-color: @blue-grey-lighter;
				right: 0;

				.panel-contact &, // FIXME: remove when panel-contact is refactored
				.panel-widget &, // FIXME: remove when panel-widget is refactored
				.@{block}--inverted & {
					background-color: @blue;
					border-color: @blue;
					color: @white;
				}
			}

			& .@{block}__bubble {
				background-color: @white;

				.panel-contact &, // FIXME: remove when panel-contact is refactored
				.panel-widget &, // FIXME: remove when panel-widget is refactored
				.@{block}--inverted & {
					//background-color: hsla( 60, 100%, 100%, 0.5);
					background-color: @blue-grey-lighter;
					border-color: @blue-grey;
				}
			}
		}

		&--feedback {
			margin-bottom: @panel-grid-s;

			.panel-contact &, // FIXME: remove when panel-contact is refactored
			.@{block}--small & {
				padding-left: @grid-unit-s;
				padding-right: @grid-unit-s;
			}

			@media (@breakpoint-m) {
				margin-bottom: @panel-grid-m;
			}

			@media (@breakpoint-l) {
				margin-bottom: @panel-grid-l;
			}
		}
	}

	&__attendant {
		position: absolute;
		display: block;
		top: 0;

		background-color: @white;
		width: 3rem;
		height: 3rem;
		line-height: 2.6rem;
		text-align: center;
		font-family: @anwb-font-family;
		text-transform: lowercase;

		.border-panel();
		border-radius: 1.5rem;

		.panel-contact &, // FIXME: remove when panel-contact is refactored
		.panel-widget &, // FIXME: remove when panel-widget is refactored
		.@{block}--inverted & {
			border-color: @blue-grey;
		}

		&--iris {
			overflow: hidden;

			> img { // important to override current generic styles...
				margin: 0 !important;
				width: 100% !important;
				height: 100% !important;
				position: absolute;
				left: 0;
				top: 0;

				.grid-stacked & { // some weird bug, that will hopefully be fixed when `structures`
					position: static;
				}
			}
		}
	}

	&__feedback-set {
		display: inline-block;
	}

	&__input {
		padding-top: 1rem;

		&-set {
			display: flex;
			flex-wrap: wrap;
			width: ~'calc(100% + .2rem)';

			> * {
				margin-right: .2rem;
				margin-bottom: .2rem;
			}

			> input {
				flex: 1 1 60%;
			}

			> button {
				flex: 1 0 auto;
			}
		}
	}

	&__bubble {
		display: inline-block;
		padding: @panel-grid-s;
		margin-bottom: @panel-grid-s;
		max-width: 86%;

		.panel-contact &, // FIXME: remove when panel-contact is refactored
		.@{block}--small & {
			max-width: 100%;
		}

		.panel-contact .@{block}__attendant + &, // FIXME: remove when panel-contact is refactored
		.@{block}--small .@{block}__attendant + & {
			margin-top: 2.26667rem;

			@media (@breakpoint-m) {
				margin-top: 2.06667rem;
			}

			@media (@breakpoint-l) {
				margin-top: 1.86667rem;
			}
		}

		@media (@breakpoint-m) {
			padding: @panel-grid-m;
			margin-bottom: @panel-grid-m;
		}

		@media (@breakpoint-l) {
			padding: @panel-grid-l;
			margin-bottom: @panel-grid-l;
		}

		.border-panel();
		border-radius: @border-radius;

		p {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		[data-loading]::before {
			width: 20px;
			height: 20px;
			margin-left: ~'calc(50% - 10px)';
		}

		&--info {
			border-color: @blue-grey-light;
			background-color: hsla(193, 52.9%, 96.7%, 1.0);
		}

		&--warning {
			border-color: @orange;
			background-color: hsla(34, 88.9%, 96.5%, 1.0);
		}
	}
}

[data-iris="loaded"] { // FIXME: when old grid is removed, remove this
	padding-left: 0 !important;
	padding-right: 0 !important;
}
