
// TODO: Move the viewport-s to core-less-imports
// difference between breakpoint-s (max-width) and breakpoint-m (min-width) should be minimal,
// as otherwise a 'black-hole' will be created where none or both of the styles will be applied when
// reaching the exact breakpoint or you're exactly in between
@viewport-s: (@viewport-m - 0.001);
@breakpoint-s: 	~"max-width: @{viewport-s}";

.quick-to-spacer {
    min-height: 8.5rem;
     margin-top: ( @grid-offset-l * -1.8 );

	@media (@breakpoint-m) {
		margin-top: ( @grid-offset-l * -2.75 );

	}
}

.quick-to-wrapper {
  ._flexed-outer-grid-padding();
  .grid('balanced', 3);
	@media (@breakpoint-m) {
		justify-content: center;
		& > * {
			max-width: unit(100 / 3,%);
		}
	}
}

.panel-quick-to {
  &:extend(._panel all);
  padding-bottom: 0;

  .panel-body {
    &:extend(._panel-attention all);
    flex: 1;

    header h3 {
      &:extend(._panel-title-h3 all);

      > a {
        padding: 0;
        text-align: left;
        font-weight: normal;
        font-size: inherit;

        &:after {
          font-size: @font-size-xs;
        }
      }
    }


    ul {
      &:extend(.list-linklist all);


      > li a {
        padding: @rem-xs 0 @rem-xs 0;
        font-weight: normal;
        line-height: @line-height-base;
        font-size: @font-size-m;
        font-family: @anwb-font-family;

        &:hover {
          color: @blue;
        }
      }

      > li:first-of-type {
        margin-top: @offset-xs;
      }

      > li:last-of-type a {
        padding-bottom: 0;
      }
    }
  }
}



// because the usage of the @breakpoint-s can be confusing, the mobile styling is done separately below
.panel-quick-to {
  @media (@breakpoint-s) {

    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;

    &:first-of-type {
      > .panel-body {
        border-top-left-radius: @border-radius;
        border-top-right-radius: @border-radius;
      }
    }

    &:last-of-type {
      > .panel-body {
        border-bottom: 0;
        border-bottom-left-radius: @border-radius;
        border-bottom-right-radius: @border-radius;
      }
    }

    .panel-body {
      border-radius: 0;
      border-bottom: @line solid @blue-grey;

      header h3 a {
        padding-top: @line;
        padding-bottom: @line;
      }

      > div {
        .hide();
      }
    }
  }
}