
@images_path:       'images/';

@anwb_logo :        '@{images_path}anwb-logo.svg';
@circle-filter :    '@{images_path}image-circle-filter.svg';
@star-o :           '@{images_path}star-o.svg';
@exclamation-sign:           '@{images_path}warning.svg';

// hide image beacons injected from Google Tag Manager (will always be injected as body child)
body > img {
	display: none;
}

img {
	width: 100%;
	margin-top: @offset-s;
	margin-bottom: @rem;
	border-radius: @border-radius;
	border: 0;

	@media (@breakpoint-l) {
		max-width: 100%;
	}

	&.half {
		@media (@breakpoint-m) {
			max-width: 370px;
			width: 50%;
			float: left;
			margin-right: @rem;
		}

		&-inset {
			@media (@breakpoint-m){
				max-width: 370px;
				width: 50%;
				float: right;
				margin-left: @rem;
			}
		}
	}
}

._legacy-image,
article img:not([srcset]):not([lazy-srcset]),
.panel-main img:not([srcset]):not([lazy-srcset]) {
	
	width: auto;
	max-width: 100%;

	&:not(.half-inset) {
		float: left;
		margin-right: @rem;
	}

}

figure {
	margin: @rem-xs;
}

.mask-img {
	mask-image: url(@circle-filter);
	mask-repeat: no-repeat;
	img {

		@media (@breakpoint-l) {
			width: 100%;
		}
	}
}

// images that have a href
a > img {
	border: none;
}

header > a > img[srcset] {
	margin-top: 0;
}