/**
 * Experimental CSS variables scoped to ':root'
 * - breakpoints
 */
:root {
	//--breakpoint-s: e("(max-width: @{viewport-s})");
	//--breakpoint-m: e("(min-width: @{viewport-l})");
	//--breakpoint-l: e("(min-width: @{viewport-l})");
	//--green: @green;
	--breakpoint: "s";
	--rem: @rem;
}

/*
 * Hide the image to prevent showing the browser's native placeholder while the image is still loading.
 * Keep the image observable for intersections as required by the lazy-loader.
 */
img[lazy-src],
img[lazy-srcset] {
	visibility: hidden;
}

body::before {
	content: "breakpoint-s";
	display: none; // Prevent from displaying.
}

@media (@breakpoint-m) {
	:root {
		--breakpoint: "m";
	}
	body::before {
		content: "breakpoint-m";
	}
}

@media (@breakpoint-g) {
	:root {
		--breakpoint: "g";
	}
	body::before {
		content: "breakpoint-g";
	}
}

@media (@breakpoint-l) {
	:root {
		--breakpoint: "l";
	}
	body::before {
		content: "breakpoint-l";
	}
}

/**
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

._refactor-alert(){
	& > * {
		filter: sepia(100%);
	}
}

._parent-reset {
	margin-right: auto;
	margin-left: auto;
	max-width: (@viewport-l-max);
}
// Todo: refactor padding / margin with BASE variables
// SASOI-2179

html {
    scroll-behavior: smooth;
}

body {
	height: 100vh;
	// The page is responsive and should therefore never scroll horizontally.
	// Hide the scrollbar in case some crappy component causes the page to be wider than the viewport.
	overflow-x: hidden;

	& > header {
		//._refactor-alert();
		& > .utilities {
			&:extend(._parent-reset);
			padding-right: @margin-text-m;
			padding-left: @margin-text-m;

			@media (@breakpoint-m--menu) {
				padding-right: @margin-text-m + .5em;
			}
			@media (@breakpoint-l) {
				padding-right: @margin-text-l + 0.1em;
			}
		}

		@media (@breakpoint-m--menu) {
			& > .menu {
				//._parent-reset;
				//padding-right: @margin-text-m;
				//padding-left: @margin-text-m;
			}
		}

	}

	// End refactor
	& > footer {
		.footer {
			background-color: @blue-dark;

			a {
				text-decoration: none;
			}

			> .footer-block {
				color: @white;
			}

			// @FIXME
			.panel,
			.panel-basic {
				padding: 0;
				margin-bottom: 0;
			}

		}

		.footer-disclaimer {
			&:extend(._parent-reset);
			background-color: white;
			padding-top: @rem;
			padding-bottom: @rem;
		}
	}
}

// make sure there's never any whitespace below the footer
// Excluding IE (no need to deal with orientation change or touch devices)
// TODO: Not a good idea to have flex box here. (considered technical debt)
// TODO: Move to CSS Grid Layout when broadly available
@supports (display: flex) {
	body {
		display: flex;
		flex-direction: column;
		& > main {
			flex: 1 0 auto;
		}
		& > header,
		& > footer {
			// this is required for Safari 9
			flex: none;
		}
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	body {
		display: block;
	}
}

// all iframes in page content.
body> main iframe {
	border: 0;
	width: 100%;
	// initial height will be specified inline, after that, height will be dynamically calculated if accessible
}

html[framed] > body {
	// Make sure the height isn't determined by the viewport.
	height: auto!important;
	// Compensate for any outer padding when we're inside an iframe.
	// The parent window's document is responsible for applying the correct padding given the iframe's context.
	// Het is beter om dit voorlopig per applicatie op te lossen i.p.v. globaal.
	// & > main {
	// 	// Quick-n-dirty: structurele oplossing is bestaande selectors uitbreiden met html:not([framed])
	// 	margin: -0.4rem;
	// 	@media (min-width: 40em) {
	// 		margin: -0.6rem;
	// 	}
	// 	@media (min-width: 70em) {
	// 		margin: -0.8rem;
	// 	}
	// }
}

// Deprioritize downloading background-images.
// The loading attribute is set from javascript and removed on DOMContentLoaded.
// Not header because of logo.
html[loading] > body > :not(header) * {
	background-image: none!important;
}

// Overrule alle transities voor synthetic session types t.b.v. korte doorlooptijd van tests/checks.
html[session-type="synthetic"],
html[session-type="synthetic"] * {
	scroll-behavior: auto!important;
	transition: none!important;
}

// Forceer intersection detectie.
[mount="pending"] {
	min-width: 1px;
	min-height: 1px;
}

// Give main applications a minimal height while not loaded for a cleaner loading experience.
body > main > section > .grid-full			[data-hook]:not([data-hook=""]),
body > main > section > .grid-full			[data-hook][data-loading],
body > main > section > .grid-content > div [data-hook]:not([data-hook=""]),
body > main > section > .grid-content > div [data-hook][data-loading] {
	min-height: 60vh;
}

// TODO: create seperate stylesheet and 'application' for content blocker.
.embed-container__dnt {
	background-color: @blue-grey-lighter;
	text-align: center;
	overflow: hidden;

	.embed-container__dnt-message {
		display: block;
		padding: 0 1rem;
		font-size: @font-size-xs;
		color: @blue-grey-dark;
		@media (min-width: 256px) {
			margin-top: 1em;
		}

		&:before {
			.icon;
			.icon-jumbo;
			content: '@{var-icon-eye-off}';
			width: 100%;
			display: none;
			@media (min-width: 348px) {
				display: inline-block;
			}

			@media (@breakpoint-m) {
				margin: 1em 0;
			}
			@media (@breakpoint-s) {
				margin: .5em 0;
			}
		}
	}

	.embed-container__dnt-override {
		position: absolute;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		bottom: 5%;
		min-width: 168px;
		width: 60%;
		white-space: nowrap;
		background-color: @blue-grey-lighter;

		&::after {
			.icon;
			.icon-xxl;
			content: @var-icon-eye;
		}
	}
}
