.checkbox_box() {
	width: 1.2rem;
	height: 1.2rem;

	top: 0;
	left: 0;

	margin-top: 0.2rem;

	background-color: @input-bg;
	border: @line solid @blue-grey;

	content: '';

	position: absolute;
	display: inline-block;
}

.checkbox_check() {
	width: 1.2rem;
	height: 1.2rem;

	top: 0;
	left: 0;

	margin-top: 0.2rem;
	background-image: url('images/input-checkbox-checked.svg');
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 0.8rem;

	content: '';

	position: absolute;
	display: inline-block;
}

.@{anwb-prefix}filters {
	@block: ~"@{anwb-prefix}filters";

	ul&,
	ol&,
	> ul,
	> ol {
		.reset-list();
	}

	border-top: 2px solid @blue-grey-light;
	padding: 1rem 0;

	&:last-child {
		border-bottom: 2px solid @blue-grey;
		margin-bottom: 1rem;
	}

	&__title {
		font-size: @font-size;
		margin: 0;
	}

	&__indicator {
		cursor: pointer;
		display: block;
		position: relative;

		&::after {
			&:extend(.icon);
			display: inline-block;
			color: @blue;
			font-size: 16px;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -8px;

			content: @var-icon-chevron-up;
		}
	}

	&__item {
		margin: 0;
		padding: .2rem 0;

		a {
			text-decoration: none;
			color: @blue-dark;

			&:hover {
				color: @blue;
			}

			display: inline-block;
			position: relative;
			padding-left: 1.6rem;

			&:before {
				.checkbox_box();
			}
		}

		&.active, // server-side (?) // or &--active ?
		&--active,
		&[data-active] { // client-side (?)
			a {
				&:after {
					.checkbox_check();
					//transition: background-size .1s;
				}

				//&:hover:after {
				//  background-size: .6rem;
				//}
			}
		}
	}

	[data-collapse]:not(:checked) {
		~ .@{block}__list {
			display: none;
		}

		~ .@{block}__title {
			.@{block}__indicator {

				&:after {
					content: @var-icon-chevron-down;
				}
			}
		}
	}
}
