.panel-poll {
	&:extend(._panel all);

	> .panel-body {
		&:extend(._panel-border all);

		button {
			margin-top: @rem-xs;
		}
	}
}

// progress
.progress {
	line-height: @line-height-s;
}

.progress-value {
	font-style: italic;
	min-width: @rem-component-l;
}