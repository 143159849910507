/**
 * Basic foundation for typography for the anwb website to build upon.
 */

/**
 * box-sizing ftw
 */

*, *:before, *:after {
	box-sizing: inherit;
}


/**
 * Main selctors
 */

html {
	box-sizing: border-box;
	font-size: @rem-base;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}



body {
	margin: 0;
	color: @blue-grey-darker;
	font-size: @font-size;
	font-family: @default-fonts;
	line-height: @line-height-base;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

/**
 * Heading selectors (h1-h3)
 */

h1, h2, h3, h4, h5, h6 {
	line-height: @line-height-s;
}

h1, h2, h3, h4, h5, h6, th  {
	margin-top: 0;
	max-width: @max-text-width;
	text-rendering: optimizeLegibility;
	color: @blue-dark;
	
	.hyphenate();
	@media(@breakpoint-l) {
		// Prevent hyphenation on large breakpoint
		word-break: normal;
		hyphens: none;
	}
}

h1 {
	.anwb-thesans-bold-italic();
	font-size: @heading-l;
	margin-bottom: @margin-text-s;
	max-width: none;

	@media (@breakpoint-m) {
		font-size: @heading-xl;
		margin-bottom: @margin-text-m;
	}

	@media (@breakpoint-l) {
		font-size: @heading-xxl;
	}
	+ p, + ol, + ul {
		margin-top: @margin-text-l;
		@media (@breakpoint-m) {
			margin-top: @margin-text-xl;
		}
	}
}

h2 {
	.anwb-thesans-bold();
	font-size: @heading-m;
	margin-bottom: @margin-text-m;

	@media (@breakpoint-m) {
		margin-bottom: @margin-text-l;
	}
}

h3, h4 {
	.anwb-thesans-bold();
	font-size: @heading-s;
	margin-bottom: @margin-text-m;

	@media (@breakpoint-m) {
		margin-bottom: @margin-text-l;
		+ small {
			margin-top: -@margin-text-s;
		}
	}
}

h5, h6 {
	.anwb-thesans-bold();
	font-size: @margin-text-l;
	margin-bottom: @margin-text-m;

	@media (@breakpoint-m) {
		margin-bottom: @margin-text-l;
		+ small {
			margin-top: -@margin-text-s;
		}
	}
}

small{
	font-size: @font-size-s;
	color: @blue-grey-dark;
	line-height: @line-height-base;
}

// required by Xinha editor
.nobr {
	white-space: nowrap;
}

.typographic-margin(){
	margin-top: @margin-text-l;
	margin-bottom: @margin-text-l;
	max-width: @max-text-width;

	@media (@breakpoint-m) {
		margin-bottom: @margin-text-xl;
	}
}

// paragraph settings
p, ol, ul {
	.typographic-margin();
}



h2 {
	+ p, + ol, + ul {
		margin-top: @margin-text-negative-s;

		@media (@breakpoint-m) {
			margin-top: @margin-text-negative-m;
		}
	}
}

h3, h4, h5, h6 {
	+ p, + ol, + ul {
		margin-top: @margin-text-negative-s;

		@media (@breakpoint-m) {
			margin-top: @margin-text-negative-l;
		}
	}
}

b,
strong {
	font-weight: bold;
	max-width: @max-text-width;
}

::-moz-selection {
	background: @blue-grey-light;
	text-shadow: none;
}

::selection {
	background: @blue-grey-light;
	text-shadow: none;
}

h3 + ul,
small + ul {
	margin-top: @rem-xs;
}

.subtitle {
	&:extend(h3);
	color: @blue;
}

.summary {
	color: @blue;
	margin-top: 0;


	& time {
		font-style: italic;
	}
}

.mainsummary {
	margin-top: @offset-l;
}


// moved from initialized
audio,
canvas,
progress,
video {
	vertical-align: baseline;
}



abbr[title] {
	border-bottom: @line dotted;
}


dfn {
	font-style: italic;
}


small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

code,
kbd,
pre,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}
pre {
	overflow: auto;
}

hr {
	display: block;
	height: @line;
	border: 0;
	border-top: @line solid @blue-grey;
	margin: @rem 0;
	padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

details,
summary {
	display: block;
}
