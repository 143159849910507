.@{anwb-prefix}tabbox {
	@block: ~"@{anwb-prefix}tabbox";

	// Number of tabs possible per tabbox (note: not affecting accordion types)
	//  keep low to prevent exponential growth of css and layout issues
	//  if the content needs more tabs, maybe another layout is more suitable.
	@numtabs: 5;
	@numtabs_more: 7;

	.__gridsupport(@rules) {
		// browsers that support grids
		@supports (display: grid) {
			@rules();
		}
		// and IE11
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			@rules();
		}
	}

	.__tabareas(@counter) when (@counter > 0) {
		.__tabareas((@counter - 1));    // next iteration

		.@{block}__controller:nth-of-type(@{counter}),
		.@{block}__tab:nth-of-type(@{counter}) {
			grid-area: ~"tab@{counter}";
		}
	}

	.__tabareas_horizontal_ie(@counter, @start, @row: 1) when (@counter > 0) {
		.__tabareas_horizontal_ie((@counter - 1), @start, @row);    // next iteration

		.@{block}__tab:nth-of-type(@{counter}) {
			-ms-grid-row: @row;
			-ms-grid-column: @start + @counter;
		}
	}

	.__tabareas_vertical_ie(@counter, @start, @row: 1) when (@counter > 0) {
		.__tabareas_vertical_ie((@counter - 1), @start, @row);    // next iteration

		.@{block}__tab:nth-of-type(@{counter}) {
			-ms-grid-column: @row;
			-ms-grid-row: @start + @counter;
		}
	}

	.__templateareas(@tabs, @type: 'top') {
		//grid-template-areas+_: ~"";

		// internal mixins
		.___horizontal(@tabs: 1, @row: 'tabs') when (@tabs > 0) {
			.___horizontal(@tabs: (@tabs - 1), @row: @row);    // next iteration

			& when (@row = 'tabs') {
				grid-template-areas+_: ~"tab@{tabs}";
			}
			& when (@row = 'boxes') {
				grid-template-areas+_: ~"box";
			}
		}
		.___vertical(@tabs: 1) when (@tabs > 0) {
			.___vertical((@tabs - 1));	// next iteration

			grid-template-areas+_: ~"'tab@{tabs} box'";
		}

		.___build() when (@type = 'pills') {
			// ".   tab1 tab2 tab3 tab4 tab5 ."
			// "box box  box  box  box  box  box";

			grid-template-areas+_: ~"' .";
			.___horizontal(@tabs: @tabs, @row: 'tabs');
			grid-template-areas+_: ~". '";

			grid-template-areas+_: ~"' box";
			.___horizontal(@tabs: @tabs, @row: 'boxes');
			grid-template-areas+_: ~"box '";
		}

		.___build() when (@type = 'top') {
			// "tab1 tab2 tab3 tab4 tab5 ."
			// "box  box  box  box  box  box";

			grid-template-areas+_: ~"'";
			.___horizontal(@tabs: @tabs, @row: 'tabs');
			grid-template-areas+_: ~". '";

			grid-template-areas+_: ~"'";
			.___horizontal(@tabs: @tabs, @row: 'boxes');
			grid-template-areas+_: ~"box '";
		}

		.___build() when (@type = 'bottom') {
			// "box  box  box  box  box  box"
			// "tab1 tab2 tab3 tab4 tab5 .";

			grid-template-areas+_: ~"'";
			.___horizontal(@tabs: @tabs, @row: 'boxes');
			grid-template-areas+_: ~"box'";

			grid-template-areas+_: ~"'";
			.___horizontal(@tabs: @tabs, @row: 'tabs');
			grid-template-areas+_: ~".'";
		}

		.___build() when (@type = 'side') {
			// "tab1 box"
			// "tab2 box"
			// "tab3 box"
			// "tab4 box"
			// "tab5 box"
			// ".    box";

			.___vertical(@tabs: @tabs);
			grid-template-areas+_: ~"'. box'";
		}

		.___build();
	}

	margin-bottom: 1rem;

	&__tab {
		margin: 0;
		max-width: 100%;
		.border-panel();
		background-color: @blue-grey-lighter;
		color: @blue;

		@media (-moz-touch-enabled: 1) {
			//&:hover {
				color: @yellow;
			//}
		}

		label {
			cursor: pointer;
			display: block;
			padding: @rem @grid-unit-m;
			font-family: @anwb-font-family;
			font-size: @heading-panel-m;
			font-style: italic;
			line-height: @line-height-compressed;
			font-weight: normal;

			@media (@breakpoint-l) {
				padding-right: @rem-l;
				padding-left: @rem-l;
			}

			@media (hover: hover), (-moz-touch-enabled: 0) {
				&:hover {
					color: @blue-dark;
				}
			}
		}

		.@{block}__controller:focus + & {
			label {
				//border: 1px dotted @blue-dark;
				//border-radius: @border-radius;
				//margin: -1px;
				color: @orange;
			}
		}

		.@{block}__controller:checked + & {
			background-color: @white;
			z-index: 1;
			color: @blue-dark;

			@media (hover: hover), (-moz-touch-enabled: 0) {
				label:hover {
					color: @blue;
				}
			}
		}

		[type="radio"].@{block}__controller:checked + & {
			pointer-events: none;
		}
	}

	&__box {
		background-color: @white;
		padding: @rem @grid-unit-m;
		.border-panel();
		display: none;

		.@{block}__controller:checked + .@{block}__tab + & {
			display: block;
		}
	}


	// These are the styles used in accordion (and default) styles (also mobile version):
	&__box,
	&__tab:nth-of-type(n+2) {
		border-top: 0 none;
	}

	&__box {
		&:last-of-type {
			border-bottom-left-radius: @border-radius;
			border-bottom-right-radius: @border-radius;
		}
	}

	&__tab {
		position: relative;

		&::after {
			content: @var-icon-chevron-down;
			position: absolute;
			display: inline-block;
			font-size: 16px;
			font-style: normal;
			font-weight: normal;
			line-height: inherit;
			right: @rem;
			top: @rem;
			transition: transform .2s;
			pointer-events: none;
		}

		@media (hover: hover), (-moz-touch-enabled: 0) {
			&:hover::after {
				transform: translateY(@line);
			}
		}

		&:first-of-type {
			border-top-left-radius: @border-radius;
			border-top-right-radius: @border-radius;
		}

		&:last-of-type {
			border-bottom-left-radius: @border-radius;
			border-bottom-right-radius: @border-radius;
		}
	}

	&__controller:checked + &__tab {
		border-bottom: 0 none;

		&::after {
			//content: @var-icon-chevron-up;
			transform: rotate(180deg);
		}

		@media (hover: hover), (-moz-touch-enabled: 0) {
			&:hover::after {
				transform: rotate(180deg) translateY(@line);
			}
		}
	}

	&__controller:checked + &__tab:last-of-type {
		border-radius: 0;
	}

	&__controller:checked + &__tab + &__box {
		padding-top: 0;
	}
	// end accordion styles

	// faq list style:
	&--slats,
	&--steps {
		border-bottom: @line solid @blue-grey-light;

		.@{block}__tab,
		.@{block}__box {
			background-color: transparent;
			border-radius: 0;
			border: 0 none;
		}

		.@{block}__tab {
			border-top: @line solid @blue-grey-light;
			color: @blue-dark;

			label {
				padding-left: 0;
				padding-right: 2rem;
				font-style: normal;
				font-weight: bold;
				font-size: @heading-panel-s;

				@media (hover: hover), (-moz-touch-enabled: 0) {
					&:hover {
						color: @blue;
					}
				}
			}
		}

		.@{block}__controller:checked + .@{block}__tab {
			background-color: transparent;
		}

		.@{block}__box {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&--steps {
		.@{block}__controller:checked + .@{block}__tab {
			color: @orange;
		}

		@media (@breakpoint-m) {
			//@supports (display: grid) { // ie11 does not support supports, but does support some css grid
				& {
					display: grid;
					justify-content: stretch;
					align-items: stretch;

					.__tabareas(@numtabs_more);

					.@{block}__box {
						grid-area: box;
					}

					grid-template-columns: repeat(@numtabs_more, auto) 1fr;
					grid-template-rows: auto;
					.__templateareas(@tabs: @numtabs_more, @type: 'top');
					//grid-template-areas:
					//		"tab1 tab2 tab3 tab4 tab5 ."
					//		"box  box  box  box  box  box";
					align-items: end;
					grid-column-gap: @grid-unit-m;
					border-bottom: 0 none;

					// IE10 / IE11
					@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
						.@{block}__tab {
							margin-right: @grid-unit-m;
						}
					}
					// Edge
					@supports not (grid-gap: 1rem) {
						.@{block}__tab {
							margin-right: @grid-unit-m;
						}
					}

					// IE / Edge grid version:
					.__tabareas_horizontal_ie(@numtabs_more, 0, 1);

					.@{block}__box {
						-ms-grid-row: 2;
						-ms-grid-column: 1;
						-ms-grid-column-span: @numtabs_more + 1;
					}

					.@{block}__tab {
						border-top: 0 none;
						border-bottom: @line solid transparent;
						margin-bottom: -@line;

						&::after {
							display: none;
						}

						label {
							padding-top: .5rem;
							padding-bottom: .5rem;
							padding-right: 0;
						}
					}

					.@{block}__box {
						position: relative;
						//top: -@line;
					}

					.@{block}__controller:focus + .@{block}__tab {
						label {
							border: 1px dotted @blue-dark;
							border-bottom-color: transparent;
							border-radius: @border-radius;
							margin: -1px;
							padding-left: .5rem;
							padding-right: .5rem;
							margin-left: ~"calc(-.5rem - 1px)";
							margin-right: ~"calc(-.5rem - 1px)";
						}
					}

					.@{block}__controller:checked + .@{block}__tab {
						border-bottom: @line solid @orange;
					}

					.@{block}__controller:checked + .@{block}__tab + .@{block}__box {
						border-top: @line solid @blue-grey-light;
						padding-top: @rem;
					}
				}
			//}
		}
	}

	&--pills,
	&--top,
	&--bottom {
		.@{block}__controller,
		.@{block}__tab,
		.@{block}__box {
			@manytabs: @numtabs+1;
			&:nth-of-type(1n + @{manytabs}) {
				display: none;
			}
		}
	}
	&--steps,
	&--side {
		.@{block}__controller,
		.@{block}__tab,
		.@{block}__box {
			@manytabs: @numtabs_more+1;
			&:nth-of-type(1n + @{manytabs}) {
				display: none;
			}
		}
	}

	// Other styles:
	@media (@breakpoint-m) {
		//@supports (display: grid) { // ie11 does not support supports, but does support some css grid
			&--pills,
			&--top,
			&--bottom,
			&--side {
				//resetting mobile first styles
				.@{block}__tab,
				.@{block}__box {
					//.border-panel();
					border-width: @line;
					border-style: solid;
				}

				.@{block}__tab::after {
					display: none;
				}

				.@{block}__controller:checked + .@{block}__tab::after {
					transform: none;
				}

				input[type="checkbox"].@{block}__controller,
				input[type="radio"].@{block}__controller {
					// fixes click jitter...
					position: relative;
					opacity: 0;
				}

				.@{block}__controller:checked + .@{block}__tab + .@{block}__box {
					padding-top: @rem;
				}
				// end resetting for now...

				display: grid;
				justify-content: stretch;
				align-items: stretch;

				//.__tabareas(@numtabs);
				//
				//.@{block}__box {
				//	grid-area: box;
				//}
			}

			&--pills,
			&--top,
			&--bottom {
				.__tabareas(@numtabs);

				.@{block}__box {
					grid-area: box;
				}
			}
			&--side {
				.__tabareas(@numtabs_more);

				.@{block}__box {
					grid-area: box;
				}
			}

			&--pills {
				grid-template-columns: 1fr repeat(@numtabs, auto) 1fr;
				grid-template-rows: auto;
				.__templateareas(@tabs: @numtabs, @type: 'pills');
				//grid-template-areas:
				//		".   tab1 tab2 tab3 tab4 tab5 ."
				//		"box box  box  box  box  box  box";
				align-items: end;
				grid-gap: 1rem;

				// IE10 / IE11
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					.@{block}__tab {
						margin-bottom: 1rem;
						margin-left: .5rem;
						margin-right: .5rem;
					}
				}
				// Edge
				@supports not (grid-gap: 1rem) {
					.@{block}__tab {
						margin-bottom: 1rem;
						margin-left: .5rem;
						margin-right: .5rem;
					}
				}

				// IE / Edge grid version:
				.__tabareas_horizontal_ie(@numtabs, 1, 1);

				.@{block}__tab {
					-ms-grid-row-align: end;
				}

				.@{block}__box {
					-ms-grid-row: 2;
					-ms-grid-column: 1;
					-ms-grid-column-span: @numtabs + 2;
				}

				.@{block}__box,
				.@{block}__tab {
					border-radius: @border-radius;
					border-color: @blue-grey-light; // resetting
				}

				.@{block}__tab {
					text-align: center;

					label {
						padding: @rem-s;
					}

					&::after {
						content: '';
						display: inline-block;
						top: auto;
						right: auto;
						left: ~'calc(50% - 10px)';
						bottom: -10px; // - @line;
						position: absolute;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-top: 10px solid transparent;
						border-top-color: inherit;
						opacity: 0;
						transition: opacity .25s;
					}

					@media (hover: hover), (-moz-touch-enabled: 0) {
						&:hover::after {
							opacity: 1;
						}
					}
				}

				.@{block}__controller:checked + .@{block}__tab {
					position: relative;
					border: @line solid @blue-grey-light; // resetting
					border-radius: @border-radius; // resetting

					label {
						padding-bottom: @rem-s; // resetting
					}

					&:after {
						bottom: -10px - @line;
						opacity: 1;
					}
				}
			}

			&--top {
				grid-template-columns: repeat(@numtabs, auto) 1fr;
				grid-template-rows: auto;
				.__templateareas(@tabs: @numtabs, @type: 'top');
				//grid-template-areas:
				//		"tab1 tab2 tab3 tab4 tab5 ."
				//		"box  box  box  box  box  box";

				// IE / Edge grid version:
				.__tabareas_horizontal_ie(@numtabs, 0, 1);

				.@{block}__box {
					-ms-grid-row: 2;
					-ms-grid-column: 1;
					-ms-grid-column-span: @numtabs + 1;
				}

				.@{block}__box,
				.@{block}__tab {
					border-color: @blue-grey-light; // resetting
				}

				.@{block}__box {
					border-bottom-left-radius: @border-radius;
					border-bottom-right-radius: @border-radius;
				}

				.@{block}__tab {
					margin-bottom: -@line;
					border-radius: 0; // resetting
					display: flex;
					align-items: center;

					&:nth-of-type(n+2) {
						border-left: 0 none;
					}

					&:first-of-type {
						border-top-left-radius: @border-radius;
					}

					&:last-of-type {
						border-top-right-radius: @border-radius;
					}
				}

				.@{block}__controller:focus + .@{block}__tab {
					label {
						border-bottom-width: 0;
					}
				}

				.@{block}__controller:checked + .@{block}__tab {
					border-bottom: 0 none;
					padding-bottom: @line;

					label {
						padding-bottom: @rem; // resetting
					}

					&:first-of-type {
						border-top-left-radius: @border-radius; //resetting
					}

					&:last-of-type {
						border-top-right-radius: @border-radius; //resetting
					}
				}
			}

			&--bottom {
				grid-template-columns: repeat(@numtabs, auto) 1fr;
				grid-template-rows: auto;
				.__templateareas(@tabs: @numtabs, @type: 'bottom');
				//grid-template-areas:
				//		"box  box  box  box  box  box"
				//		"tab1 tab2 tab3 tab4 tab5 .";

				// IE / Edge grid version:
				.__tabareas_horizontal_ie(@numtabs, 0, 2);

				.@{block}__box {
					-ms-grid-row: 1;
					-ms-grid-column: 1;
					-ms-grid-column-span: @numtabs + 1;
				}

				.@{block}__box,
				.@{block}__tab {
					border-color: @blue-grey-light; // resetting
				}

				.@{block}__box {
					margin-bottom: -@line;
					border-top-left-radius: @border-radius;
					border-top-right-radius: @border-radius;
					border-bottom-left-radius: 0; // resetting
					border-bottom-right-radius: 0; // resetting
				}

				.@{block}__tab {
					border-radius: 0; // resetting
					display: flex;
					align-items: center;

					&:nth-of-type(n+2) {
						border-left: 0 none;
					}

					&:first-of-type {
						border-bottom-left-radius: @border-radius;
					}

					&:last-of-type {
						border-bottom-right-radius: @border-radius;
					}
				}

				.@{block}__controller:focus + .@{block}__tab {
					label {
						border-top-width: 0;
					}
				}

				.@{block}__controller:checked + .@{block}__tab {
					border-bottom: @line solid @blue-grey-light; // resetting
					border-top: 0 none;
					padding-top: @line;

					label {
						padding-bottom: @rem; // resetting
					}

					&:first-of-type {
						border-bottom-left-radius: @border-radius; // resetting
					}

					&:last-of-type {
						border-bottom-right-radius: @border-radius; // resetting
					}
				}
			}

			&--side {
				grid-template-columns: 4rem 2fr;
				grid-template-rows: repeat(@numtabs_more, auto) 1fr;
				.__templateareas(@tabs: @numtabs_more, @type: 'side');
				//grid-template-areas:
				//		"tab1 box"
				//		"tab2 box"
				//		"tab3 box"
				//		"tab4 box"
				//		"tab5 box"
				//		".    box";

				@media (@breakpoint-g) {
					grid-template-columns: 1fr 2fr;
				}

				// IE / Edge grid version:
				.__tabareas_vertical_ie(@numtabs_more, 0, 1);

				.@{block}__box {
					-ms-grid-row: 1;
					-ms-grid-column: 2;
					-ms-grid-row-span: @numtabs_more + 1;
				}

				.@{block}__box,
				.@{block}__tab {
					border-color: @blue-grey-light; // resetting
				}
				
				.__gridsupport({
					.@{block}__box {
						margin-left: -@line;
						border-top-right-radius: @border-radius;
						border-bottom-right-radius: @border-radius;
						border-bottom-left-radius: 0; // resetting
					}

					.@{block}__tab {
						border-radius: 0; // resetting
						writing-mode: vertical-lr;

						@media (@breakpoint-g) {
							writing-mode: horizontal-tb;
						}

						&:nth-of-type(n+2) {
							border-top: 0 none;
						}

						&:first-of-type {
							border-top-left-radius: @border-radius;
						}

						&:last-of-type {
							border-bottom-left-radius: @border-radius;
						}
					}

					.@{block}__controller:focus + .@{block}__tab {
						label {
							border-right-width: 0;
						}
					}

					.@{block}__controller:checked + .@{block}__tab {
						border-bottom: @line solid @blue-grey-light; // resetting
						border-right: 0 none;
						padding-right: @line;

						label {
							padding-bottom: @rem; // resetting
						}

						&:last-of-type {
							border-bottom-left-radius: @border-radius; // resetting
						}
					}
				});
			}
		//}

	}

	&--attention {
		.@{block}--slats& {
			border-color: @white;
		}

		.@{block}--steps& {
			border-color: @blue-dark;
		}

		.@{block}__tab,
		.@{block}__box {
			background-color: @blue-grey-light;
			border-bottom-color: @white;
			border-left-width: 0;
			border-right-width: 0;

			&:last-of-type {
				border-bottom-color: @blue-grey-light;
			}

			.@{block}--accordion& { // to keep it the same as on mobile, the unmodifiered tabbox will still have radii
				border-radius: 0;
			}
			@media (@breakpoint-s) { // not mobile first (to much resetting needed...)
				border-radius: 0;
			}

			.@{block}--slats& {
				border-color: @white;
				padding-left: 0;
				padding-right: 0;
			}

			.@{block}--steps& {
				border-color: @white;

				@media (@breakpoint-m) {
					border-color: transparent;
				}
			}

			.@{block}--pills&,
			.@{block}--top&,
			.@{block}--bottom&,
			.@{block}--side& {
				@media (@breakpoint-m) {
					//@supports (display: grid) {
						& {
							border-bottom-color: @blue-grey-light;
							border-left-width: @line;
							border-right-width: @line;
						}
					//}
				}
			}
		}

		.@{block}__tab {
			color: @blue-dark;
			padding-left: @line;
			padding-right: @line;

			@media (hover: hover), (-moz-touch-enabled: 0) {
				label:hover {
					color: @blue;
				}
			}

			.@{block}--steps& {
				&:first-of-type {
					margin-left: ~'calc(@{grid-unit-m} + @{line})';
				}

				@media (@breakpoint-m) {
					background-color: transparent;
				}
			}

			.@{block}--pills&,
			.@{block}--top&,
			.@{block}--bottom&,
			.@{block}--side& {
				@media (@breakpoint-m) {
					//@supports (display: grid) {
						& {
							padding-left: 0;
							padding-right: 0;
						}
					//}
				}
			}

			.@{block}--top&,
			.@{block}--bottom&,
			.@{block}--side& {
				@media (@breakpoint-m) {
					//@supports (display: grid) {
						& {
							background-color: @blue-grey-lighter;
							border-color: @blue-grey-light;
							color: @blue;

							@media (hover: hover), (-moz-touch-enabled: 0) {
								label:hover {
									color: @blue-dark;
								}
							}
						}
					//}
				}
			}
		}

		.@{block}__box {
			padding-left: ~'calc(@{grid-unit-m} + @{line})';
			padding-right: ~'calc(@{grid-unit-m} + @{line})';

			.@{block}--pills&,
			.@{block}--top&,
			.@{block}--bottom&,
			.@{block}--side& {
				@media (@breakpoint-m) {
					//@supports (display: grid) {
						& {
							padding-left: @grid-unit-m;
							padding-right: @grid-unit-m;
						}
					//}
				}
			}
		}

		.@{block}__controller:focus + .@{block}__tab {
			.@{block}--pills& {
				@media (@breakpoint-m) {
					//@supports (display: grid) {
						label {
							color: inherit;
							border: 1px dotted @white;
							margin: -1px;
							border-radius: @border-radius;
						}
					//}
				}
			}
		}

		.@{block}__controller:checked + .@{block}__tab {
			background-color: @blue-grey-light;


			.@{block}--steps& {
				@media (@breakpoint-m) {
					background-color: transparent;
				}
			}

			.@{block}--top&,
			.@{block}--bottom&,
			.@{block}--side& {
				@media (@breakpoint-m) {
					//@supports (display: grid) {
						& {
							color: @blue-dark;
						}
					//}
				}
			}

			.@{block}--pills& {
				@media (@breakpoint-m) {
					//@supports (display: grid) {
						& {
							background-color: @blue-dark;
							border-color: @blue-dark;

							color: @white;
						}
					//}
				}
			}
		}

		.@{block}__controller:checked + .@{block}__tab + .@{block}__box {
			.@{block}--steps& {
				border-color: @blue-dark;
			}
		}
	}

	// interactions with other components
	& + .@{anwb-prefix}title {
		margin-top: @rem-component-m;
	}
}
