
@duration-in: 0.3s;
@duration-out: 0.2s;
@delay: 0.3s;


.anwb_hover() {

	.touched & {
		[aria-haspopup] > label a {
			pointer-events: none;
		}
		[aria-haspopup] [aria-haspopup] > label a {
			pointer-events: inherit;
		}
		[aria-haspopup] > :checked ~ label{
			.show();
		}
	}
	.hovered & {
		& > [aria-haspopup]:hover > label {
			.show();
		}
	}



	[aria-haspopup]:not(input){
		position: relative;
		& [aria-haspopup] {
			& > [aria-label="submenu"] {
				display: none;
				transition: unset;

			}
			& > label::before {
				display: none;
			}
		}

		& > [aria-label="submenu"] {
			display: block;
			opacity: 0;
			top: -1000px;
			transition: top @duration-in @delay step-end,z-index @duration-in @delay step-end,opacity @duration-out @delay ease-out;
			position: absolute;
			z-index: 1;
			margin: 0;
			padding: @line @margin-text-m @margin-text-s @margin-text-m;
			border-bottom-left-radius: @border-radius-s;
			border-bottom-right-radius: @border-radius-s;
			background: @white;
			box-shadow: 0 0 6px @shadow-color-light;
			& > li {
				display: block;
				font-weight: normal;
				font-size: @font-size;
				line-height: 2;
				white-space: nowrap;
				& > label > a,
				& > a {
					display: block;
					text-decoration: none;
					color: @blue-dark;
				}
			}
		}

		& > label {
			position: relative;
			z-index:2;
			&::before,
			&::after {
				opacity: 0;
				position: absolute;
				left: 0;
				content: ' ';
				display: block;
				width: 100%;
				pointer-events: none;
				background: @white;
				transition: opacity @duration-out @delay ease-out;
			}
			transition: z-index @duration-in step-end;
			&::before {
				 border-top-left-radius: @border-radius-s;
				 border-top-right-radius: @border-radius-s;
				 top: 0;
				 height: 100%;
				 z-index: -1;
				 box-shadow: 0 0 6px @shadow-color-light;
			 }
			&::after{
				top: 100%;
				height: 6px;
				z-index: 1;

			}
			&:hover a {
				color: @blue !important;
			}
		}
	}


	.show(){
		z-index: @z-index-logo + 1;
		border-bottom-color: @white;
		&::before,
		&::after{
			opacity: 1;
			transition: opacity @duration-in ease-in;
		}
		transition: z-index @duration-in step-start;

		& ~ [aria-label="submenu"] {
			z-index:  ( @z-index-logo - 1 );
			opacity: 1;
			top: 100%;
			transition: top @duration-in step-start,z-index @duration-in step-start, opacity @duration-in ease-in;

		}

	}



}