/*
	Panel Compare
*/

.panel-compare {
	&:extend(._panel all);
	align-items: 'stretch';


	.panel-body {
		&:extend(._panel-border);
		border-color: @white;
		background-color: @white;
		display: flex;
		height: 100%;
		flex: 1;
		flex-direction: column;
		& > [class|=sticker]{
			position: absolute;
			left: ~'calc(100% - @{sticker-m})';
			@media (@breakpoint-l) {
				left: ~'calc(100% - @{sticker-l})';
			}
		}
		> footer {
			text-align: center;
			> ul:extend(._list-unstyled all) {
				> li:first-child > a {
					&:extend(._btn all);
					&:extend(._btn-decide all);
				}
			}
		}
	}

	//Highlight
	&.highlight .panel-body {
		border-color: @blue-dark;
		header {
			// line after header
			&:after {
				._panel-divider(@line);
			}
		}
	}

	// exception
	// Todo: refactor
	.tabbox-vertical & {
		&:not(.highlight) .panel-body{
			border-color: @blue-grey-light;
		}
	}

	header {
		// line after header
		&:after {
			content: '';
			position: absolute;
			border-bottom: @line @blue-grey-light solid;
			._panel-divider('0px');
		}

		& > h3 {
			.anwb-thesans-bold();
			margin-bottom: 0;
		}
		& > h3 + h3 {
			margin-bottom: @rem-s;
		}

		> p {
			padding-top: @rem-xs;
			margin-bottom: @rem-s;
		}

	}


}

._panel-divider(@l){
		left: ~'calc(@{panel-grid-s} + @{l})';
		right: ~'calc(@{panel-grid-s} + @{l})';

		@media (@breakpoint-m) {
			left: ~'calc(@{panel-grid-m} + @{l})';
			right: ~'calc(@{panel-grid-m} + @{l})';
		}
		@media (@breakpoint-l) {
			left: ~'calc(@{panel-grid-l} + @{l})';
			right: ~'calc(@{panel-grid-l} + @{l})';
		}

}