// Panel USP
.panel-usp {
	&:extend(._panel all);

	h3 {
		font-size: @heading-s;
		margin-bottom: 0;
		position: relative;
		&:before {
			.icon;
			content: @var-icon-check-usp;
			color: @green;
			font-size: @font-size-xs;
			padding-right: @rem-xs;
			margin-top: @rem-xs;
			position: absolute;
			left: -(1 + @rem-xs);
		}
	}
}
