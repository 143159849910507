.ANWB-readmore {
	max-height: 400px;
	height: auto;
	overflow: hidden;
	position: relative;

	&--enabled::after {
		content: '';
		background-image: linear-gradient(to top, @white 1.8rem, rgba(255, 255, 255, 0.1));
		bottom: 0;
		height: 100px;
		position: absolute;
		width: 100%;
	}

	&__button {
		display: none;
	}

	&--enabled &__button {
		background-color: @white;
		bottom: 0;
		color: @blue;
		display: block;
		position: absolute;
		z-index: 2;
		text-decoration: underline;
	}

	@media (@breakpoint-m) {
		max-height: 618px;
	}
}
