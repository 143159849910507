@panel-widgetHeaderSmall:{
	@media (@breakpoint-m) {
		max-width: 100%;
		width: 100%;
	}
};

@panel-WidgetHeaderMedium:{
	@media (@breakpoint-m) {
		max-width: 100%;
		min-width: ~'calc( 50% - ( @{grid-unit-m} / 2) )';
		width: inherit;
	}

	@media (@breakpoint-l) {
		max-width: ~'calc( 75% - ( @{grid-unit-s} / 2) )';
		min-width: ~'calc( 50% - ( @{rem-l} / 2) )';
	}
};

@panel-widgetHeaderLarge:{
	@media (@breakpoint-m) {
		min-width: 33%;
		max-width: 66%;
		width: inherit;
	}

	@media (@breakpoint-l) {
		min-width: ~'calc( 33% - ( @{rem-l} / 2) )';
		max-width: ~'calc( 50% - ( @{rem-l} / 2) )';
	}
};



/*  Container based breakpoints
	This technique uses the grid classnames to target rendering.
	The hope is that in the future we can use element based queries.
 */
.panel-widget .panel-body  {

	header {
		.grid-insert(@panel-widgetHeaderSmall, @panel-WidgetHeaderMedium, @panel-widgetHeaderLarge);
	}

}


.panel-widget > .panel-body {

	._panel-grid-offset();



	& > header {
		._panel-padding();
		padding-bottom: @offset-s;
		min-height: @sticker-l;
		background-color: @blue-grey-light;
		border-top-left-radius: @border-radius;
		border-top-right-radius: @border-radius;
		display: inline-flex;
		flex-direction: row;
		margin-bottom: 0;
		width: 100%;

		@media (@breakpoint-m) {
			margin-bottom: -@rem-s;
			padding-bottom: @grid-unit-m;
		}

		@media (@breakpoint-l) {
			padding-bottom: @offset-s;
		}

		> .panel-widget-icon {
			min-width: @sticker-m;
			width: @sticker-m;
			min-height: @sticker-m;
			height: @sticker-m;
			background-color: @yellow;
			border-radius: 50%;
			margin-top: -@rem-xs;
			margin-left: -@rem-xs;
			display: flex;
			align-items: center;
			justify-content: center;

			> i {
				&:extend(.icon);
				&:extend(.icon-jumbo-xl);
			}

			@media (@breakpoint-m) {
				margin-top: -@offset-s;
				margin-left: -@offset-s;
			}

			@media (@breakpoint-l) {
				width: @sticker-l;
				min-width: @sticker-l;
				height: @sticker-l;
				min-height: @sticker-l;

				> i {
					// must use mixin: can't use extend here because we're inside a media query
					.icon-jumbo-xxl;
				}
			}
		}

		> div:not(.panel-widget-icon) {
			padding-left: @rem;

			h3 {
				&:extend(._panel-title-h3 all);
			}

		}

	}

	> .panel-main {
		._panel-padding();
		height: auto;
		background-color: @blue-grey-light;
		border-radius: @border-radius;
	}

	> header + .panel-main {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding-top: @grid-unit-s - @rem-xs;

		@media (@breakpoint-m) {
			border-top-right-radius: @border-radius;
			padding-top: @rem-s + @grid-unit-m;
		}

		> * {
			margin-top: 0;
			padding: 0;
		}
	}
}

.showcase .panel-widget > .panel-body {
	position: relative;
	margin-top: -@rem-component-l;

	@media (@breakpoint-m) {
		margin: calc(0 - (@sticker-m + @rem-component-m + @grid-unit-s)) auto 0;
		max-width: @viewport-l-max;

		> header > .panel-widget-icon {
			z-index: @z-index-logo;
		}

		> .panel-body {
			position: relative;
			border-radius: 0;
			box-shadow: 99px 0 0 0 @blue-grey-light, -99px 0 0 0 @blue-grey-light;
		}
	}

	@media (@breakpoint-l) {
		margin-top: calc(0 - (@sticker-l + @rem-component-m + @rem-s));

		> div {
			box-shadow: 999px 0 0 0 @blue-grey-light, -999px 0 0 0 @blue-grey-light;
		}
	}

}

