
._show-mobile-menu() {
	display: block;
	width: 100vw;
}

@media (@breakpoint-s--menu) {

	.checked(){

		.active & {
			ul {
				will-change: left;
			}
		}

		& > ul {
			position: fixed;
			top: @utilities-offset;
			left: 100vw;
			transition: left .3s ease-out;
			display: block;
			margin: 0;
			max-height: none;
			max-width: none;
			width: 100vw;
			height: ~'calc( 100vh - @{utilities-offset} )';
			background: @white;
			overflow: hidden;
			&:before {
				content: '';
				font-family: @anwb-font-family;
				position: fixed;
				top: @utilities-offset;
				left: 0;
				padding: 0;
				display: block;
				height: 0;
				width: 0;
				background-color: @blue-grey-lighter;
				color: @blue-grey-lighter;
				transition: color .3s ease-out;
			}
			> li:first-of-type{
				border-top: @utilities-offset solid @blue-grey-lighter;
			}
		}

		& > ul > li:last-child {

			&:after {
				content: '';
				height: @line;
				width: 100%;
				display: block;
				position: relative;
				bottom: -@utilities-offset*3;
			}
		}

		& > :checked ~ ul {
			left: 0;
			transition: left .3s ease-in;
		}

		.onTop ~ ul {
			top: @utilities-offset;
			overflow-x: hidden;
			overflow-y: auto;
			//-webkit-overflow-scrolling: touch;
			&:before {
				content: attr(data-submenu-title);
				width: 100%;
				height: @utilities-offset;
				color: @blue;
				padding-top: @rem-s;
				padding-left: @rem-component-m;
				//transition: color .3s ease-in;
			}

			&:after{
				.icon;
				content: @var-icon-chevron-left;
				font-size: @font-size-s;
				position: fixed;
				top: @rem-component-l + @grid-unit-l;
				left: @rem-s;
				z-index: @z-index-cookie;
			}

			> li:first-of-type {
				margin-top: @utilities-offset;
				border-top: 0;
			}
		}

		.onTop ~ label {
			position: fixed;
			z-index: 100;
			top: @utilities-offset;
			left: 0;
			background: transparent;

			user-select: none;
			color: @blue-grey-lighter;
			text-indent: -9999px;

			& > a {
				color: @blue-grey-lighter;
				border-bottom: 0;
				&::after{
					display: none;
				}
			}
			&:hover a {
				color: @blue-grey-lighter;
			}
		}
	}

	.navigation-main  {
		//height: @utilities-offset;
		ul, li, label, a {
			margin: 0;
			padding: 0;
			width: 100vw;
			display: block;
			max-width: none;
			//min-height: @utilities-offset;
			list-style: none;
			//background: @white;
		}

		ul {
			max-width: 100%;
		}

		& > ul > li[aria-haspopup] {
			background: @white;
			position: absolute;
			top: 0;
			left: 0;
			height: @utilities-offset;

			& > ul {
				position: fixed;
				top: @utilities-offset;
				//top: 0;
				left:0;
				overflow-x: hidden;

				display: none;
				height: ~'calc( 100vh - @{utilities-offset} )';
				padding-bottom: @utilities-offset * 2;
			}


			& > :checked ~ ul {
				display: block;
				//-webkit-overflow-scrolling: touch;
			}
		}

		& > ul > li > label {
			position: absolute;
			//z-index: @z-index-logo;
			top: 5px;
			overflow: hidden;
			width: 44px;
			height: 34px;
			text-align: center;
			line-height: 2.3;
			align-self: center;
			padding-bottom: 5px;
			padding-right: @margin-text-m;
			border-radius: @border-radius-s @border-radius-s 0 0;
			color: transparent;
			&:hover {
				color: transparent;
			}
			&:before {
				width: 44px;
				height: 34px !important;
				display: block;
				color: @blue-dark;
				font-size: @font-size-m;
				margin: 8px 14px;
			}

		}

		& > ul > li {
			& > :checked ~ label {
				background: @yellow !important;
			}
		}




		// Main menu
		& > .navigation-content > li {
			& > label {

				right: @panel-grid-l;
				z-index: 10;
				&:before {
					.icon;
					content: @var-icon-menu;
					.icon-lg;
				}
			}
			& > :checked ~ label {
				&:before {
					.icon;
					content: @var-icon-cross !important;
					.icon-lg;
				}
			}
		}

		// User menu
		& > .navigation-user > li > label {
			right: ~'calc( ( @{utilities-offset} * 2 ) + @{panel-grid-l} )';
			z-index: 30;
			&:before {
				.icon;
				content: @var-icon-profile;
				.icon-lg;
			}

		}

		//Search
		& > .navigation-search > li > label {
			right: ~'calc( @{utilities-offset} + @{panel-grid-l} )';
			z-index: 20;
			&:before {
				.icon;
				content: @var-icon-search;
				.icon-lg;
			}

		}
		.navigation-search .menu-search-wrapper .menu-searchForm{
			display: block;
		}
	}
	.navigation-search,
	.navigation-user,
	.navigation-content  {

		ul, li, label, a {
			margin: 0;
			padding: 0;
			width: 100vw;
			display: block;
			max-width: none;
			list-style: none;
			//background: @white;
		}

		ul, li {
			width: 100%;
		}



		li, label, a {
			//height: @utilities-offset;
		}
		li {
			//background: white;
			font-size: @font-size;

			a {
				text-decoration: none;
				color: @blue-dark;
				//line-height: 2.5;
				border-bottom: @line solid @blue-grey-lighter;
				//background: @white;
				//height: ~'calc( @{utilities-offset} - @{line})';
				padding: .8em @panel-grid-l;
			}

			:hover {
				color: @blue ;
			}

		}
		.utility-links {
			height: auto;
			nav {
				padding-top: @margin-text-m;
			}
			nav a {
				height: auto !important;
				line-height: @line-height-s;
				border: 0;
				font-size: @font-size-xs;
			}
		}

		& > [aria-haspopup] > ul [aria-haspopup] {
			//height: auto;
			.checked();
		}

		& [aria-haspopup ] > label {


			& > a {
				pointer-events: none;
				&:after {
					float: right;
					.icon;
					content: @var-icon-chevron-right;
					font-size: @font-size-xs;
					color: @blue;
					display: inline-block;
					line-height: 2;
					font-weight: bold;

				}
			}
			&:hover a {
				color: @blue;
			}

		}
	}

	.subActive {
		overflow: hidden;
	}
}