.breadcrumb {
	background: @gradient-blue-dark;

	ol, ul {
		&:extend(._list-unstyled all);
		padding-left: @grid-offset-s;
		& > li {
			display: none;
			font-size: @font-size-xs;

			&:last-child {
				display: inline;

				&:before {
					.icon;
					content: @var-icon-chevron-left;
					color: @blue-grey;
					font-size: 7px; // coold not fix this with EM or REM :(
				}
			}
		}
		& > li > a {
			color: @blue-grey;
			text-decoration: none;
			line-height: 34px;

			&:hover {
				color: @white;
			}

			&:active {
				color: @blue-grey;
			}
		}
	}

	@media (@breakpoint-m) {
		ol, ul {
			._parent-reset;
			padding-left: @grid-offset-m;
		}
	}

	// Display elements in larger viewports
	@media (@breakpoint-m--menu) {
		border-top: none;
		padding: 1.6em 0 .5em;
		line-height: @line-height-base;
		margin-top: -@margin-text-l * 1.3;

		ol, ul {
			._parent-reset;
			padding-left: @grid-offset-m;

			& > li {
				display: inline;

				&:after {
					.icon;
					content: @var-icon-chevron-right;
					color: @blue-grey;
					font-size: @font-size-xxs;
					margin-left: (@margin-text-m / 3);
				}

				&:before {
					display: none !important;
				}

				&:last-child:after {
					display: none;
				}
			}

		}
	}

	@media (@breakpoint-l) {
		padding: 2em 0 0.4em;
		margin-top:-@margin-text-l * 1.6;

		ol, ul {
			padding-left: @grid-offset-l;
		}
	}
}

