// NOTE:
// The ad containers are agnostic of the ad sizes:
// The ads determine their own size.
// These sizes are specified in javascript.
.ad {

  	&-leaderboard {
		text-align: center;
		& > :first-child {
			// the margin is on child so collapsing doesn't make the page 'jump'
			margin-bottom: @grid-unit-s;
		}
		// hide on small screens
		display: none;
		@media (@breakpoint-m--menu) {
			display: block;
			flex: none;
		}
	}

	// this is the element that can be collapsed
	&-rectangle {
		&:extend(._panel);
		text-align: center;
		& > :first-child {
			&:extend(._panel-border);
		}
		// temporary solution while the ads are not responsive:
		// hide on tiny screens
		@media (max-width: 366px) {
			display: none;
		}
		// hide while ad doesn't fit inside the 1/3 panel
		@media (@breakpoint-m) {
			display: none;
		}
		// show once the ad fits inside the 1/3 panel
		@media (min-width: 1144px) {
			display: block;
		}
	}
}
