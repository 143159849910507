.reset-list() {
	margin: 0;
	padding: 0;
	list-style: none;
}

.reset-link(@type: initial) {
	color: @type;
	text-decoration: @type;
}

.reset-font() {
	font-family: @default-fonts;
	font-style: normal;
	font-weight: normal;
}
//.reset-img() {
//  float: none;
//  margin: 0;
//}
