.@{anwb-prefix}topics {
	@block: ~"@{anwb-prefix}topics";

	// topic has a little different padding than normal structures, because the items have less padding and mobile styling;
	padding: 0;
	@media (@breakpoint-m) {
		padding: 0 (@grid-offset-m - (@grid-unit-m / 2) + (@panel-grid-m - @margin-text-s));
	}
	@media (@breakpoint-l) {
		padding: 0 (@grid-offset-l - (@grid-unit-l / 2) + (@panel-grid-l - @margin-text-s));
	}

	border-bottom: solid @blue-grey-light @line;
	@media (@breakpoint-m) {
		border: 0;
	}

	&,
	> ul,
	> ol {
		list-style: none;
	}

	li {
		margin: 0;
	}

	&__item {
		display: block;
		padding: @grid-unit-s;
		width: 100%;
		border-top: solid @blue-grey-light @line;
		color: @blue-dark;
		text-decoration: none;
		font-weight: bold;
		line-height: @line-height-s;

		@media (@breakpoint-m) {
			padding: @margin-text-s;
			border: 0;
			text-align: center;
		}

		a {
			color: inherit;
			text-decoration: none;

			.hyphenate();

			&:hover {
				color: @blue;
			}
		}
	}

	&__icon {
		display: inline-block;

		.icon-xxxl;

		@media (@breakpoint-m) {
			display: block;
			margin: 0 auto;

			.icon-jumbo-xxl;
		}

		& + * {
			font-weight: bold;
			margin-left: @rem-s;
			font-family: @anwb-font-family;

			@media (@breakpoint-m) {
				display: block;
				margin-left: 0;
				text-align: center;
				padding-top: @rem-xs;
			}
		}
	}
}