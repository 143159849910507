//
// Panel CrossSell
//--------------------------------------------------
// TODO delete from here. In SASOI-2179 these variables are set in core-less-imports
@duration-in: 0.3s;
@duration-out: 0.2s;

.panel-callToAction {
	&:extend(._panel all);


	// The display:block and height:100% are needed for IE, the flex:1 for all other browsers
	.panel-body {
		&:extend(._panel-border all);
		min-height: @grid-offset-l * 2;
		text-decoration: none;
		padding-bottom: ~"calc((@{grid-unit-s} - (@{line} * 2)) + @{rem-s})";

		border-image-slice: 12 12 12 12;
		border-image-width: 12px 12px 12px 12px;
		border-image-repeat: stretch stretch;
		border-image-source: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAlxJREFUeNrsmDFv02AQhu/7/NUpjhuBRZgghS5I0LFDB4TUP1AJpNLCwMDAigQLIwsUlnaBf4AEYWDpHwDEgCCMSLAUGlgAKZQkRI1r++M91wmWmqiKFGwPPunVWYnl99Hd+SxbaK1pWHz60a4gXYEWoDNQGSrQaNGGvkFb0AuoevqY/XnYyWIQUARyH7oEGTTeCBgKug2w+oFAgFlEegxNCUFkm4rsgqJDE5KUlMS/jeSO63uBpp3dgNpdj9quR5ElV+46oJ4MBQLMDaQ1SDJE2TbJNORYy+P6Af1suyEcgs1vAWp9HxBglpGYVjCIY5n0P6PRcUOwCOoyoKp9oGhmPkB2EjADoLh9Z3mmev1YZZgptCkpGA72Yk/2hh6EFfr4vTWNvIlhlTNOkZQhKMnwfE2bjT886Hz3zXCFVniImTRpGA72jKoUssho6YW3dloR815goFk+mlRGakAx71kGOhqWTorUgGLeZdl7Non0eOLepqSMRQ6UA+VAOVAOlHUgEbw3tdBuJmC0MEn68oifleowi/SME9tZAfKMyi/pqlNvswLkqpPvpKeO38vGbEtiFqFrRJ3C+S2r+6qSJg4Y6mCYDkuzU5hbDkRRpwXD3szQ30NOc+1Ny1p6iC2QxuYh9maGvVfp2r+/mtbK81Ln2YW9LybJwDStpQ14Lg7c1KXO04vb9rW7gbB1Em36Xbz6KA6zr0L9jwClm/OT3VrV6r6ujL9aEgN8rs4z02vTgUBxMOXV75jelznlfz1sBA1D6N0RHwcT2MCOzwsYe6aGvOq01l8OO/+vAAMA0NrdyyA4szkAAAAASUVORK5CYII=");

		@media (@breakpoint-m) {
			padding-bottom: ~"calc((@{grid-unit-m} - (@{line} * 2)) + @{rem-s})";
		}
		@media (@breakpoint-l) {
			padding-bottom: ~"calc(@{grid-unit-m} - @{line} + @{rem-s})";
		}


		> header {
			float:left;
			.header-img();
		}

		small + a {
			margin-top: @margin-text-l;
		}
		[class^='btn'] + .list-linklist {
			margin-top: @margin-text-l;
		}

	}

	// needed for the floating price stickers.
	//.panel-main{
	//	display: inline;
	//}
}