
.ANWB-afm {
	margin: 0 !important;
	padding: 0 !important;
	img {
		display: block;
		margin: 0;
		padding: 0;
		border-radius: 0;
	}
 }