.@{anwb-prefix}panel {
	@block: ~"@{anwb-prefix}panel";

	//** private mixins **//

	.__background(@color: @blue-grey-lighter) {
		.@{block}__compound,
		.@{block}__main {
			background-color: @color;
		}
	}

	.__padded(@mod: false, @border: false, @header: true) {
		// double private header padding mixin
		.__header-padding() {
			.padding-panel-sides(@border: @border);

			&:first-child {
				.padding-panel-top(@border: @border);
			}
		}

		// double private content padding mixin
		.__content-padding() {
			.padding-panel-sides(@border: @border);

			&:first-child {
				.padding-panel-top(@border: @border);
			}

			&:last-child {
				.padding-panel-bottom(@border: @border);
			}
		}

		// double private footer padding mixin
		.__footer-padding() {
			.padding-panel-sides(@border: @border);

			&:last-child {
				.padding-panel-bottom(@border: @border);
			}
		}

		& when (@mod = false) {
			.@{block}__header when (@header = true) {
				.__header-padding();
			}

			.@{block}__content {
				.__content-padding();
			}

			.@{block}__footer {
				.__footer-padding();
			}
		}

		& when (@mod = true) {
			.@{block}__header when (@header = true) {
				&--padded {
					.__header-padding();
				}
			}

			.@{block}__content {
				&--padded {
					.__content-padding();
				}
			}

			.@{block}__footer {
				&--padded {
					.__footer-padding();
				}
			}
		}
	}

	.__border(@color: @blue-grey-light, @padded: false, @mod: false, @bg: @white) {
		.@{block}__compound,
		.@{block}__main {
			.border-panel(@color);
		}

		.@{block}__compound {
			margin-bottom: -@line;
			border-bottom-color: @bg;
		}

		& when (@padded = true) {
			.__padded(@border: true, @mod: @mod);
		}
	}

	.__titles-padding(@border: true) {
		.@{block}__title {
			.padding-panel-sides(@border: @border);

			&:first-child {
				.padding-panel-top(@border: @border);
			}
		}

		.@{block}__icon {
			.padding-panel-sides(@border: @border);
			.padding-panel-top(@border: @border);
		}
	}

	//** base styling */
	position: relative;

	// base default padding
	.spacing-panel();

	// setting the elements to display as block in the case inline elements are used
	&,
	&__main,
	&__header,
	&__content,
	&__footer,
	&__title {
		display: block;
	}

	// main default rounded corners
	&__main {
		background-color: @white;
		border-radius: @border-radius;
	}

	&__compound {
		background-color: @white;
		border-top-left-radius: @border-radius;
		border-top-right-radius: @border-radius;
		display: inline-block;
		width: 100%;
		min-height: 70px;

		@media (@breakpoint-m) {
			max-width: 100%;
			min-height: 85px;
			width: inherit;
		}

		& + .@{block}__main {
			border-top-left-radius: 0;
		}

		&--full {
			width: 100%;
			max-width: 100%;
			min-width: 100%;

			& + .@{block}__main {
				border-top-right-radius: 0;
			}
		}
	}

	// header, content, footer
	.__padded(@mod: true); // version with --padded modifier

	&__poster {
		margin-top: 0;
		border-radius: 0;
		border-top-left-radius: @border-radius - @line;
		border-top-right-radius: @border-radius - @line;

		&--rounded {
			border-radius: @border-radius - @line;
		}
	}

	&__icon {
		float: left;
		.padding-panel-sides(@border: false);
		padding-left: 0;

		> i {
			.text2icon();
		}
	}

	// standard title styles
	&__title {
		margin: 0;
		padding-bottom: @rem-xs;
		color: @blue-dark;
		.anwb-thesans-bold-italic();
		font-size: @heading-panel-m;
		font-weight: 600;

		a {
			color: inherit;
			text-decoration: none;
			.link-icon();

			&[href]::after {
				content: @var-icon-arrow-right;
			}
		}
	}

	&__title--pre {
		font-size: @heading-panel-s;
	}

	&__title--sub {
		font-size: @heading-panel-s;
	}

	&__title--meta {
		color: @blue-grey-dark;
		font-size: @font-size-xs;
		.reset-font();

		a {
			text-decoration: underline;
			padding: 0;

			&:hover {
				color: @blue-dark;
			}

			&::after,
			&[href]::after {
				content: none;
			}
		}
	}

	&__title--usp {
		&:before {
			.icon;
			display: inline-block;
			content: @var-icon-check-usp;
			color: @green;
			font-size: @rem;
			margin-right: @line;
			margin-left: ~"calc(-@{rem} - @{line})";

			@media (@breakpoint-m) {
				margin-right: @rem-xs;
				margin-left: -(@rem + @rem-xs);
			}
		}
	}

	&__header,
	&__content {
		&--padded-bottom {
			.padding-panel-bottom(@border: true);
		}

		&--border {
			.border-section();
			.padding-panel-bottom(@border: true);

			& + .@{block}__content,
			& + .@{block}__footer {
				.padding-panel-top(@border: true);
			}

			&:last-child {
				border: 0 none;
			}
		}

		&--background {
			background-color: @blue-grey-light;

			&:first-child {
				border-top-left-radius: @border-radius - @line;
				border-top-right-radius: @border-radius - @line;
			}

			&:last-child {
				border-bottom-left-radius: @border-radius - @line;
				border-bottom-right-radius: @border-radius - @line;
			}
		}
	}

	&__footer {
		&--border {
			.border-section(@side: top);
			.padding-panel-top(
				margin,
				@border: true
			); //NOTE: the previous sibling cannot have a border on it.
			.padding-panel-top(@border: true);
		}

		&--background {
			background-color: @blue-grey-light;
			border-bottom-left-radius: @border-radius - @line;
			border-bottom-right-radius: @border-radius - @line;
		}
	}

	&__content,
	&__footer {
		p {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	// slats in panels
	.@{anwb-prefix}slats {
		&__item {
			.padding-panel-sides();

			&:last-child {
				border-bottom-left-radius: @border-radius;
				border-bottom-right-radius: @border-radius;
			}
		}

		&:first-child {
			.@{anwb-prefix}slats__item:first-child {
				border-top-left-radius: @border-radius;
				border-top-right-radius: @border-radius;
			}
		}
		&:last-child {
			border-bottom: 0 none;
		}
	}

	// stickers in panels
	.@{anwb-prefix}sticker {
		float: right;
		margin-bottom: -1.2rem;
		position: relative;
		top: -@panel-grid-m;
		right: -@panel-grid-m;

		@media (@breakpoint-l) {
			top: -@panel-grid-l;
			right: -@panel-grid-l;
		}
	}

	.@{block}__compound .@{anwb-prefix}sticker {
		float: left;
		top: @panel-grid-s;
		left: @panel-grid-s;
		right: auto;
		margin-bottom: 0; // fixes some weird bug

		& ~ .@{block}__title {
			word-break: break-word;
			margin-right: 0;
			margin-left: @sticker-m + @panel-grid-s;

			@media (@breakpoint-m) {
				@sticker-spot-m: @sticker-m + @panel-grid-m;
				max-width: ~"calc((24rem - @{sticker-spot-m}) + (3 * @{line}))";
				margin-left: @sticker-spot-m;
			}

			//@media (@breakpoint-g) {
			//	@sticker-spot-g: @sticker-m + @panel-grid-m;
			//	max-width: ~"calc(22.1rem - @{sticker-spot-g})";
			//	margin-left: @sticker-spot-g;
			//}

			@media (@breakpoint-l) {
				@sticker-spot-l: @sticker-l + @panel-grid-l;
				max-width: ~"calc(24rem - @{sticker-spot-l})";
				margin-left: @sticker-spot-l;
			}
		}
	}

	&__poster + .@{anwb-prefix}sticker {
		top: -@sticker-m;
		margin-bottom: -(1.2rem + @sticker-m);

		@media (@breakpoint-l) {
			top: -@sticker-l;
			margin-bottom: -(1.2rem + @sticker-l);
		}
	}

	&__icon + &__title,
	.@{anwb-prefix}sticker + &__title {
		.padding-panel-top(@border: true); // not only child anymore
	}

	&__icon ~ &__title {
		margin-left: 64px; // FIXME when icon sizes are rem'ed
	}

	.@{anwb-prefix}sticker ~ &__title {
		margin-right: @sticker-m - @panel-grid-m;

		@media (@breakpoint-l) {
			margin-right: @sticker-l - @panel-grid-l;
		}
	}

	&__poster + .@{anwb-prefix}sticker + &__title {
		padding-top: 0; // remove the previous padding set by above
	}

	&__poster
		+ .@{anwb-prefix}sticker:not(.@{anwb-prefix}sticker--rating)
		~ &__title {
		@media (@breakpoint-m) {
			margin-right: 0;
		}
	}

	.@{anwb-prefix}sticker--rating {
		margin: 0;
		position: static;
	}

	//** basic panel classes **//

	&--padded &__main {
		.padding-panel();
		// NOTE: if child elements have the --padded modifier, it will have doubled padding...
		// NOTE: if child elements have the --border modifier, the border will not reach the sides
	}

	&--padded&--border &__main {
		.padding-panel(@border: true);
	}

	&--border {
		.__border(@padded: true, @mod: true);
	}

	&--border-white {
		.__border(@white, @padded: true, @mod: true);
	}

	&--border-blue {
		.__border(@blue-dark, @padded: true, @mod: true);
	}

	&--background {
		.__background();
	}

	&--full-height {
		.@{block}__main {
			height: 100%;
			//display: flex;
			//flex-direction: column;
		}

		.@{block}__content:last-of-type {
			.padding-panel-bottom(margin);
		}

		.@{block}__footer {
			margin-top: auto;
		}
	}

	//** PANEL CLASSES **//
	// These are the classes that should be mostly used to build panels!

	&--basic {
		.__border();
		.__padded(@border: true);

		.@{anwb-prefix}sticker + .@{block}__title {
			padding-top: 0;
		}

		.@{block}__poster,
		.@{anwb-prefix}sticker:not(.@{anwb-prefix}sticker--rating) {
			display: none;
			outline: 2px solid red;
		}
	}

	&--standard,
	&--segmented {
		.__border();
		.__padded(@border: true, @header: false);
		.__titles-padding();

		.@{anwb-prefix}sticker--rating {
			margin-top: 1rem;
			margin-right: 1rem;
		}

		.@{block}__poster + .@{anwb-prefix}sticker--rating {
			margin-top: 0;
		}

		.@{anwb-prefix}slats__item {
			.padding-panel-sides(@border: true);

			&:last-child {
				border-bottom-left-radius: @border-radius - @line;
				border-bottom-right-radius: @border-radius - @line;
			}
		}
	}

	&--segmented {
		.@{block}__header,
		.@{block}__content {
			.border-section();
			.padding-panel-bottom(@border: true);

			& + .@{block}__content,
			& + .@{block}__footer {
				.padding-panel-top(@border: true);
			}

			&:last-child {
				border: 0 none;
				margin-bottom: 0;
			}
		}

		.@{block}__title:last-child {
			margin-bottom: 0;
		}
	}

	&--zebra {
		.__background();
		.__padded(@border: true, @header: false);
		.__titles-padding();

		.@{block}__header {
			.padding-panel-bottom();
		}

		.@{block}__title:last-child {
			margin-bottom: 0;
		}
	}

	&--info,
	&--promotion,
	&--attention,
	&--notice {
		.__padded(@border: true, @header: false);
		.__titles-padding();
	}

	&--info {
		.__background();
		.__border(@color: @blue-grey-lighter, @bg: @blue-grey-lighter);
	}

	&--attention {
		.__background(@blue-grey-light);
		.__border(@bg: @blue-grey-light);
	}

	&--promotion {
		.__background(@yellow);
		.__border(@color: @yellow, @bg: @yellow);
	}

	&--notice {
		.__border(@color: transparent, @bg: transparent);

		.@{block}__compound,
		.@{block}__main {
			background-clip: content-box;
		}

		.@{block}__compound {
			margin-bottom: -4px;
		}
	}

	&--clear {
		.__padded(@header: false);
		.__titles-padding(@border: false);

		.@{block}__main {
			background-color: transparent;
		}
	}

	.section-informational &--info, // todo: BEM version of this section block
	&--notice {
		.__background(@white);
	}

	&--feature {
		// call to action
		.__border();
		.__padded(@border: true, @header: false);
		.__titles-padding();

		.@{block}__main:after {
			background-color: @yellow;
			border: @line solid @yellow;
			border-top: 0 none;
			border-bottom-left-radius: @border-radius - @line;
			border-bottom-right-radius: @border-radius - @line;
			box-sizing: content-box;
			content: "";
			display: block;
			height: @border-radius - @line;
			margin-bottom: -@line;
			margin-left: -@line;
			width: 100%;
		}
	}

	&--highlighted,
	&[data-highlighted] {
		.__border(@blue-dark, @padded: true, @mod: true);
	}

	&--fusible {
		@media (@breakpoint-s) {
			.@{block}__content,
			.@{block}__list {
				display: none;
			}

			&:not(:last-child) {
				padding-bottom: 0;

				.@{block}__main {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					.border-section();
				}
			}

			& + & {
				padding-top: 0;

				.@{block}__main {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}
			}
		}
	}

	//[data-hide-on="breakpoint-s"] { // todo: only on s
	//	display: none;
	//}

	.grid-stacked > & {
		// todo: piece of legacy, can be removed when the new BEM grid is rolled out
		overflow: visible;
	}
}
