/* Opmerking mbt A/B Testing:
Voor elk actief Optimizely experiment op de pagina wordt een attribuut op het html element gezet.
De waarde is de actieve variatie. Variatie waarde "0" is normaal gesproken het origineel.

Het menu bevat styling voor de B-test (waarde "1") en default (soms met een :not() selector)
*/

// Dit is het unieke experiment ID:
@test-b: e('[experiment-8299577532="error"]');

@media (@breakpoint-m--menu) {

	@breakpoint-m--menu--search: ~"min-width: 940px";

	.navigation-search {
		padding-left: 0;
		position: absolute;
		top: -@margin-text-m;
		@media ( @breakpoint-l ) {
			right: @margin-text-m;
		}
		right: @margin-text-l;

		ul,
		> li {
			display: inline-block;
			list-style: none;
			//margin: 0 @panel-grid-m 0 0;
			max-width: none;
			width: auto;
			position: relative;

			//BREAKPOINT-M    search

				margin-left: auto;
				display: inline-block;


				> ul {
					border-radius: @border-radius;
					border-top-right-radius: 0;
					//margin-top: -3px !important;
					right: 8px;
				}

				span {
					// A-B testing main-menu; A test (default)
					html:not(@{test-b}) .navigation-main & {
						display:none;
					}
				}


			//BREAKPOINT-Mmenu    search
			@media (@breakpoint-m--menu--search){
				span {
					// A-B testing main-menu; A test (default)
					html:not(@{test-b}) .navigation-main & {
						//margin-right: @margin-text-m;
						display: inline-block;
					}
				}
			}

			@media (@breakpoint-l) {
				margin-right: @margin-text-s;
				> ul {
					right: 0.9rem;
				}
			}
		}
		.anwb_hover();


		[aria-haspopup] > ul {
			right:0;
			text-align: left;
			border-top-left-radius: @border-radius-s;

		}
		& .search label {

			color: @blue-dark;
			text-decoration: none;
			padding: 0.5rem;
			// A-B testing main-menu; B test
			html@{test-b} .navigation-main & {
				padding: @margin-text-s;
				padding-bottom: 0;
			}
			font-family: @anwb-font-family;
			font-size: @font-size-m;
			display: inline-block;
			height: 100%;
			width: 100%;
			//right:0.5rem;
			position: relative;
			// A-B testing main-menu; A test (default)
			html:not(@{test-b}) .navigation-main &::before {
				box-shadow: none !important;
			}
			@media ( @breakpoint-l) {
				// A-B testing main-menu; A test (default)
				html:not(@{test-b}) .navigation-main & {
					font-size: @font-size-l;
				}
			}
			& a {
				border-top-left-radius: @border-radius-s;
				border-top-right-radius: @border-radius-s;
				display: inline-block;
				color: @blue-dark;
				text-decoration: none;
				&:hover,
				&:hover:after {
					color: @blue;
				}
				line-height: 1.8;

				@media ( @breakpoint-l) {
					line-height: 2;
				}
				padding-right: 0.5rem;

				&:after {
					.icon;
					content: @var-icon-search;
					display: inline-block;
					color: @blue-dark;
					font-size: @font-size-m;
					position: relative;
					right: -(@line * 5);
					top:-1px;
				}

			}

		}
		&:hover .search label {
			border-top-left-radius: @border-radius-s;
			border-top-right-radius: @border-radius-s;
			& a:before {
				box-shadow: none;

			}
			&::before {
				//box-shadow: none;
			}
			&::after {
				//display: none;
			}

		}

		// A-B testing main-menu; B test
		html@{test-b} .navigation-main & {
			top: -4.125rem;
			z-index: @z-index-logo + 1;
		}
	}
}
.navigation-search .menu-search-wrapper {


	@media (@breakpoint-m--menu) {
		width: 35rem;

	}

	.menu-searchForm {
		padding: @grid-unit-s;
		@media (@breakpoint-m--menu) {
			padding: 0;
			margin: 0;
			display: flex;
			width: 100%;
			align-items: flex-start;
		}

		.menu-searchInput {
			@media (@breakpoint-m--menu) {
				width: 78%;
			}
			& > ol {
				&:extend(._panel-border);
				display: block;
				margin: 0;
				padding: 0.2rem 0;
				border-top: 0 none;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-color: @blue-grey;
				list-style: none;

				& > li {
					display: block;
					text-decoration: none;
					color: @blue-dark;
					border: 0;
					padding: 0.4rem 0.7rem;
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
					line-height: 1.4;
					cursor: pointer;
					&:hover,
					&.selected {
						outline: 0;
						text-decoration: none;
						color: @blue;
					}
					&.selected {
						color: @orange;
					}
				}
			}

			input[type='search'] {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				&:only-child {
					border-radius: @border-radius;
				}
				&:focus + * {
					border-color: @blue;
				}
			}
		}

		button[type='submit'] {
			display: none;
			@media (@breakpoint-m--menu) {
				margin-left: @panel-grid-m;
				display: inline-block;
				flex: 1;
			}
		}

		@media (@breakpoint-m--menu) {
			//display: block;
			margin: @margin-text-s 0 @line;
		}

		&.active {
			display: block;
		}

	}
}
