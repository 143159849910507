.@{anwb-prefix}showcase {
	@block: ~"@{anwb-prefix}showcase";

	img {
		border-radius: 0;
	}

	opacity: 1!important; // original comment by MC: prevent flickering caused by stupid BlueConic script
	position: relative;
	width: 100%;
	margin-top: 0;
	border-top: 0;
	border-bottom: solid transparent @rem-component-s;
	padding: 0;
	overflow: hidden;

	margin-top: 0 !important; // remove important when old grid is dead
	margin-bottom: ( @rem-component-s  * -1 ) !important; // remove important when old grid is dead

	@media (@breakpoint-m) {
		margin-bottom: ( @rem-component-m * -1 ) !important; // remove important when old grid is dead
	}

	@media (@breakpoint-l) {
		margin-bottom: ( @rem-component-l * -1 ) !important; // remove important when old grid is dead
	}

	@media (@breakpoint-m--menu) {
		border-bottom: solid transparent @rem-component-m;
	}

	@media (@breakpoint-l) {
		border-bottom: solid transparent @rem-component-m;
	}

	display: flex;
	flex-direction: column;

	&__media {
		max-height: 60vh;
		overflow: hidden;
		display: flex;
		background-size: cover;
		background-position: center;
		position: relative;

		@media (@breakpoint-m--menu) {
			min-height: 420px;
			//max-height: 60vh;
		}

		> img {
			visibility: hidden;
			align-self: center;
		}

		&::before {
			position: absolute;
			display: block;
			content: "";
			bottom: 0;
			width: 100%;
			height: 100px;
			background: @gradient-blue-transparent;

			@media (@breakpoint-m--menu) {
				height: 140px;
			}
		}
	}

	&__widget {
		position: relative;
		margin-top: -@rem-component-l;
		@pull-up-m: 85px - @line;

		@media (@breakpoint-m) {
			margin-top: -@pull-up-m;
			background-image: linear-gradient(@blue-grey-light, @blue-grey-light);
			background-position: bottom;
			background-repeat: no-repeat;
			background-size: 100% ~"calc(100% - @{pull-up-m})";
		}

		.@{anwb-prefix}panel {
			padding-top: 0;
			padding-bottom: 0;
		}

		.@{block}__display ~ & {
			margin-top: 0;

			@media (@breakpoint-m) {
				background-size: 100% 100%;
			}

			@media (@breakpoint-m--menu) {
				margin-top: -@pull-up-m;
				background-size: 100% ~"calc(100% - @{pull-up-m})";
			}
		}
	}

	&__panels {
		position: relative;
		margin-top: -@rem-component-l;
		@pull-up-m: 85px - @line;

		@media (@breakpoint-m) {
			margin-top: -@pull-up-m;
		}

		.@{block}__display ~ & {
			margin-top: 0;

			@media (@breakpoint-m--menu) {
				margin-top: -@pull-up-m;
			}
		}

		.@{block}__widget ~ & {
			margin-top: 0;
		}
	}

	&__display {
		position: relative;
		margin-top: -@rem-component-l;

		@media (@breakpoint-m) {
			@pull-up-m: 85px - @line;
			margin-top: -@pull-up-m;
		}

		@media (@breakpoint-m--menu) {
			position: absolute;
			margin-top: 0;
			top: 0;
			min-height: 420px;
			max-height: 60vh;
			width: 100%;
		}
	}
}

.@{anwb-prefix}speechbubble {
	@block: ~"@{anwb-prefix}speechbubble";

	.spacing-panel();

	&__main {
		.padding-panel(@border: true);
		.border-panel(@blue-grey);
		border-radius: @border-radius;
		background-color: @white;
		position: relative;

		@media (@breakpoint-m--menu) {
			padding: @grid-unit-s;
			border: 0 none;
			background-color: transparent;
		}
	}

	@media (@breakpoint-m--menu) {
		position: absolute;
		width: 390px;
		top: 10px; // effective 20px;
		bottom: 13px;
		margin-left: -3rem;
		padding: 5rem 3rem 4rem;
		background-color: transparent;
		background-image: url('images/speechbubble.svg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		display: flex;
		align-items: center;
	}

	&__title {
		color: @blue-dark;
		.anwb-thesans-bold-italic();
		font-size: @heading-panel-m;
		font-weight: 600;

		a {
			color: inherit;
			text-decoration: none;
			.link-icon();

			&[href]::after {
				content: @var-icon-arrow-right;
			}
		}
	}

	// stickers in speechbubble
	.@{anwb-prefix}sticker {
		float: right;
		margin-bottom: -1.2rem;
		position: relative;
		top: -@panel-grid-m - @grid-unit-s;
		right: -@panel-grid-m - @grid-unit-s;

		@media (@breakpoint-m) {
			top: -@panel-grid-m - @grid-unit-m;
			right: -@panel-grid-m - @grid-unit-m;
		}

		@media (@breakpoint-m--menu) {
			top: -3.5rem;
			right: -5.5rem;
		}
	}

	// placing the speechbubble when on less taller screens
	@media (max-height: 43rem) {
		margin-top: -130px;
	}

	@media (min-height: 43rem) and (max-height: 50rem) {
		margin-top: ~"calc(-50rem + 90vh)";
	}
}
