.spacing-panel() {
	padding: @panel-grid-s;

	@media (@breakpoint-m) {
		padding: @panel-grid-m;
	}

	@media (@breakpoint-l) {
		padding: @panel-grid-l;
	}
}

.spacing-panel-sides() {
	padding-left: @panel-grid-s;
	padding-right: @panel-grid-s;

	@media (@breakpoint-m) {
		padding-left: @panel-grid-m;
		padding-right: @panel-grid-m;
	}

	@media (@breakpoint-l) {
		padding-left: @panel-grid-l;
		padding-right: @panel-grid-l;
	}
}

.padding-panel(@spacing: padding, @border: false) {
	@{spacing}: @grid-unit-s;

	& when (@border = true) {
		@{spacing}: ~'calc( @{grid-unit-s} - @{line})';
	}

	@media (@breakpoint-m) {
		@{spacing}: @grid-unit-m;

		& when (@border = true) {
			@{spacing}: ~'calc( @{grid-unit-m} - @{line})';
		}
	}

	@media (@breakpoint-l) {
		@{spacing}: @rem-l;

		& when (@border = true) {
			@{spacing}: ~'calc( @{rem-l} - @{line})';
		}
	}
}

.padding-panel-sides(@spacing: padding, @border: false) {
	@{spacing}-left: @grid-unit-s;
	@{spacing}-right: @grid-unit-s;

	& when (@border = true) {
		@{spacing}-left: ~'calc( @{grid-unit-s} - @{line})'; // when @border = true;
		@{spacing}-right: ~'calc( @{grid-unit-s} - @{line})'; // when @border = true;
	}

	@media (@breakpoint-m) {
		@{spacing}-left: @grid-unit-m;
		@{spacing}-right: @grid-unit-m;

		& when (@border = true) {
			@{spacing}-left: ~'calc( @{grid-unit-m} - @{line})';
			@{spacing}-right: ~'calc( @{grid-unit-m} - @{line})';
		}
	}

	@media (@breakpoint-l) {
		@{spacing}-left: @rem-l;
		@{spacing}-right: @rem-l;

		& when (@border = true) {
			@{spacing}-left: ~'calc( @{rem-l} - @{line})';
			@{spacing}-right: ~'calc( @{rem-l} - @{line})';
		}
	}
}

.padding-panel-top(@spacing: padding, @border: false) {
	@{spacing}-top: @grid-unit-s;

	& when (@border = true) {
		@{spacing}-top: ~'calc( @{grid-unit-s} - @{line})';
	}

	@media (@breakpoint-m) {
		@{spacing}-top: @grid-unit-m;

		& when (@border = true) {
			@{spacing}-top: ~'calc( @{grid-unit-m} - @{line})';
		}
	}

	@media (@breakpoint-l) {
		@{spacing}-top: @rem-l;

		& when (@border = true) {
			@{spacing}-top: ~'calc( @{rem-l} - @{line})';
		}
	}
}

.padding-panel-bottom(@spacing: padding, @border: false) {
		@{spacing}-bottom: @grid-unit-s;

	& when (@border = true) {
		@{spacing}-bottom: ~'calc( @{grid-unit-s} - @{line})';
	}

	@media (@breakpoint-m) {
		@{spacing}-bottom: @grid-unit-m;

		& when (@border = true) {
			@{spacing}-bottom: ~'calc( @{grid-unit-m} - @{line})';
		}
	}

	@media (@breakpoint-l) {
		@{spacing}-bottom: @rem-l;

		& when (@border = true) {
			@{spacing}-bottom: ~'calc( @{rem-l} - @{line})';
		}
	}
}
