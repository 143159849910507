.@{anwb-prefix}timeline {
	@block: ~"@{anwb-prefix}timeline";

	ul&,
	ol&,
	> ul,
	> ol {
		.reset-list();
	}

	&__intro {
		color: @blue;
		margin-bottom: 3rem;

		.@{block}--lightbox & {
			margin-bottom: 1rem;
		}

		p {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.@{block}__title { // mmm nested elements, not good...
			+ p, + ol, + ul {
				margin-top: -@rem-xs;
			}
		}
	}

	&__list {

	}

	&__item {
		position: relative;
		padding-left: 42px;
		min-height: @grid-unit-l + @rem;
		padding-bottom: @rem-xl;
		overflow: hidden;

		&:not(:last-child)::before {
			content: "";
			position: absolute;
			width: 2px;
			top: 0;
			background-color: @blue-dark;
			left: 11px;
			bottom: 0;
		}

		&::after {
			content: "";
			position: absolute;
			width: @grid-unit-l;
			height: @grid-unit-l;
			border-radius: @grid-unit-l / 2;
			top: 0;
			background-color: @white;
			border: @line solid @blue;
			left: 0;
		}
	}

	&__title {
		margin: 0;
		margin-bottom: @rem-xs;
		color: @blue-dark;
		.anwb-thesans-bold();
		font-size: @heading-panel-m;
		font-weight: 600;
		line-height: 1.2;
	}

	&__title--pre,
	&__title--sub {
		font-size: @heading-panel-s;
		color: @blue;
	}

	&__desc {
		p:first-child {
			margin-top: 0;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	&__image {
		img,
		img& {
			margin-top: 0;
			width: 100%;

			@media (@breakpoint-m) {
				float: left;
				margin-right: 1rem;
				width: 54%;
			}

			@media (@breakpoint-l) {
				width: 50%;
			}
		}
	}

}