@gold: #C0A85C;
@silver: #a9aaac;
@bronze: #CA954A;

.ANWB-article-header {
	overflow: hidden;

	&__subtitle {
		&:extend(h3);
		color: @blue;
	}

	&__summary {
		color: @blue;
		margin-top: 0;

		& time {
			font-style: italic;
		}
	}

	&--product-page h1 {
		margin-bottom: .4rem;

		@media (@breakpoint-m) {
			margin-bottom: .2rem;
		}
	}

	&--product-page &__subtitle {
		font-size: 1.2rem;
		margin-bottom: .2rem;

		@media (@breakpoint-m) {
			font-size: 1.6rem;
		}
	}

	&--product-page &__summary {
		color: inherit;
		margin-bottom: .5rem;

		@media (@breakpoint-m) {
			margin-bottom: 0;
		}
	}

	&__award-text {
		border-top: 2px solid;
		font-weight: 800;
		margin-top: 1rem;
		padding: 0.4rem 0;

		&--gold {
			border-color: @gold;
			color: @gold;
		}
		&--silver {
			border-color: @silver;
			color: @silver;
		}
		&--bronze {
			border-color: @bronze;
			color: @bronze;
		}

		& > span {
			display: inline-block;
			word-break: keep-all;
		}

		& > span:last-child {
			display: block;
		}

		@media (@breakpoint-m) {
			display: none;
		}
	}

	&__award-text-icon {
		font-size: 1.3rem;
		line-height: 1rem;
	}
}

.ANWB-article-logo {
	background-size: contain;
	display: inline-block;
	float: right;
	height: 2rem;
	margin-left: 1em;
	width: 5rem;
	
	img {
		border-radius: 0;
		display: none;
		margin: 0;
	}

	@media (@breakpoint-m) {
		height: 69px;
		margin-top: .3rem;
		width: 170px;

		&--alternate-logo {
			background: none;
			height: auto;
			width: 9.5rem;
		}

		img {
			display: inline-block;
			margin-top: .3rem;
			max-height: 100%;
			max-width: 100%;
		}
	}

	@media (@breakpoint-l) {
		&--alternate-logo {
			width: 10.5rem;
		}
	}
}
