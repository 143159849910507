/**
 * Button definitions
 * If the button isn't a button it must be assigned the role button.
 */

/* TODO:
      TEMP CLASS NAME '_tmp-list' ; this needs to be moved to the Styleguide,
      And is only for presentation purposes and NOT production.
*/
._tmp-list {
	._list-unstyled;
	> li {
		padding:10px;
	}
}




/**
 * Arguments:
 * text color
 * background
 * background-image
 * border
 * border-colour
 * hover-colour
 * hover-background-image
 * active-colour
 * active-text-colour
 */

/*
	Button pattern
*/

._btn() {

}

._btn,
[class^=btn],
button {
	&:extend(._btn all);
	.button-variant(@blue-dark; transparent; none; @line; @blue-dark; @blue; none; @orange; @orange;);

	// Icon placement
	&::before,
	&::after {
		.icon;
		position: relative;
		top: -1px;
	}

	&::after { left: 0px;  padding-left: .5rem;}
	&::before { right: 0px; padding-right: .5rem; }

	// STATES
	&:hover {
		&::after,
		&::before {
			transition: left .1s ease-in-out, right .1s ease-in-out;
		}

		&::after { left: 2px; }
		&::before { right: 2px; }
	}

	&[disabled] {
		opacity: .5;
	}
}

/****************
	Modifiers
	- primary
	- decide
	- link
	- reversed
*/

// Primary button
._btn-primary() {  }

._btn-primary,
[type="submit"],
[class^=btn-primary] {
	&:extend(._btn all);
	.button-variant(@white; @blue-dark; @gradient-blue-dark; 0; none; @white; @gradient-lighter-blue-dark; @gradient-blue-btn-active; @white;);
}

// TODO: Check if this doesn't cause problems
//form[action] [type="submit"]:extend(._btn_arrow-right all) {}

// Decide button
._btn-decide() { }

._btn-decide,
[class^=btn-decide] {
	.button-variant(@white; @orange; @gradient-orange; 0; none; @white; @gradient-lighter-orange; @gradient-orange-btn-active; @white;);
}

// Link button
._btn-link() {}
._btn-link,
[class^=btn-link] {
	.button-variant(@blue-dark; transparent; none; 0; none; @blue; none; @orange; @orange; );

	&::after {
		color: @blue;
	}
	&:active:after {
		color:@orange;
	}
}

// Reversed button
._btn-reversed,
[class^=btn-reversed],
.footer-block [role="button"]
{
	.button-variant(@white; transparent; none; @line; @white; @blue-grey; none; @orange; @orange;);
}

// Qualifier classes
._btn_arrow-left {
	&:before {
		content: @var-icon-arrow-left;
	}
}

._btn_arrow-right {
	&:after {
		content: @var-icon-arrow-right;
	}
}

._btn_box-arrow {
	&:after {
		content: @var-icon-external-link;
	}
}

._btn_document {
	&:after {
		content: @var-icon-document;
	}
}


// Predefined buttons
.btn-next {
	&:not(.btn-decide) {
		._btn-primary;
	}
	&.btn-decide {
		._btn-decide;
	}
	._btn_arrow-right;
}

.btn-previous,
.btn-link-previous {
	._btn_arrow-left;
}

[data-ui="nps-btn"] {
	z-index: @z-index-logo;
	position: fixed;
	width: auto;
	line-height: @line-height-s;
	font-size: @font-size-xs;
	transform-origin: left bottom;
	transform: rotate(270deg);
	left: 100%;
	bottom: ~'calc(50vh - 4.5em)';
	white-space: nowrap;
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	background-color: @white;
	padding: 4px @grid-unit-s;


	@media (@breakpoint-m) {
		line-height: @line-height-base;
		font-size: @font-size-m;
		bottom: calc(@rem-component-l * 2);
	}
}

.footer-block [role="button"],
[class^=btn][class*=link-internal] {
	._btn_arrow-right;
}

[class^=btn][class*=link-external] {
	._btn_box-arrow;
}

[class^=btn][class*=document] {
	._btn_document;
}

